export function getUrlFromFile(file) {
    try {
        if(file) {
            return URL.createObjectURL(file);
        }
    }
    catch (e) {}
}

export function getFormData(data, methods = false, reduce) {
    const FD = new FormData();
    for (let key in data) {
        const val = reduce ? reduce(data[key], key) : data[key];
        if(val) {
            if(Array.isArray(val)) {
                for (let index in val) {
                    FD.append(`${key}[]`, val[index]);
                }
            }
            else FD.append(key, val);
        }
    }
    if(methods) FD.append('_method', methods);
    return FD;
}

export function isFile(file) {
    if(file) {
        if(process.client) return file instanceof File;
        else return typeof file === 'object';
    }
    return false;
}
