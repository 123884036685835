export default {
    computed: {
        //TODO: error.html
        phoneContact() {
            return '+7 495 120-80-70';
        },
        phoneContactLink() {
            return 'tel:+74951208070';
        },
    },
}
