<template>
    <section class="page-error">
        <div class="container">
            <h1>{{error.statusCode}}</h1>
            <div class="about" v-if="error.statusCode === 404">
                <div v-if="error.message">{{error.message}}</div>
                <template v-else>Страница не существует или была перемещена в другое место.</template>
                Советуем начать с главной страницы. Если по данному адресу вы не нашли содержание, которое очень важно для вас, обязательно свяжитесь с нами: мы поможем!
            </div>
            <div class="about" v-else-if="error.statusCode === 500">
                <p v-if="error.message">{{error.message}}</p>
                Произошла ошибка сервера, попробуйте позже.
            </div>
            <div class="about" v-else>
                <p v-if="error.message">{{error.message}}</p>
                Произошла ошибка.
            </div>

            <div class="contacts">
                <div class="item">
                    <div class="img"><img v-lazy-load src="@/assets/img/icons/phone.svg" alt="Телефон" loading="lazy"></div>
                    <a :href="phoneContactLink">{{ phoneContact }}</a>
                </div>
                <div class="item">
                    <div class="img"><img v-lazy-load src="@/assets/img/icons/email.svg" alt="Почта" loading="lazy"></div>
                    <a href="mailto:info@rentride.ru">info@rentride.ru</a>
                </div>
            </div>

            <router-link to="/" class="action">
                <button class="home white shadow">Вернуться на главную</button>
            </router-link>
        </div>

        <div class="cars">
            <div class="left">
                <img v-lazy-load src="@/assets/img/car/car-1-2-error.png" alt="">
                <img v-lazy-load src="@/assets/img/car/car-1-1-error.png" alt="">
            </div>
            <div class="right">
                <img v-lazy-load src="@/assets/img/car/car-2-1-error.png" alt="">
                <img v-lazy-load src="@/assets/img/car/car-2-2-error.png" alt="">
            </div>
        </div>
    </section>
</template>

<script>
import phoneContacts from '@/mixins/contacts/phoneContacts';

export default {
    props: ['error'],
    mixins: [phoneContacts],
}
</script>

<style lang="scss">
    section.page-error {
        text-align: center;
        padding-top: 50px;

        h1 {
            font-size: 130px;
            line-height: 120px;
            font-weight: bold;
            color: $black;
        }
        .about {
            font-size: 16px;
            line-height: 1.44;
            color: rgba($black, 0.75);
            padding: 12px 17px;
        }
        .contacts {
            .item {
                @include flexCenter;
                font-weight: 500;
                font-size: 14px;
                line-height: 17px;
                letter-spacing: -0.01em;
                color: $black;
                margin-top: 20px;

                .img {
                    @include flexCenter;
                    width: 24px;
                    height: 24px;
                    border-radius: 50%;
                    border: 2px solid $primary;
                    margin-right: 6px;

                    img {
                        max-height: 10px;
                        max-width: 12px;
                    }
                }
            }
        }

        .home {
            position: relative;
            z-index: 2;
            height: 46px;
            padding: 12px 18px;
            margin-top: 32px;
        }

        .cars {
            @include flexCenter;
            height: 176px;
            margin-top: 12px;

            .left, .right {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                mix-blend-mode: darken;
                width: 50%;
                max-width: 50vw;
                overflow: hidden;

                img {
                    flex-shrink: 0;
                }
            }

            .left img:last-child {
                margin-right: -32px;
            }

            .right {
                justify-content: flex-start;

                img:first-child {
                    margin-bottom: -10px;
                    margin-left: -32px;
                }
            }
        }

        a.action {
            text-decoration: none;
        }

        @include mobileUp {
            h1 {
                font-size: 144px;
                line-height: 173px;
                margin-bottom: -10px;
            }
            .about {
                font-size: 18px;
                line-height: 30px;
                padding: 0;
                max-width: 770px;
                margin: 0 auto 32px;
                color: $black;
            }
            .contacts {
                @include flexCenter;

                .item {
                    padding: 0 12px;

                    .img {
                        margin-right: 8px;
                    }
                }
            }

            .cars {
                height: 310px;
                margin-top: 0;

                .right {
                    margin-bottom: -7px;

                    img {
                        &:first-child {
                            margin-bottom: -2px;
                        }
                        &:last-child {
                            margin-bottom: 6px;
                        }
                    }
                }

                .left img:first-child {
                    margin-bottom: -18px;
                }

                img {
                    margin-right: 0 !important;
                    margin-left: 0 !important;
                    height: 379px;
                }
            }

        }
    }
</style>
