import Vue from 'vue'
import Vuex from 'vuex'
import { getHost } from "@/utils/router";
import { cacheTimeToUpdate, getCache, setCache } from "@/utils/cache";
import { getGlossaries } from "@/utils/filters";
import { cookieNames } from '@/utils/_consts';
import { cookiesSet } from '@/utils/store';

Vue.use(Vuex);

export default {
    state: () => ({
        city: 'moskva',
        domain: getHost(),
        ip: null,
        prevRoute: null,
        supportWebp: false,
        sourceSignup: null,
    }),
    getters: {
        getDomain: (s) => (level) => {
            return s.domain.split('.').reverse().slice(-level).join('.');
        },
        isLocalHost(s) {
            return s.domain === 'localhost';
        }
    },
    mutations: {
        setPrevRoute(s, route) {
            s.prevRoute = route;
        },

        setCity(s, city) {
            s.city = city;
        },

        cleanErrors(stateGlobal) {
            Object.values(stateGlobal).forEach((s) => {
                if (s?.error) {
                    Object.keys(s.error).forEach((key) => {
                        s.error[key] = null;
                    })
                }
            });
        },
        setSupportWebp(s, val) {
            s.supportWebp = val;
        },
        setSourceSignup(s, sourceName) {
            s.sourceSignup = sourceName;
        }
    },
    actions: {
        async nuxtServerInit(store, { app, req, route, $cookies }) {
            if (route.query.fromApp) {
                cookiesSet($cookies, cookieNames.appDownloadAlertClose, true);
            }

            const timeCache = cacheTimeToUpdate();
            const clearPage = route.path === '/system/clear/';
            if (timeCache >= 0 || clearPage) {
                console.log('[cache]', 'update', new Date())
                const r = getGlossaries(store).then(() => {
                    setCache(store.state.glossary);
                });
                if (timeCache === 0 || clearPage) {
                    await r;
                }
            }
            const glossaryCache = getCache();
            if (glossaryCache) {
                store.state.glossary = glossaryCache;
            }


            store.state.ip = req.ip || req.connection.remoteAddress || req.socket.remoteAddress;
            store.state.domain = getHost(req);
            store.state.city = app.$cookies.get('city') || 'moskva';
            store.state.supportWebp = app.$cookies.get(cookieNames.webpSupport) ?? req.headers.accept?.includes('image/webp');

            if (!store.state.glossary._readyGlossaries && !store.state.glossary.pending.glossaries) {
                return store.dispatch('glossary/getGlossaries');
            }
        },
        cancelAllRequest({state}) {
            Object.values(state).forEach((s) => {
                if (s?.cancelSources) {
                    Object.entries(s.cancelSources).forEach(([property, cancelSource]) => {
                        if (s?.pending?.[property] && cancelSource) {
                            cancelSource?.cancel?.();
                        }
                    });
                }
            });
        },
    }
}
