import { StoreApi } from "~/lib/storeRestApi";
import { getStore } from "@/utils/store";

const api = new StoreApi({
    state: {},
}).get({
    action: "getLanding",
    property: "landing",
    path: "/landing",
});

export default getStore(api);
