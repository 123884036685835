export function mergeDocsUser(oldUser, newUser = {}) {
    let documents = newUser.documents ?? [];
    const oldDocs = oldUser?.documents ?? [];
    const getDocFileId = (id) => oldDocs.find((doc) => doc.id === id)?.file;

    if (documents.length && oldDocs.length) {
        documents = documents.map(doc => ({
            ...doc,
            file: getDocFileId(doc.id) ?? doc.file,
        }));
    }

    return { ...newUser, documents };
}
