export const PROLONGATION_STATUS = {
    NEW: 1,
    PAYED: 3,
    CONFIRMED: 10,
    CANCELED: 7,
}

export const isProlongationActive = ({status}) =>
    status?.id === PROLONGATION_STATUS.NEW || status?.id === PROLONGATION_STATUS.CONFIRMED;

export const isProlongationPayed = ({status}) => status?.id === PROLONGATION_STATUS.PAYED;

export const getProlongationLast = (prolongations = [], statusId) => [...prolongations].reverse().find(({status}) => status?.id === statusId);
