function pathLowerCase(path, redirect) {
    const lowerCasePath = path.toLowerCase();
    if (path !== lowerCasePath) {
        return redirect(lowerCasePath);
    }
}

function bookingChat(path, redirect) {
    const paths = path.split('/').filter(v => v);
    if (['rent', 'lease'].includes(paths[0]) && paths[1] === 'deal') {
        return redirect(`/booking/chat/${paths[2]}`);
    }
}

function redirectURLs(path, redirect, ctx) {
    const redirects = ctx.store.state.glossary.redirectURLs || [];
    redirects.some(({ from_url_path: from, to_url_path: to, to_external_url: toExternal, code }) => {
        const regexp = normalizePath(from).replace('*', '(.*)');
        const matches = path.match(`^${regexp}$`);

        if (matches) {
            if (toExternal) {
                ctx.redirect(code, toExternal);
            }

            const dynamicPath = matches[1];
            if (dynamicPath != null && to.includes('*')) {
                const toWithDynamicPath = to.replace('*', dynamicPath);
                ctx.redirect(code, toWithDynamicPath);
            } else {
                ctx.redirect(code, to);
            }
        }

        return !!matches;
    });
}

const redirectMiddlewares = [
    pathLowerCase,
    bookingChat,
    redirectURLs,
];

export default function (ctx) {
    const { route: { path }, $redirect, store } = ctx;
    if (path) {
        redirectMiddlewares.forEach((middleware) => {
            middleware(path, $redirect, ctx)
        });
    }
}

function normalizePath(pathRaw) {
    let path = pathRaw;
    if (!path.startsWith('/') && !path.startsWith('*')) {
        path = `/${path}`;
    }
    if (!path.endsWith('/') && !path.endsWith('*')) {
        path = `${path}/`;
    }
    return path;
}
