export const getUrlPay = {
    methods: {
        getUrlPay(action, queryOther = {}, route = this.$route) {
            let query = Object.assign({}, queryOther, this.$route.query) || {};
            query.action = action;
            const protocol = process.client && location?.protocol;
            const path = this.$router.resolve({
                ...route,
                query,
            })?.href;
            const domain = this.$store.state.domain;
            return `${protocol ?? 'https:'}//${domain}${path}`
        },
    }
}

export const ACTIONS = {
    SUCCESS: 'success',
    FAIL: 'failure',
    SUCCESS_PROLONGATION: 'success-prolongation',
    FAIL_PROLONGATION: 'failure-prolongation',
    SUCCESS_ADD: 'success-add',
    FAIL_ADD: 'failure-add',

};
