export function mapObject(object, callback) {
    return Object.fromEntries(Object.entries(object).map(callback));
}

export function mapObjectValues(object, callback) {
    return mapObject(object, (...args) => {
        const [[name]] = args;
        return [name, callback.apply(null, args)];
    });
}
