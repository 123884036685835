import moment from "moment";
import { DATE_FORMAT } from "@/utils/_consts";

export function getMomentFromTimestamp(timestamp) {
    const isUnix = timestamp.toString().length === 10;
    return isUnix ? moment.unix(timestamp) : moment(timestamp);
}

export function formatDate(date) {
    //fromNow - https://momentjs.com/docs/#/customization/relative-time/
    if (!date) return;
    return getMomentFromTimestamp(date).format('D MMMM YYYY');
}

export function formatFullDate(date) {
    if (!date) return;
    return getMomentFromTimestamp(date).format(DATE_FORMAT.date_time_string);
}

export function formatDuration(date, argWithSuffix = false) {
    const duration = getMomentFromTimestamp(date).diff(moment());
    return moment.duration(duration).humanize(argWithSuffix, { ss: -1, s: 0 }); // min dur - minutes
}

export function shortDate(date) {
    if (!date) return;
    return getMomentFromTimestamp(date).format(DATE_FORMAT.date_m_string);
}
