export const STATUS = {
    NEW: 1, // Новая
    MATCHING: 2, // Согласование
    WAITING: 10, // Подтверждено
    PAYED: 3, // Оплачена
    EXPLOITATION: 4, // Эксплуатация
    COMPLETED: 6, // Завершена
    CANCELED: 7, // Отменена
};
export const checkNotPaid = (status) => [STATUS.NEW, STATUS.MATCHING, STATUS.WAITING].includes(status);
export const checkIsPaid = (status) => [STATUS.PAYED, STATUS.EXPLOITATION, STATUS.COMPLETED].includes(status);
export const checkIsExploitation = (status) => [STATUS.EXPLOITATION, STATUS.COMPLETED].includes(status);
export const strictCheckIsExploitation = (status) => [STATUS.EXPLOITATION].includes(status);
export const isStatus = (status) => (...list) => list.includes(status);

export function allowedEdit(status, isOwner) {
    const notPaid = checkNotPaid(status);
    const isPaid = checkIsPaid(status);
    const isExploitation = checkIsExploitation(status);

    return {
        date: !isOwner && notPaid,
        price: isOwner && notPaid,
        mileage_limit: isOwner && notPaid,
        options: notPaid,
        cities: !isOwner && notPaid,
        inspection: {
            indicators: !isOwner && isPaid,
            photos: isOwner ? isExploitation: isPaid
        }
    }
}

export function allowedActions(status, isOwner) {
    const is = isStatus(status);
    const isExploitation = checkIsExploitation(status);

    return {
        cancel: isOwner && is(STATUS.NEW, STATUS.MATCHING, STATUS.WAITING),
        confirm: isOwner && is(STATUS.NEW, STATUS.MATCHING),
        pay: !isOwner && is(STATUS.WAITING),
        using: is(STATUS.PAYED),
        inspection: is(STATUS.PAYED) || is(STATUS.EXPLOITATION),
        completion: is(STATUS.EXPLOITATION),
        prolongation: {
            send: isExploitation && !isOwner,
            action: isExploitation && isOwner,
        },

    }
}
