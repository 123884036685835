export const getPriceCarH = ({price, type}) => (type - 1) ? price : price * 24;//Получает цену за час

export function objIsEmpty(obj) {
    return Object.keys(obj).length === 0;
}

export function getArrayStep(from, to, step = 1, initArray = [], handler = false) {
    const list = [...initArray];
    for (let item = from; item < to; item += step) {
        list.push(handler ? handler(item) : item);
    }
    return list;
}

export function includedRange(start, end, val, valEnd) {
    return val >= start && (valEnd || val) <= end;
}

export function minMax(val, min, max) {
    return (!min || val >= min) && (!max || val <= max);
}

export function getPercent(val, p) {
    return val * p / 100;
}

export function objectClean(object) {
    return Object.keys(object).reduce((obj, key) => {
        if (object[key]) obj[key] = object[key];
        return obj;
    }, {});
}

export function formatRentDuration(duration, round = false) {
    const result = [];
    const days = Math.floor(duration.asDays());
    const hours = duration.hours();
    if (days) {
        result.push(`${days} сут.`);
    }
    if (hours) {
        if (!round || !result.length) {
            result.push(`${hours} ч.`);
        }
    }
    if (!days) {
        if (!round || !result.length) {
            const formatMinutes = minutes => `${minutes} мин.`;
            const minutes = duration.minutes();
            if (minutes) {
                result.push(formatMinutes(minutes));
            } else if (!result.length) {
                result.push(formatMinutes(1));
            }
        }
    }
    return result.join(' ');
}
