import { StoreApi } from "~/lib/storeRestApi";
import { getStore, onSuccessData } from "@/utils/store";

const api = new StoreApi({
    state: {
        categories: [],
        categoriesFeatured: [],
        posts: {},
        postsTyped: {},
    },
}).get({
    action: "getPosts",
    property: "posts",
    queryParams: true,
    path: '/posts',
}).get({
    action: "getPost",
    property: "post",
    path: ({ alias }) => `/posts/${alias}`,
}).get({
    action: "getCategories",
    property: "categories",
    path: '/postcategories',
    onSuccess: onSuccessData('categories'),
}).get({
    action: "getCategoriesFeatured",
    property: "categoriesFeatured",
    path: '/postcategories?filter[featured]=1',
    onSuccess: onSuccessData('categoriesFeatured'),
});

export default getStore(api, {
    getters: {
        postsType: (s) => (type) => {
            return s.postsTyped[type] || [];
        }
    },
});
