import _isEqual from "lodash/isEqual";

export default function leavePageCancelRequest({store: {dispatch}, route, from}) {
    if (process.client && !isSimilarRoute(from, route)) {
        dispatch('cancelAllRequest');
    }
}

function isSimilarRoute(from, to) {
    if (!from || !to) {
        return false;
    } else {
        return from.name === to.name && _isEqual(from.params, to.params);
    }
}
