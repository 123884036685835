import { GTMPay } from "@/utils/meta";

export default function ({ $echo, $auth, $sentry, app }) {

    let userId;

    function join() {
        userId = $auth?.user?.id;
        const channel = $echo.join(`payments.${userId}`);
        channel.listen('.payment.success', (track_payment) => {
            $sentry.addBreadcrumb({
                category: "payment.success",
                message: `payments.${userId}`,
                level: $sentry.Severity.Info,
            });
            app.$dataLayerPush('RRTransaction', 'RRTransaction', track_payment?.payment_id, GTMPay(track_payment));
            channel.socket.emit('payment.success.read', `payments.${userId}`, track_payment?.payment_id);
        }).error((e) => {
            $sentry.captureException(new Error(`Error connect to 'payments.${userId}': ${e}`));
        });
    }

    if(process.client) {
        $echo.connect();
        if($auth?.loggedIn) {
            join();
        }

        $auth?.$storage?.watchState('loggedIn', (loggedIn) => {
            if (loggedIn) {
                join();
            }
            else {
                $echo.leave(`payments.${userId}`);
            }
        });
    }

}
