export function declension(rawNumber, ...titles) { //отзыв, отзыва, отзывов
    let number = Math.floor(rawNumber);
    if (number !== rawNumber && rawNumber) {
        number = +rawNumber?.toString().split('.').slice(-1)[0];//Последнее число
    }
    return titles[(number % 100 > 4 && number % 100 < 20) ? 2 : ([2, 0, 1, 1, 1, 2])[Math.min(number % 10, 5)]]
}

export function declensionWithCount(count, ...titles) {
    return `${count} ${declension(count, ...titles)}`
}

export function connectMultipleDeclensions(count, titlesTuples, connector) {
    const titles = titlesTuples.map((titles) => declension(count, ...titles));
    return connector(count, ...titles);
}

export function consonantLast(word) {
    const consonants = ['б', 'в', 'г', 'д', 'ж', 'з', 'й', 'к', 'л', 'м', 'н', 'п', 'р', 'с', 'т', 'ф', 'х', 'ц', 'ч', 'ш', 'щ'];
    const symbol = word.slice(-1);
    return consonants.includes(symbol);
}


/* Declensions */
export function declensionCar(count) {
    return declensionWithCount(count, 'автомобиль', 'автомобиля', 'автомобилей');
}

export function declensionCarShort(count) {
    return declensionWithCount(count, 'машина', 'машины', 'машин');
}

export function declensionMoto(count) {
    return declensionWithCount(count, 'мотоцикл', 'мотоцикла', 'мотоциклов');
}

export function declensionReviews(count) {
    return declensionWithCount(count, 'отзыв', 'отзыва', 'отзывов');
}
export function declensionRatings(count) {
    return declensionWithCount(count, 'оценка', 'оценки', 'оценок');
}

export function declensionProposals(count) {
    return declensionWithCount(count, 'предложение', 'предложения', 'предложений');
}

export function declensionYears(count) {
    return declensionWithCount(count, 'год', 'года', 'лет');
}

export function declensionMonths(count) {
    return declensionWithCount(count, 'месяц', 'месяца', 'месяцев');
}

export function declensionDays(count) {
    return declensionWithCount(count, 'сутки', 'суток', 'суток');
}

export function declensionNormalDays(count) {
    return declensionWithCount(count, 'день', 'дня', 'дней');
}

export function declensionHours(count) {
    return declensionWithCount(count, 'час', 'часа', 'часов');
}

export function declensionMinutes(count) {
    return declensionWithCount(count, 'минута', 'минуты', 'минут');
}

export function declensionMinutesCount(count) {
    return declensionWithCount(count, 'минуту', 'минуты', 'минут');
}

export function declensionTrip(count) {
    return declensionWithCount(count, 'поездка', 'поездки', 'поездок');
}

export function declensionProlongations(count) {
    return declensionWithCount(count, 'продление', 'продления', 'продлений');
}

export function declensionTimes(count) {
    return declensionWithCount(count, 'раз', 'раза', 'раз');
}

export function declensionClients(count) {
    return declensionWithCount(count, 'клиент', 'клиента', 'клиентов');
}

export function declensionPeople(count) {
    return declensionWithCount(count, 'человек', 'человека', 'человек');
}


/* Declensions without count */

export function declensionEngineVolume(count) {
    return declension(count, 'литр', 'литра', 'литров');
}

export function declensionBonusPointsWithoutCount(count) {
    return declension(count, 'балл', 'балла', 'баллов');
}
