import { StoreApi } from '~/lib/storeRestApi';
import { getStore, pendingSome } from '@/utils/store';
import _upperFirst from 'lodash/upperFirst';
import { CITY_LARGEST, dealCascoOptionType, FILTERS } from '@/utils/_consts';
import { getCords, sortByDistanceCities } from '@/utils/location';
import _invert from "lodash/invert"

//_isVue - disable reactive: https://stackoverflow.com/a/56091103/9109534
const store = new StoreApi({
    state: {
        _readyGlossaries: false,
        _readyGlossariesOptions: false,
        _readyGlossariesDealOptions: false,
        _readyGlossariesLandings: false,
        _readyGlossariesClasses: false,
        _readyRedirectURLs: false,
        glossariesOptions: {
            _isVue: true,
        },
        glossariesAddress: {
            _isVue: true,
            city: [],
            metro: [],
            region: [],
        },
        glossariesCar: {
            _isVue: true,
            brand: [],
            brand_model: [],
            car_statuses: [],
            insurance_type: [],
            car_class: [],
            tags: [
                {
                    id: -1,
                    alias: 'unikalnye',
                    title: 'Уникальные авто',
                    seo_title: 'уникальных автомобилей',
                    seo_description: 'уникальных, необычных и ретро машин',
                },
            ],
        },
        glossariesDeal: {
            _isVue: true,
            deal_statuses: [],
            deal_options: [],
            deal_option_types: [],
            deal_option_price_types: [],
            deal_cancel_reasons: [],
            deal_options_casco: [],
            deal_status_filters: [],
        },
        glossariesLandings: [],
        redirectURLs: [],
    },
}).get({
    action: "getGlossaries",
    path: '/glossaries',
    property: 'glossaries',
    allowDuplicate: true,
    onSuccess(s, { data }) {
        for (const key in data) {
            let val = data[key];
            if (key === 'address') {
                if (Array.isArray(val?.city)) {
                    val.city = val.city.filter(({ alias }) => alias);
                }
                if (Array.isArray(val?.metro)) {
                    val.metro = val.metro.filter(({ alias }) => alias);
                }
            }
            s[`glossaries${_upperFirst(key)}`] = {
                ...s[`glossaries${_upperFirst(key)}`],
                ...val,
            };
        }
        s._readyGlossaries = true;
    },
}).get({
    action: "getGlossariesOptions",
    path: '/glossaries/car/options',
    property: 'glossariesOptions',
    onSuccess(s, { data }) {
        s.glossariesOptions = data;
        s._readyGlossariesOptions = true;
    },
}).get({
    action: "getGlossariesClasses",
    path: '/glossaries/car/classes',
    property: 'glossariesCar.car_class',
    onSuccess(s, { data: { data } }) {
        s.glossariesCar.car_class = data;
        s._readyGlossariesClasses = true;
    },
}).get({
    action: "getGlossariesDeal",
    path: '/glossaries/deal/options',
    property: 'glossariesDeal',
    onSuccess(s, { data: { data } }) {
        s.glossariesDeal.deal_options = data;
        s.glossariesDeal.deal_options_casco = data.filter(({ type }) => type.alias === dealCascoOptionType);
        s._readyGlossariesDealOptions = true;
    },
}).get({
    action: "getGlossariesLandings",
    path: '/landings',
    property: 'glossariesLandings',
    onSuccess(s, { data }) {
        s.glossariesLandings = data;
        s._readyGlossariesLandings = true;
    },
}).get({
    action: "getRedirectURLs",
    path: '/redirect-urls',
    property: 'redirectURLs',
    onSuccess(s, { data }) {
        s.redirectURLs = data;
        s._readyRedirectURLs = true;
    },
});

const alias = {
    regions: 'region',
    osago: 'insurance_type',
    casco: 'insurance_type',
    ..._invert(FILTERS.ALIASES_NAME),
};

export default getStore(store, {
    getters: {
        getGlossaryCarList: (s) => (type, vehicleType) => {
            type = alias[type] || type;
            const list = ['glossariesCar', 'glossariesOptions', 'glossariesDeal', 'glossariesAddress'];
            const sAlias = list.find((name) => {
                const aliasList = s[name][type];
                if (aliasList) {
                    if (vehicleType) {
                        if (aliasList.vehicle_types && !aliasList.vehicle_types?.includes(vehicleType)) {
                            return false;
                        }
                    }
                    return true;
                } else {
                    return false;
                }
            });

            if (sAlias) {
                const listValues = sAlias === 'glossariesOptions' ? s[sAlias][type]?.values : s[sAlias][type];
                if (!Array.isArray(listValues)) {
                    return [];
                }
                if (vehicleType) {
                    return listValues.filter((item) => {
                        return item?.vehicle_types?.includes(vehicleType) ?? true;
                    });
                }
                return listValues;
            } else {
                if (process.client && !pendingSome(s.pending)) {
                    console.warn('Глоссарий не найден', type);
                }
                return [];
            }
        },
        getLandingCatalog: (s) => (paramsAlias, paramsCity) => {
            return s.glossariesLandings.find(
                ({ url_path, alias, city }) => city === paramsCity && alias === paramsAlias,
            );
        },
        getGlossaryOption: (s, getters) => (type, alias) => {
            const list = getters.getGlossaryCarList(type);
            if (list) {
                return list.find((item) => item.alias === alias);
            } else {
                return {};
            }
        },
        dealOptionsCascoAliases(s) {
            return s.glossariesDeal.deal_options_casco?.map(({ alias }) => alias);
        },
        listCities(s) {
            const list = s.glossariesAddress?.city || [];
            return list.reduce((obj, city) => {
                if (CITY_LARGEST.includes(city.alias)) obj.largest.push(city);
                obj.other.push(city);
                return obj;
            }, { largest: [], other: [] });
        },
        currentCity(s, g, rootState) {
            return g.getCity(rootState.city) || { alias: rootState.city || 'moskva' };
        },
        getCity: (s, g, rootState) => (city) => {
            if (city) {
                const list = s.glossariesAddress?.city || [];
                return list.find(({ alias }) => alias === city);
            }
        },
        brandPublished(s) {
            return s.glossariesCar.brand.filter(({ count_publish_cars }) => count_publish_cars);
        },
        brandModelPublished(s) {
            return s.glossariesCar.brand_model.filter(({ count_publish_cars }) => count_publish_cars);
        },
        availableDeliveryAirports(s, g) {
            const city = g.currentCity.alias;
            return s.glossariesAddress.airport?.filter(({city_alias}) => city_alias === city);
        },
    },
    mutations: {
        setCities(s, cities) {
            s.glossariesAddress.city = cities;
        },
    },
    actions: {
        sortCities({ commit, state }) {
            const cityList = state.glossariesAddress.city;
            if (cityList) {
                getCords().then((cords) => {
                    const cites = sortByDistanceCities(cityList, cords);
                    commit('setCities', cites);
                }, () => {
                });
            }
        },
    },
});
