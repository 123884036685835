export default {
    namespaced: true,
    state: ()=>({
        activeModal: false,
        dataModal: false
    }),
    mutations: {
        openModal(s, data){
            if(typeof data == 'object') {
                s.activeModal = data.name;
                s.dataModal = data.data;
            }
            else {
                s.activeModal = data;
                s.dataModal = false;
            }
        },
        closeModal(s){
            s.activeModal = false;
            s.dataModal = false;
        },
        setDataModal(s, data){
            s.dataModal = data;
        }
    },
    actions: {
        init(){}
    }
}
