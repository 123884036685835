import Vue from 'vue'
import VueMask from 'v-mask'

Vue.prototype.$maskPhoneFormat = '### ### ## ##';
Vue.prototype.$maskPhoneFormatCode = '+# ' + Vue.prototype.$maskPhoneFormat;
Vue.prototype.$maskCarNumberFormat = 'Я ### ЯЯ ###?';
Vue.prototype.$maskaDocSeriesAndNumber = '## ## ######';
Vue.prototype.$maskCarVinFormat = 'WWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWW';
Vue.prototype.$maskDateFormat = [/[0-3]/, /\d/, '.', /[0-1]/, /\d/, '.', /[1-2]/, /[890]/, /\d/, /\d/];
Vue.use(VueMask, {
    placeholders: {
        Я: /[A-zа-яА-Я]/,
        W: /\w/,
    },
});
