export default ({app, $auth}) => {
    const token = app.$cookies.get('RR-AccessToken');
    if(token){
        $auth.setUserToken(token);
        if(process.client) {
            app.$cookies.remove('RR-AccessToken');
            const domain = location.host.split('.').splice(-2).join('.');
            document.cookie = 'RR-AccessToken=; Max-Age=0; path=/; domain=.'+domain;
        }
    }
}
