export const GET_BASE_URL = (domainOnly = false, onlyMainDomain = false, onlyZone = false) => {
    const url = process.env.VUE_APP_API_URL || 'https://api.rentride.ru/api/2.0';
    if (domainOnly) {
        let list = url.split('/');
        const i = list.indexOf('api') || 3;
        let domain = list.slice(0, i).join('/');

        if (onlyMainDomain || onlyZone) {
            const main = url.match(/^(?:https?:)?(?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:\/\n]+)/im)[1];
            if (main) {
                domain = main.split('.').splice(-2).join('.');
            }
        }

        if (onlyZone) {
            domain = domain.split('.').splice(-1)[0];
        }

        return domain;
    } else return url;
}

export const IS_DEV = () => process.env.NODE_ENV === 'development' || GET_BASE_URL(true, true, true) === 'dev';

export const FILTERS = {
    ALLOWED: [
        'id', 'status', 'brand', 'brand_model', 'cars_body', 'drive',
        'drive_hand', 'engine_type', 'car_engine_volume', 'doors',
        'car_class', 'car_sub_class', 'transmission', 'selection',
        'price_from', 'price_to', 'date_from', 'date_to', 'metro',
        'mileage', 'seats', 'min_time', 'deposit', 'rent_options',
        'delivery', 'region', 'osago', 'casco', 'insurance_type',
        'year', 'min_year', 'max_year', 'mileage_limit', 'trip_time',
        'is_auto_agreed', 'vehicle_type', 'min_age', 'min_exp', 'tags',
        'landing', 'delivery_address', 'delivery_airport',
    ],
    ARRAY_FILTERS: [
        'rent_options', 'region', 'car_class', 'cars_body', 'drive',
        'brand_model', 'transmission', 'metro', 'engine_type', 'tags',
        'delivery_airport',
    ],
    NUMBER_FILTERS: [
        'price_from', 'price_to', 'date_from', 'date_to',
        'min_time', 'deposit', 'delivery',
        'year', 'min_year', 'max_year', 'region', 'min_age'
    ],
    OTHER: [
        'cars_body', 'brand', 'brand_model', 'car_class', 'seats',
        'metro', 'drive', 'min_time', 'engine_type', 'mileage_limit', 'doors',
        'year', 'insurance_type', 'region', 'delivery', 'casco', 'osago',
        'rent_options', 'tags',
    ],
    ROOT: [
        'brand', 'brand_model', 'city', 'year', 'min_price_per_day', 'max_price_per_day',
        'min_time', 'price_max', 'uploads', 'uploadsSts', 'region', 'deposit',
        'mileage_limit', 'overrun_price', 'min_experience', 'min_age', 'metro',
        'casco_type', 'osago_type', 'description', 'address', 'imagesSts', 'regions', 'rent_options',
        'longitude', 'latitude', 'cities', 'cities_exclude', 'regions_exclude',
        'price_steps', 'price_percent',
    ],
    ALIASES_VAL: {
        econom: 'economy',
        'delivery-address': 'delivery_address',
    },
    ALIASES_NAME: {
        airport: 'delivery_airport',
    },
    WITHOUT_QUERY: [
        'extension',
        'vehicle_type',
    ],
    BOOLEAN: [
        'casco', 'osago', 'delivery_address',
    ],
    UPPER: [
        'delivery_airport',
    ],
};

export const defaultMarketplaceFilters = {
    vehicle_type: 'car',
}

export const defaultMarketplaceFiltersKeys = Object.keys(defaultMarketplaceFilters);

export const marketplaceCountIgnoreFilters = [
    'extension', 'landing',
]

export const CAR_PARAMS = {
    year: 'Год',
    city: 'Город',
    metro: 'Метро',
    deposit: 'Депозит',
    insurance_type: 'Страховка',
    region: 'Регионы',
    mileage_limit: 'Включенные КМ',
    delivery: 'С доставкой',
    casco: 'Каско',
    osago: 'Осаго',
    rent_options: 'Услуги',
};

export const RATING_NAME = {
    3: 'Рейтинг',
    5: 'Рейтинг',
    6: 'Рейтинг',
    7: 'Рейтинг',
    8: 'Рейтинг',
    11: 'Рейтинг'
}

export const DATEPICKER = {
    default_time: {
        minutes: 0,
        hours: 12,
    }
}

export const DATE_FORMAT = {
    time: 'HH:mm',
    date: 'DD.MM.YYYY',
    date_string: 'DD MMMM YYYY',
    date_m_string: 'DD MMMM',
    date_time_in: 'DD.MM.YYYY[, в] HH:mm',
    date_time: 'DD.MM.YYYY[,] HH:mm',
    date_m_time_string: 'DD MMMM[,] HH:mm',
    date_time_string: 'DD MMMM YYYY[,] HH:mm',
    date_new: 'D MMM ‘YY',
}

export const CITY_LARGEST = [
    'moskva',
    'sankt-peterburg'
];

export const INSPECTION_TYPES = {
    managerBegin: 6, // Начало менеджер / владаелец
    deliveryBegin: 8, // Начало доставки
    deliveryEnd: 9, // кончание доставки
    clientBegin: 3, // Прием клиентом на эксплуатацию

    returnalBegin: 18, // Начало возврата
    returnalEnd: 19, // Окончание возврата
    managerEnd: 7, // Окончание менеджер / владаелец
    clientEnd: 5, // Завершение клиентом

    other: 10, //  зарезервировано и под просто фотки с поездки
    exploitation: 4, // Фото эксплуатации // B.C. ending
    panel: 1,//  Приборная панель // B.C. beginning

    draft: 50,
}

export const BEGIN_INSPECTION_TYPES_LIST = [
    INSPECTION_TYPES.managerBegin,
    INSPECTION_TYPES.clientBegin,
    INSPECTION_TYPES.deliveryBegin,
    INSPECTION_TYPES.returnalBegin,
];

export const END_INSPECTION_TYPES_LIST = [
    INSPECTION_TYPES.managerEnd,
    INSPECTION_TYPES.clientEnd,
    INSPECTION_TYPES.deliveryEnd,
    INSPECTION_TYPES.returnalEnd,
];

export const DELIVERY_TARIFF_ALIASES = {
    selftake: 'owner_selftake',
    airport: 'owner_to_airport',
    address: 'owner_to_address',
}

export const DELIVERY_TYPES = {
    delivery: 1,
    return: 2,
}

export const MODAL_IDS = {
    INSPECTION_DEAL: 'inspectionDeal',
    CALENDAR_CAR: 'calendarCar',
    TERMS_CAR: 'termsCar',
    PRICES_SERVICES: 'pricesServices',
    RENT_CAR: 'rentCar',
    PROFILE_CONFIRM: 'profileConfirm',
    NAME_AND_PHONE: 'nameAndPhone',
    DEAL_CANCEL: 'cancel-deal',
    CAR_START_MODERATION: 'car-start-moderation',
    CAR_FEEDBACK: 'car-feedback',
    DEAL_OWNER_FEEDBACK: 'deal-owner-feedback',
    SDAT_FORM: 'sdat-form',
    SHORT_CONFIRM: 'short-confirm',
    SELECT_DATE_PERIOD: 'select-date-period',
    WIZARD_SAVE_MAIN: 'wizard-save-main',
    WIZARD_SAVE_MOBILE: 'wizard-save-mobile',
    WIZARD_BASIC_DONE: 'wizard-basic-done',
    MY_CAR_REMOVE: 'my-car-remove',
    MY_CAR_REMOVED: 'my-car-removed',
    CROPPER: 'cropper',
    PAYMENT_BONUS_CONGRATULATIONS: 'payment-bonus-congratulations',
    PAYMENT_BONUS_POINTS_INFO: 'payment-bonus-points-info',
    BONUS_POINTS_ACTIVATE: 'bonus-points-activate',
    BONUS_POINTS_EXPIRED_PROMOTION: 'bonus-points-expired-promotion',
    PHOTO_PREVIEW: 'photo-preview',
    NEW_USERS_REGISTRATION_PROMOTION: 'new-users-registration-promotion',
}

export const ROLES = {
    OWNER: 1,
    CLIENT: 2
}

// for seconds
export const SHORT_TIME = {
    M: 60,
    H: 60 * 60,
    D: 60 * 60 * 24,
}

// for ms
export const TIME = {
    M: SHORT_TIME.M * 1000,
    H: SHORT_TIME.H * 1000,
    D: SHORT_TIME.D * 1000,
}

export const LOCALSTORAGE_NAMES = {
    utm: 'utm-list',
    referralId: 'rr-referral-id',
    lastRoute: 'rr_least_route',
    takenAccruedBonusesDealIds: 'rr_taken_accrued_bonuses_deal_ids',
}


export const CAR_STATUS = {
    REMOVED: 3,
    DRAFT: 5,
    PUBLISHED: 6,
    RENTED: 7,
    BLOCK: 9,
    DISABLED: 10,
    MODERATION: 50, // STATUS_CTC_REVIEW
}

export const CAR_STS_IMAGE_TYPES = {
    FRONT: 1,
    BACK: 2,
}

export const dateViewTypes = {
    dayViewType: 'day',
    weekViewType: 'week',
    monthViewType: 'month',
}

export const cookieNames = {
    audiTemplateVisited: 'audi-template-visited',
    payRedirect: 'rr-pay-redirect',
    appDownloadAlertClose: 'rr-app-download-alert-close',
    webpSupport: 'rr-webp-support',
    bonusSignUp: 'rr-bonus-sign-up',
}

export const audiBrand = 'audi';

export const dealCascoOptionType = 'casco';

export const VEHICLE_TYPE_NAMES = {
    car: {
        short: {
            one: 'авто',
            multi: 'машин',
        },
        full: {
            one: 'автомобиль',
            multi: 'автомобилей',
        },
    },
    moto: {
        short: {
            one: 'мото',
            multi: 'мотоциклов',
        },
        full: {
            one: 'мотоцикл',
            multi: 'мотоциклов',
        },
    },
}

export const PROFILE_STATUSES = {
    NEW: 'new', // Не зарегистрирован
    REGISTERED: 'registered', // Зарегистрирован
    VERIFICATION: 'verification', // На верификации
    RELOAD: 'reload', // Повторно загрузить
    VERIFIED: 'verified', // Верифицирован
    UNVERIFIED: 'unverified', // Документы отклонены
    BAN: 'ban', // Бан
}

export const PROFILE_DOCUMENTS_STATUSES = {
    DRAFT: 'draft', // На проверке
    ACCEPTED: 'accepted', // Верифицирован
    DECLINED: 'declined', // Отклонен
}


export const CAR_CALENDAR_TYPES = {
    NOT_AVAILABLE: 10, // Заблокированный период
    SYSTEM_HIDE: 3, // Закрытый системой период
    RENTED: 1, // Арендованный период
}


export const yaMapCfg = {
    apiKey: process.env.VUE_APP_YANDEX_MAP_API_KEY,
    lang: 'ru_RU',
    coordorder: 'latlong',
    enterprise: false,
    version: '2.1',
};

export const NBSP = ' ';


const metaTitleNoLimit = "Прокат без залога, без ограничения пробега.";
const metaTitleFree = "Возможность арендовать авто без водителя, без залога, без ограничения пробега."

export const EXTENSIONS_CATALOG = {
    'posutochno': {
        title: "Аренда автомобилей посуточно",
        meta: {
            title: "Посуточная аренда авто без водителя | Прокат машин в RentRide",
            description: "Выгодная посуточная аренда машины без водителя рядом с вами! " + metaTitleNoLimit
        },
    },
    '10-days': {
        title: "Аренда автомобилей на 10 дней",
        meta: {
            title: "Аренда авто на 10 дней без водителя | Прокат машин в RentRide",
            description: "Выгодная аренда машины без водителя на 10 дней рядом с вами! " + metaTitleNoLimit
        },
    },
    '2-weeks': {
        title: "Аренда автомобилей на 2 недели",
        meta: {
            title: "Аренда авто на 2 недели без водителя в  | Прокат машин в RentRide",
            description: "Выгодная аренда машины без водителя на 14 дней рядом с вами! " + metaTitleNoLimit
        },
    },
    'month': {
        title: "Аренда автомобилей на месяц",
        meta: {
            title: "Аренда авто на месяц без водителя | Прокат машин в RentRide",
            description: "Выгодная помесячная аренда машины без водителя рядом с вами! " + metaTitleNoLimit
        },
    },
    'without-driver': {
        title: "Аренда автомобилей без водителя",
        meta: {
            title: "Аренда авто без водителя | Прокат машин в RentRide",
            description: "Выгодная аренда машины без водителя рядом с вами! " + metaTitleNoLimit
        },
    },
    'dlitelnaya': {
        title: "Длительная аренда автомобилей",
        meta: {
            title: "Аренда авто на длительный срок | Прокат машин без водителя в RentRide",
            description: "Выгодная аренда машины на длительный срок без водителя рядом с вами! " + metaTitleNoLimit
        },
    },
    'dolgosroschnaya': {
        title: "Долгосрочная аренда автомобилей",
        meta: {
            title: "Долгосрочная аренда авто | Прокат машин без водителя в RentRide",
            description: "Выгодная аренда машины на долгий срок без водителя рядом с вами! " + metaTitleNoLimit
        },
    },
    'drugoy-gorod': {
        title: "Аренда автомобилей в {city} для междугородней поездки",
        meta: {
            title: "Аренда авто в {city} для поездки в другой город | Прокат машин без водителя в RentRide",
            description: "Выгодная аренда машины на межгород без водителя рядом с вами! " + metaTitleNoLimit,
        },
    },
    'poezdki-russia': {
        title: "Аренда автомобилей в {city} для поездок по России",
        meta: {
            title: "Аренда авто в {city} для поездки по России | Прокат машин без водителя в RentRide",
            description: "Выгодная аренда машины для поездок и путешествий по России без водителя рядом с вами!" + metaTitleNoLimit
        },
    },
    'long-distances': {
        title: "Аренда автомобилей для длительной поездки",
        meta: {
            title: "Аренда авто для дальних поездок | Прокат машин без водителя в RentRide",
            description: "Выгодная аренда машины для поездки на дальние расстояния без водителя рядом с вами!" + metaTitleNoLimit,
        },
    },
    'na-more': {
        title: "Аренда автомобилей для длительной поездки",
        meta: {
            title: "Аренда авто для дальних поездок в | Прокат машин без водителя в RentRide",
            description: "Выгодная аренда машины для поездки на дальние расстояния без водителя рядом с вами!" + metaTitleNoLimit,
        },
    },
    'v-krim': {
        title: "Аренда автомобилей для поездки в Крым",
        meta: {
            title: "Аренда авто для поездки в Крым | Прокат машин без водителя в RentRide",
            description: "Выгодная аренда машины для поездки в Крым на море без водителя рядом с вами! " + metaTitleNoLimit
        },
    },
    'travel-russia': {
        title: "Аренда автомобилей в {city} для путешествия по России",
        meta: {
            title: "Аренда авто в {city} для путешествий по России | Прокат машин без водителя в RentRide",
            description: "Выгодная аренда машины для путешествий и поездок по России без водителя рядом с вами! " + metaTitleNoLimit
        },
    },
    'chastniki': {
        title: "Аренда автомобилей от частных собственников",
        meta: {
            title: "Аренда авто от частных лиц | Прокат машин от собственников без водителя в RentRide",
            description: "Выгодная аренда машины от частных лиц и собственников без водителя рядом с вами! " + metaTitleNoLimit
        },
    },
    'inostranci': {
        title: "Аренда автомобилей для иностранцев",
        meta: {
            title: "Аренда авто для иностранцев | Прокат машин без водителя в RentRide",
            description: "Выгодная аренда машины для иностранных граждан и туристов без водителя рядом с вами! " + metaTitleNoLimit
        },
    },
    'carsharing-days': {
        title: "P2P-каршеринг на сутки",
        meta: {
            title: "P2P-каршеринг посуточно | Аренда авто на сутки без водителя в RentRide",
            description: "P2P-каршеринг - это удобная и выгодная посуточная аренда авто без водителя рядом с вами! " + metaTitleNoLimit,
        },
    },
    'carsharing-month': {
        title: "P2P-каршеринг на месяц",
        meta: {
            title: "P2P-каршеринг на месяц | Аренда авто на месяц без водителя в RentRide",
            description: "P2P-каршеринг - это удобная и выгодная помесячная аренда авто без водителя рядом с вами! " + metaTitleNoLimit
        },
    },
    'carsharing-inostranci': {
        title: "P2P-каршеринг для иностранных граждан",
        meta: {
            title: "P2P-каршеринг для иностранцев | Аренда авто без водителя в RentRide",
            description: "P2P-каршеринг - это удобная и выгодная посуточная аренда авто для иностранцев и туристов! " + metaTitleNoLimit
        },
    },
    'carsharing-bez-staga': {
        title: "P2P-каршеринг без опыта и стажа вождения",
        meta: {
            title: "P2P-каршеринг для новичков в {city} | Аренда авто для водителей с минимальным стажем в RentRide",
            description: "P2P-каршеринг - это удобная и выгодная аренда авто для новичков-водителей без стажа вождения! " + metaTitleNoLimit,
        },
    },
    'carsharing-mezhgorod': {
        title: "P2P-каршеринг с опцией межгород",
        meta: {
            title: "P2P-каршеринг для поездки в другой город | Аренда авто без водителя в RentRide",
            description: "P2P-каршеринг - это удобная и выгодная аренда авто для поездок в другой город без водителя! " + metaTitleNoLimit
        },
    },
    'carsharing-v-oblast': {
        title: "P2P-каршеринг с выездом за город",
        meta: {
            title: "P2P-каршеринг в {city} с выездом в область | Аренда авто для поездки за город в RentRide",
            description: "P2P-каршеринг - это удобная и выгодная аренда авто с выездом в область или за город без водителя! " + metaTitleNoLimit
        },
    },
    'carsharing-bez-nakleek': {
        title: "P2P-каршеринг без наклеек",
        meta: {
            title: "P2P-каршеринг без наклеек | Аренда авто от собственников без водителя в RentRide",
            description: "P2P-каршеринг - это удобная и выгодная аренда авто от частных лиц без водителя рядом с вами! " + metaTitleNoLimit
        },
    },
    'without-deposit': {
        title: "Аренда автомобилей без залога и депозита",
        meta: {
            title: "Аренда авто без залога | Прокат машин без депозита в RentRide",
            description: "Большой выбор машин в аренду без депозита и залога рядом с вами." + metaTitleFree
        },
    },
    'delivery': {
        title: "Аренда автомобилей с доставкой по адресу",
        meta: {
            title: "Аренда авто с доставкой в аэропорт, вокзал или по городу | Прокат машин без водителя в RentRide",
            description: "Большой выбор машин в аренду с доставкой по нужному адресу, в аэропорт или на вокзал." + metaTitleFree
        },
    },
    'roof-rack': {
        title: "Аренда автомобилей с багажником на крыше",
        meta: {
            title: "Аренда авто с багажником на крыше | Прокат машин без водителя в RentRide",
            description: "Большой выбор машин в аренду с багажником на крыше рядом с вами." + metaTitleFree
        },
    },
    'trailer': {
        title: "Аренда автомобилей с прицепом",
        meta: {
            title: "Аренда авто с прицепом | Прокат машин без водителя в RentRide",
            description: "Большой выбор машин в аренду с прицепом рядом с вами." + metaTitleFree
        },
    },

    'tourism-fishing-hunting': {
        title: "Аренда автомобилей для туризма",
        meta: {
            title: "Аренда авто для рыбалки и охоты | Прокат машин для туризма без водителя в RentRide",
            description: "Большой выбор машин в аренду для туризма, выездов на рыбалку и охоту." + metaTitleFree
        },
    },
    'events': {
        title: "Аренда автомобилей на мероприятие",
        meta: {
            title: "Аренда авто для мероприятий и праздников | Прокат машин без водителя в RentRide",
            description: "Большой выбор машин в аренду для мероприятий и празднований рядом с вами." + metaTitleFree
        },
    },
    'wedding': {
        title: "Аренда свадебных авто",
        meta: {
            title: "Аренда авто на свадьбу | Прокат свадебных машин без водителя в RentRide",
            description: "Большой выбор свадебных машин в аренду рядом с вами." + metaTitleFree
        },
    },
    'sport-cars': {
        title: "Аренда спортивных авто",
        meta: {
            title: "Аренда спорткаров | Прокат спортивных машин без водителя в RentRide",
            description: "Большой выбор спортивных машин и спорткаров в аренду рядом с вами." + metaTitleFree
        },
    },
    'vip-auto': {
        title: "Аренда элитных автомобилей",
        meta: {
            title: "Аренда vip авто | Прокат вип машин без водителя в RentRide",
            description: "Большой выбор элитных vip машин в аренду рядом с вами." + metaTitleFree
        },
    },
};

export const GLOSSARY_EXTENSIONS_CATALOG = {
    'seats': {
        withValue: true,
        title: "Аренда авто на {seats} мест",
        meta: {
            title: "Выгодная аренда автомобиля с {seats} местами без водителя рядом с вами! " + metaTitleNoLimit,
            description: "Аренда автомобилей на {seats} человек без водителя | Прокат машин в RentRide",
        },
    },
    'metro': {
        title: "Аренда автомобилей у метро {metro}",
        meta: {
            title: "Аренда авто у метро {metro} без водителя | Прокат машин в RentRide",
            description: "Большой выбор автомобилей в аренду рядом с вами. " + metaTitleFree,
        },
    },
    'casco': {
        title: "Аренда авто со страховкой КАСКО",
        meta: {
            title: "Аренда автомобилей застрахованных по КАСКО без водителя | Прокат машин в RentRide",
            description: "Выгодная аренда автомобиля со страховкой КАСКО без водителя рядом с вами! " + metaTitleNoLimit,
        },
    },
    'osago': {
        title: "Аренда авто со страховкой ОСАГО",
        meta: {
            title: "Аренда автомобилей застрахованных по ОСАГО без водителя | Прокат машин в RentRide",
            description: "Выгодная аренда автомобиля со страховкой ОСАГО без водителя рядом с вами! " + metaTitleNoLimit,
        },
    },
    'for-a-day': {
        title: "Аренда машины на сутки без водителя",
        meta: {
            title: "Аренда авто без водителя на сутки - прокат машины на день в {city} недорого",
            description: "Аренда машины посуточно без водителя недорого! " + metaTitleNoLimit,
        },
    },
    'weekend': {
        title: "Аренда автомобилей на 2-3 дня и на выходные",
        meta: {
            title: "Аренда авто на выходные, на 2-3 дня без водителя | Прокат машин в RentRide",
            description: "Аренда машины посуточно на 2-3 дня без водителя для поездки на выходные! " + metaTitleNoLimit,
        },
    },
    'half-week': {
        title: "Аренда автомобилей на 4-6 дней",
        meta: {
            title: "Аренда авто на 4-5-6 дней без водителя | Прокат машин в RentRide",
            description: "Аренда машины посуточно на 4, 5, 6 дней без водителя рядом с вами! " + metaTitleNoLimit,
        },
    },
    'longtrip': {
        title: "Аренда автомобилей на неделю",
        meta: {
            title: "Аренда авто на 7 дней без водителя | Прокат машин в RentRide",
            description: "Аренда машины на неделю / 7 дней без водителя рядом с вами! " + metaTitleNoLimit,
        },
    },
    'mileage-unlimited': {
        title: "Аренда автомобилей без ограничения пробега",
        meta: {
            title: "Аренда авто без ограничения пробега по России | Прокат машин в RentRide",
            description: "Аренда машины без лимита пробега по России без водителя рядом с вами! " + metaTitleNoLimit,
        },
    },
    'without-experience': {
        title: "Аренда автомобилей без стажа вождения",
        meta: {
            title: "Аренда авто без стажа и опыта вождения | Прокат машин для водителей-новичков в RentRide",
            description: "Большой выбор машин в аренду для водителей-новичков без опыта и стажа вождения. " + metaTitleFree,
        },
    },
    'kabriolet': {
        title: "Аренда кабриолета",
        meta: {
            title: "Аренда кабриолетов | Прокат машин без водителя в RentRide",
            description: "Большой выбор кабриолетов в аренду рядом с вами. " + metaTitleFree,
        },
    },
    'limuzin': {
        title: "Аренда лимузина",
        meta: {
            title: "Аренда лимузинов | Прокат свадебных машин без водителя в RentRide",
            description: "Большой выбор лимузинов в аренду рядом с вами. " + metaTitleFree,
        },
    },
    'miniven': {
        title: "Аренда минивэна без водителя",
        meta: {
            title: "Аренда минивэнов | Прокат машин для поездки по России без водителя в RentRide",
            description: "Большой выбор минивэнов в аренду рядом с вами. " + metaTitleFree,
        },
    },
    'mikroavtobus': {
        title: "Аренда микроавтобуса",
        meta: {
            title: "Аренда микроавтобусов без водителя | Прокат машин для поездки по России в RentRide",
            description: "Большой выбор микроавтобусов в аренду рядом с вами. " + metaTitleFree,
        },
    },
    'furgon': {
        title: "Аренда фургона или грузового авто",
        meta: {
            title: "Аренда фургонов без водителя | Прокат машин в RentRide",
            description: "Большой выбор фургонов в аренду рядом с вами. " + metaTitleFree,
        },
    },
    'four-wheel-drive': {
        title: "Аренда полноприводных автомобилей",
        meta: {
            title: "Аренда авто с полным приводом | Прокат машин без водителя в RentRide",
            description: "Аренда машины с полным приводом без водителя рядом с вами! " + metaTitleNoLimit,
        },
    },
    'year': {
        title: "Поиск и аренда автомобилей {year} года",
        meta: {
            title: "Аренда 🚗 авто {year} года в {city}. Сервис проката автомобилей RentRide.",
            description: "Возьми недорого напрокат автомобиль {year} года в {city}. " + metaTitleNoLimit,
        },
    },
    'delivery_address': {
        title: "Аренда автомобиля с доставкой на конкретный адрес",
        meta: {
            title: "Аренда 🚗 автомобиля в {city} с доставкой на конкретный адрес - RentRide",
            description: "Возьми недорого напрокат автомобиль без водителя в {city} с доставкой на конкретный адрес",
        },
    },
    'delivery_airport': {
        title: "Аренда авто в аэропорту {delivery_airport}",
        meta: {
            title: "Аренда 🚗 автомобиля с доставкой в аэропорт {delivery_airport}, в {city} - RentRide",
            description: "Возьми недорого напрокат автомобиль с доставкой в аэропорт {delivery_airport}, в {city}. Условия автопроката: ✓ без водителя ✓с залогом и без ✓ на сутки, неделю, месяц",
        },
    },
}

export const APP_LINKS = {
    ios: 'https://redirect.appmetrica.yandex.com/serve/962122372310146748',
    android: 'https://redirect.appmetrica.yandex.com/serve/385661615409753446',
    universal: 'https://redirect.appmetrica.yandex.com/serve/1036645686487093344',
}


export const USER_PAY_TYPE = {
    legalEntity: 1,
    individual: 2,
};


export const CAR_PRICE_STEPS = {
    days: [4, 7, 14, 21],
    percent: [5, 10, 15, 20],
};

export const AUTO_MIN_PRICE_PROC = 100 - CAR_PRICE_STEPS.percent[CAR_PRICE_STEPS.percent.length - 1];

export const YEAR_FORMAT = '[1-2][0,9][0-9][0-9]';

export const SIGN_UP_SOURCE = {
    TAB_REQUESTS: 'tab_requests',
    TAB_MY_CARS: 'tab_my_cars',
    TAB_MESSENGER: 'tab_messenger',
    TAB_PROFILE: 'tab_profile',
    HEADER: 'header',
    CAR_PAGE: 'car_page',
    BONUS_POINTS_PROMO: 'bonus_points_promo',
    SDAT: 'sdat',
    HEADER_SDAT: 'header_sdat',
}
