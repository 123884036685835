import Vue from 'vue';
import { getStore, onSuccessDataPages, querySerializer } from "@/utils/store";
import { StoreApi } from "~/lib/storeRestApi";
import { defaultMarketplaceFilters, FILTERS } from "@/utils/_consts";
import _castArray from "lodash/castArray"
import {
    filterIsArray,
    getDefaultMarketplaceFilter,
    getRouterParams,
    isMarketplaceCountIgnoreFilter,
    isMarketplaceCountPairedFilter,
} from "@/utils/filters";
import { getMinPeriodH } from '@/utils/car/getMinPeriodH';

const store = new StoreApi({
    state: {
        car: {},
        cars: {
            list: [],
            total: 0,
            pages: 0,
            currentPage: 0,
            paramsReq: null,
        },
        reviews: {
            list: [],
            total: 0,
            pages: 0,
            currentPage: 0,
            paramsReq: null,
        },
        filters: {...defaultMarketplaceFilters},
        sort: undefined,
        source_info: {}
    }
}).get({
    action: "_getCars",
    property: "cars",
    path: ({ landing }) => landing ? `/landings/${landing}/cars` : '/cars',
    requestConfig: querySerializer,
    headers(params) {
        return params?.headers;
    },
    onSuccess(s, { data }, axios, { params, infinite }) {
        const { passiveRequest, ...paramsReq } = (params || {});

        if (passiveRequest) {
            return;
        }

        let cars = s.cars;

        if(infinite) cars.list = cars.list.concat(data.data);
        else cars.list = data.data;

        cars.total = data.meta.total;
        cars.pages = data.meta.last_page;
        cars.currentPage = data.meta.current_page;
        cars.paramsReq = paramsReq;
        cars.prices = data.prices;
        cars.pagePost = data.page_post;
    }
}).get({
    action: "getCarDetails",
    property: "car",
    path: ({ id }) => `/cars/${id}`,
}).get({
    action: "getCarBenefits",
    property: "carBenefits",
    path: ({ id }) => `/cars/${id}/benefits`,
    requestConfig: querySerializer,
}).get({
    action: "getCarsGroup",
    property: "carsGroup",
    path: '/cars',
    requestConfig: querySerializer,
    onSuccess(s, { data }) {
        s.carsGroup = data.data[0];
    },
}).get({
    action: "getOwnerOtherCars",
    property: "ownerOtherCars",
    path: ({id}) => `/cars/${id}/other`,
    requestConfig: querySerializer,
    onSuccess(s, { data }) {
        s.ownerOtherCars = data.data;
    },
}).post({
    action: "addCarReviews",
    property: "resultAddCarReviews",
    path: '/review',
}).get({
    action: "getCarReviews",
    property: "reviews",
    path: '/review',
    requestConfig: querySerializer,
    onSuccess: onSuccessDataPages('reviews'),
}).post({
    action: "addCarReviewsAnswer",
    property: "addCarReviewsAnswerResult",
    path: ({id}) => `/review/${id}/answer`,
    onSuccess(s, {data: {data: answer}}) {
        s.addCarReviewsAnswerResult = answer;
        const parentReview = s.reviews.list.find(review => review.id === answer.car_review_id);
        Vue.set(parentReview, 'answer', [
            ...parentReview.answer,
            answer,
        ]);
    },
}).delete({
    action: "deleteCarReviewsAnswer",
    property: "deleteCarReviewsAnswerResult",
    path: ({id}) => `/review/${id}/answer`,
    onSuccess(s, {data: deletedAnswer}) {
        s.deleteCarReviewsAnswerResult = deletedAnswer;
        const parentReview = s.reviews.list.find(review => review.id === deletedAnswer.car_review_id);
        const updatedParentReviewAnswer = parentReview.answer.filter(({id}) => id !== deletedAnswer.id);
        Vue.set(parentReview, 'answer', updatedParentReviewAnswer);
    },
}).put({
    action: "updateCarImagePosition",
    property: "updateCarImagePositionResult",
    path: ({carId, imageId}) => `/cars/${carId}/images/${imageId}/update-position`,
}).put({
    action: "updateCarImageRotate",
    property: "updateCarImageRotateResult",
    path: ({ carId, imageId }) => `/cars/${carId}/images/${imageId}/rotate`,
});

export default getStore(store, {
    actions: {
        getCars({ state, dispatch, rootState, rootGetters }, {
            infinite,
            selection,
            city,
            exclude,
            headers,
            filter = {},
            allowDuplicate,
            ...params
        }) {
            const { extension, landing, ...filters } = state.filters;
            return dispatch('_getCars', {
                infinite,
                params: {
                    landing,
                    filter: {
                        selection,
                        city: city,
                        exclude,
                        ...filters,
                        ...filter,
                    },
                    sort: state.sort,
                    ...params,
                },
                headers,
                allowDuplicate,
            });
        },
        setFiltersRawsClean({ dispatch, commit }, filters) {
            commit('cleanFilter');
            return dispatch('setFiltersRaw', filters);
        },
        setFiltersRaw({ state, commit }, { sort, ...filters } = {}) {
            if (sort !== undefined) commit('setSort', sort);
            FILTERS.ARRAY_FILTERS.forEach(key => {
                filters[key] = filters[key] ? _castArray(filters[key]) : [];
            });
            FILTERS.NUMBER_FILTERS.forEach(key => {
                if (filters[key]) {
                    if (filterIsArray(key)) filters[key] = filters[key].map(el => parseInt(el)).filter(el => !isNaN(el));
                    else {
                        const val = parseInt(filters[key]);
                        filters[key] = isNaN(val) ? undefined : val;
                    }
                }
            });
            commit('setFiltersObj', filters);
        }
    },
    mutations: {
        cleanFilter(s, filters = {}) {
            s.filters = {
                ...defaultMarketplaceFilters,
                ...filters,
            };
        },
        removeFilter(s, filter) {
            const alias = filter?.alias ?? filter;
            const filterToRemove = filter?.filter;
            const defaultFilter = getDefaultMarketplaceFilter(alias);

            if (filterToRemove) {
                if (FILTERS.ARRAY_FILTERS.includes(alias)) {
                    let updatedFilter = s.filters[alias].filter(filter => filter !== filterToRemove);
                    if (defaultFilter && updatedFilter.length === 0) {
                       updatedFilter = defaultFilter;
                    }
                    Vue.set(s.filters, alias, updatedFilter);
                } else {
                    if (defaultFilter) {
                        Vue.set(s.filters, alias, defaultFilter);
                    } else {
                        Vue.delete(s.filters, alias);
                    }
                }

            } else {
                if (defaultFilter) {
                    Vue.set(s.filters, alias, defaultFilter);
                } else {
                    Vue.delete(s.filters, alias);
                }
            }

            if (process.client) {
                $nuxt.$router.replace(getRouterParams(s.filters, $nuxt.$route)).catch(()=>{});
            }
        },
        setFilter(s, {name, val}){
            if (FILTERS.ALLOWED.includes(name)) {
                Vue.set(s.filters, name, val);
            }
            else if(name !== 'city') {
                console.warn('Set not allowed filter:', name, val);
            }
        },
        setFiltersObj(s, filters){
            for(let name in filters) {
                if (FILTERS.ALLOWED.includes(name) || name === 'extension') {
                    Vue.set(s.filters, name, filters[name]);
                }
            }
        },
        reviewsReset(s){
            s.reviews.list = [];
            s.reviews.total = 0;
            s.reviews.pages = 0;
            s.reviews.currentPage = 0;
            s.reviews.paramsReq = null;
        },
        carsReset(s){
            //if(!objIsEmpty(s.filters)) s.filters = {};
            s.cars.list = [];
            s.cars.total = 0;
            s.cars.pages = 0;
            s.cars.paramsReq = null;
        },
        setSort(s, type) {
            s.sort = type || undefined;
        },
        setCarImages(s, images) {
            s.car.images = images;
        },
        setCar(s, car) {
            s.car = car;
        },
        setPendingCars(s, pending) {
            s.pending.cars = pending;
        },
    },
    getters: {
        countFilters(s) {
            return Object.keys(s.filters).reduce((n, key) => {
                const filter = s.filters[key];
                if (isMarketplaceCountPairedFilter(key, s.filters)) return n;
                if (FILTERS.ARRAY_FILTERS.includes(key)) {
                    const count = filter?.length ? 1 : 0;
                    return n + count;
                } else if (filter) {
                    const isIgnoredFilter = isMarketplaceCountIgnoreFilter(key, filter);
                    if (isIgnoredFilter) {
                        return n;
                    }
                    return n + 1;
                }
                return n
            }, 0);
        },
        activeFilters(s) {
            return Object.keys(s.filters).reduce((activeFilters, alias) => {
                const filter = s.filters[alias];

                if (!isMarketplaceCountIgnoreFilter(alias, filter)) {
                    if ((FILTERS.ARRAY_FILTERS.includes(alias) ? filter?.length : filter)) {
                        activeFilters.push({alias, filter});
                    }
                }

                return activeFilters
            }, []);
        },
        flatActiveFilters(s, g) {
            return g.activeFilters.reduce((flatFilters, filterObject) => {
                if (Array.isArray(filterObject.filter)) {
                    filterObject.filter.forEach((filter) => flatFilters.push({
                        ...filterObject,
                        filter,
                    }));
                } else {
                    flatFilters.push(filterObject);
                }
                return flatFilters;
            }, []);
        },
        minPeriodH: function (s) {
            return getMinPeriodH(s.car);
        },
        reviewsEmpty(s){
            return !s.reviews.total;
        },
        carsEmpty(s){
            return !s.cars.total;
        },
        getGroup: (s) => (groupId) =>{
            if(s.carsGroup && s.carsGroup.id === groupId) {
                return s.carsGroup;
            }
            else {
                return s.cars.list.find(({id, items})=>items && id === groupId);
            }
        },
        periodDeal({filters: {date_from, date_to}}){
            return {
                period_start: date_from,
                period_end: date_to
            }
        },
        getFilterValueString: (s, g, rs, rootGetters) => (glossary, filterName) => {
            const formatSingleFilter = title => {
                if(title) {
                    return `${filterName}: ${title}`;
                }
                return filterName;
            };
            const activeFilterAlias = s.filters[glossary];
            const filterOptions = activeFilterAlias && rootGetters['glossary/getGlossaryCarList'](glossary);
            const expandedFormat = !filterName;
            const isArray = Array.isArray(activeFilterAlias);

            if (activeFilterAlias && filterOptions) {
                if (isArray) {
                    const filtersLength = activeFilterAlias.length;
                    const activeFilterOptions = filterOptions.filter(({alias}) => {
                        return activeFilterAlias.includes(alias);
                    });
                    if (filtersLength > 1) {
                        if(expandedFormat) {
                            const string = activeFilterOptions.map(({title}) => title).join(', ');
                            if(string.length > 26) {
                                return `${string.slice(0, 20)} ... (${filtersLength})`;
                            }
                            else {
                                return string;
                            }
                        }
                        return `${filterName}, (${filtersLength})`;
                    }
                    else if(activeFilterOptions[0]) {
                        if(expandedFormat) {
                            return activeFilterOptions[0].title;
                        }
                        return formatSingleFilter(activeFilterOptions[0].title);
                    }
                    return '';
                }
                const activeFilterOption = filterOptions.find(({alias}) => alias === activeFilterAlias);
                if (activeFilterOption) {
                    if(expandedFormat) {
                        return activeFilterOption.title;
                    }
                    return formatSingleFilter(activeFilterOption.title);
                }
            }
            return '';
        }
    }
});
