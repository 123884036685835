export default ({app, $auth, store}) => {
    app.$sentry.setTag("loggedIn", $auth.loggedIn);
    setUser();

    $auth.$storage.watchState('loggedIn', (loggedIn) => {
        setUser(true);
        if (!loggedIn) {
            app.$signals.authLoggedOut();
        }
    });

    function setUser(forced = false) {
        const u = $auth.user;
        if (u || forced) {
            app.$sentry.setUser({
                id: u?.id,
                email: u?.email,
                username: u?.phone,
            });

            if (process.client) {
                app.$dataLayerConfig('user_id', u?.id);//userId
            }
        }
    }
}
