import {createAnalyticsProduct} from "~/utils/analytics/products/createAnalyticsProduct";
import {roundDaysDiff} from "~/utils/date";

export function dealProlongationToAnalyticsProduct(dealProlongation = {}) {
    const {id, price, period_start, period_end} = dealProlongation;
    const dealProlongationDuration = roundDaysDiff(period_start, period_end);

    return createAnalyticsProduct({
        id,
        name: `Prolongation #${id}`,
        price: price,
        quantity: dealProlongationDuration,
        periodStart: period_start,
        periodEnd: period_end,
    });
}
