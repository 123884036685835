export function checkWebpSupport() {
    return new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = () => {
            resolve((img.width > 0) && (img.height > 0));
        };
        img.onerror = () => {
            resolve(false);
        };
        img.src = "data:image/webp;base64,UklGRiIAAABXRUJQVlA4IBYAAAAwAQCdASoBAAEADsD+JaQAA3AAAAAA";
    })
}
