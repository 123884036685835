import { DAY } from "@/utils/date";

export function getMinPeriodH(car) {
    if (!car?.prices) return 0;

    const getPeriod = ({ min_time, type } = {}) => (type - 1) ? min_time * 24 : min_time;

    const prices = car.prices;
    return prices.reduce((min, price) => {
        return Math.min(min, getPeriod(price));
    }, getPeriod(prices[0]) || 0);
}

export function getCarMinRentPeriod(car, actually = false) {
    const minDays = car.min_time || 0;
    return minDays * DAY - (actually ? DAY / 2 : 0);
}
