import moment from "moment";
import {declensionDays, declensionHours, declensionMinutes, declensionMonths, declensionYears} from "@/utils/word";

export const DAY = 8.64e+7;
export const HOUR = 3.6e+6;
export const MINUTE = 60000;

export function getUnixDate(date, format = "DD.MM.YYYY"){
    return moment(date, format).unix();
}
export function getDateFromUnix(unix, format = "DD.MM.YYYY"){
    return moment.unix(unix).format(format);
}
export function timePeriodString(period) {
    const t = (time, f) => time ? `${Math.floor(time)}${f}` : '';
    return `${t(period / DAY, ' сут.')} ${t(period % DAY / HOUR, 'ч')} ${t(period % DAY % HOUR / MINUTE, 'м')}`
}
export function timePeriodLongString(period) {
    const get = (time, declension) => time ? declension(time) : '';

    const d = Math.floor(period / DAY);
    const h = Math.floor(period % DAY / HOUR);
    const m = Math.floor(period % DAY / HOUR / MINUTE);

    return [get(d, declensionDays), get(h, declensionHours), get(m, declensionMinutes)].filter(el => el).join(', ');
}

export function timePeriodYearsMonths(period) {
    const get = (time, declension) => time ? declension(time) : '';
    const duration = moment.duration(period);

    const m = duration.months();
    const y = duration.years();

    return [get(y, declensionYears), get(m, declensionMonths)].filter(el => el).join(', ');
}

export function roundDaysDiff(from, to) {
    return Math.round(moment(to).diff(from, 'days', true));
}

export function normalizeShortDate(date) {
    if(date) {
        return date * 1000;
    }
}

export function getDurationFromDayStart(date) {
    return date - moment(date).startOf('day');
}

export function setTimeInDay(date, time) {
    return +moment(date).startOf('day').add(time);
}

export function getStartOfDay(date) {
    return +moment(date).startOf('day');
}

export function isSameDay(date1, date2) {
    return moment(date1).isSame(date2, 'day');
}
