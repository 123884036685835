import {StoreApi} from "~/lib/storeRestApi";
import {getStore} from "~/utils/store";
import Vue from "vue";

const api = new StoreApi({
    state: {
        promotion: null,
        segmentId: null,
        newbiesDiscountPromotionPanelIsHidden: false,
        activateNewUserPromo: false,
    },
}).get({
    action: "getPromotion",
    property: "promotion",
    path: ({ id }) => `/promotions/${id}`,
    onSuccess(s, response) {
        Vue.set(s, 'promotion', response.data.data);
    },
}).put({
    action: "activatePromotion",
    property: "activatePromotionResult",
    path: ({ segmentId }) => `/promotions/${segmentId}/activate`,
});

export default getStore(api, {
    mutations: {
        hideNewbiesDiscountPromotionPanel(s) {
            s.newbiesDiscountPromotionPanelIsHidden = true;
        },
        setPromotion(s, promotion) {
            s.promotion = promotion;
        },
        setSegmentId(s, id) {
            s.segmentId = id;
        },
        setActivateNewUserPromo(s, flag) {
            s.activateNewUserPromo = flag;
        },
    },
})
