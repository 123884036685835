const locale = 'ru-RU';

export function formatNumber(number) {
    if (number != null) {
        if (Math.abs(number) > 9999) {
            return number.toLocaleString(locale);
        }
        return number.toString();
    }
    return '';
}
