import {
    defaultMarketplaceFilters,
    EXTENSIONS_CATALOG,
    FILTERS,
    marketplaceCountIgnoreFilters,
    YEAR_FORMAT,
} from "./_consts";
import _mapValues from "lodash/mapValues";
import _defaults from "lodash/defaults"
import _forEach from "lodash/forEach";
import _castArray from "lodash/castArray"
import _union from "lodash/union"
import { isEqualRoutes, ROUTE_CARS } from "./router";
import _isEqual from "lodash/isEqual";
import _pickBy from "lodash/pickBy";
import { mapObjectValues } from "./mapObject";
import _invert from 'lodash/invert';
import objectDeepLower from './objects/objectDeepLower';
import _isString from 'lodash/isString';

const defaultMarketplaceFiltersAliases = Object.keys(defaultMarketplaceFilters);
const catalogRouteDefaultName = 'arendovat-city';
const catalogRouteNameVehicle = (type = '') => {
    if (type && type !== 'car') {
        return catalogRouteDefaultName.replace('-', `-${type}-`);
    }
    return catalogRouteDefaultName;
};
export const getVehicleFromRoute = (routeName) => {
    const vehicleType = routeName.match(new RegExp(catalogRouteDefaultName.replace('-', '-(.*)-')))?.[1];
    return vehicleType ?? getDefaultMarketplaceFilter('vehicle_type');
};

function addParamToFilter(filters, _name, _val) {
    const name = FILTERS.ALIASES_NAME[_name] ?? _name;
    let val = getAliasValue(_val, FILTERS.ALIASES_VAL, FILTERS.UPPER.includes(name));
    if (filterIsArray(name) && filters[name]) {
        filters[name] = _union(_castArray(filters[name]), _castArray(val));
    } else filters[name] = val
}

export function getFiltersUrl({ name, query, params }, store) {
    return new Promise((resolve, reject) => {
        const filters = getFiltersFromQuery(query, {
            vehicle_type: getVehicleFromRoute(name),
        });

        return getGlossaries(store).then((g) => {
            if (!params.filter && !params.city) resolve(filters);
            else {
                const filter = getAliasValue(params.filter, FILTERS.ALIASES_VAL);

                if (Object.keys(EXTENSIONS_CATALOG).includes(filter)) {
                    filters.extension = filter;
                } else if (FILTERS.BOOLEAN.includes(filter)) {
                    filters[filter] = 1;
                } else {
                    const section = store.getters['glossary/getLandingCatalog'](filter, params.city);
                    if (section) {
                        filters.landing = section.alias;
                    } else {
                        const filterGlossary = checkIsYearParam(filter) || findGlossaryByAlias(store.state.glossary, filter);
                        if (filterGlossary) addParamToFilter(filters, filterGlossary, filter);

                        const filterValue = filterGlossary === 'brand' && store.getters['glossary/getGlossaryOption'](filterGlossary, filter);

                        if (params.subfilter) {
                            const subfilterGlossary = params.subfilter && findGlossaryByAlias(
                                store.state.glossary,
                                params.subfilter,
                                ['brand'],
                                (glossary, aliasName, { alias, brand_id }) => {
                                    if (alias !== aliasName) {
                                        return false;
                                    } else if (glossary === 'brand_model') {
                                        return brand_id === filterValue?.id;
                                    } else {
                                        return true;
                                    }
                                },
                            );
                            if (subfilterGlossary) addParamToFilter(filters, subfilterGlossary, params.subfilter);
                        }
                    }
                }
                resolve(filters);
            }
        }, reject)
    });
}

function checkIsYearParam(val) {
    const reg = new RegExp(`^${YEAR_FORMAT}$`);
    if (reg.test(val)) {
        const curYar = (new Date()).getFullYear();
        return parseInt(val) <= curYar ? 'year' : false;
    }
}

export function getGlossaries({ state, dispatch }) {
    return new Promise((resolve, reject) => {
        let tasks = [];
        if (!state.glossary?._readyGlossariesDealOptions) {
            tasks.push(dispatch('glossary/getGlossariesDeal'));
        }
        if (!state.glossary?._readyGlossariesOptions) {
            tasks.push(dispatch('glossary/getGlossariesOptions'));
        }
        if (!state.glossary?._readyGlossariesClasses) {
            tasks.push(dispatch('glossary/getGlossariesClasses'));
        }
        if (!state.glossary?._readyGlossariesLandings) {
            tasks.push(dispatch('glossary/getGlossariesLandings'));
        }
        if (!state.glossary?._readyGlossaries) {
            tasks.push(dispatch('glossary/getGlossaries'));
        }
        if (!state.glossary?._readyRedirectURLs) {
            tasks.push(dispatch('glossary/getRedirectURLs'));
        }
        return Promise.all(tasks).then(resolve, reject);
    });
}

function findGlossaryByAlias(state, _aliasName, exclude = [], predicate) {
    const aliasName = getAliasValue(_aliasName, FILTERS.ALIASES_VAL);
    if (!aliasName) return;
    const { glossariesOptions, glossariesCar, glossariesDeal, glossariesAddress: { metro, airport } } = state;

    return findGlossary(glossariesCar, aliasName, (el) => el, exclude, predicate) ||
        findGlossary(glossariesOptions, aliasName, ({ values }) => values, exclude, predicate) ||
        //findGlossary(glossariesDeal, aliasName, (el)=>el, exclude, predicate) ||
        findGlossary({ metro }, aliasName, (el) => el, exclude, predicate) ||
        findGlossary({ airport }, aliasName.toUpperCase(), (el) => el, exclude, predicate);
}

function findGlossary(glossaries, aliasName, getList, exclude = [], predicate) {
    return Object.keys(glossaries).find((name) => {
        if (!exclude.includes(name)) {
            const customPredicate = predicate
                ? ((...args) => predicate(name, aliasName, ...args))
                : (({ alias }) => alias === aliasName);

            const list = getList(glossaries[name]);
            if (Array.isArray(list)) {
                return list.some(customPredicate);
            }
        }
    });
}

function getFiltersFromQuery(query, filters = {}) {
    return Object.keys(query).reduce((filters, name) => {
        if (checkQueryFilter(name, query[name])) {
            filters[name] = getAliasValue(query[name], FILTERS.ALIASES_VAL, FILTERS.UPPER.includes(name));
        }
        return filters;
    }, filters);
}

function getAliasValue(value, aliases, upper = false) {
    const getVal = upper ? toUpperCase : ((v) => v);

    if (Array.isArray(value)) {
        return value.map(el => getVal(aliases[el] ?? el));
    } else {
        return getVal(aliases[value] ?? value);
    }
}

function toUpperCase(str) {
    return _isString(str) ? str.toUpperCase() : str;
}

function checkQueryFilter(name, val) {
    return val && //Фильтр не пустой
        FILTERS.ALLOWED.includes(name); //Только параметры фильтров
}

function validateFilterLength(filters, filter) {
    // Не значение из одного символа или флаг
    return filters[filter] && filters[filter].toString().length > 1 || FILTERS.BOOLEAN.includes(filter)
}

function getFirstParam(filters, primarilyList = []) {
    const primarily = primarilyList.find((key) => key in filters && filters[key]);
    if (primarily) {
        return primarily;
    }

    return Object.keys(filters).sort().find(filter => {
        return checkQueryFilter(filter, filters[filter]) &&
            filter !== 'extension' && //Не расширенное название
            filter !== 'brand_model' && //Не модель
            filter !== 'vehicle_type' && //Не тип ТС
            validateFilterLength(filters, filter) &&
            !filterIsNumber(filter, ['year']); //Кроме числовых значений
    });
}

export function filterIsNumber(filter, exceptions = []) {
    return exceptions.includes(filter) ? false : FILTERS.NUMBER_FILTERS.includes(filter);
}

export function filterIsArray(filter) {
    return FILTERS.ARRAY_FILTERS.includes(filter);
}

export function filterUpper(alias, val) {
    return (FILTERS.UPPER.includes(alias) && _isString(val)) ? val.toUpperCase() : val;
}

function getSingleFilter(value) {
    return Array.isArray(value)
        ? value?.length === 1 && value[0]
        : value;
}

export function getRouterParams(filtersObj, route) {
    /*const filters = _mergeWith(_cloneDeep(route.query), _cloneDeep(filtersObj), (objValue, srcValue, key) => {
        if(filterIsArray(key) && objValue && srcValue) {
            return _union(_castArray(objValue), _castArray(srcValue)).sort();
        }
    });*/
    const filters = objectDeepLower(appendDefaultValuesToFiltersObj(filtersObj));
    FILTERS.ARRAY_FILTERS.forEach(key => {
        if (Array.isArray(filters[key])) filters[key].sort();
    });

    let subfilter;
    const brandModel = getSingleFilter(filters.brand_model);
    if (brandModel && !filters.extension && !filters.landing) {
        if (filters.brand) {
            subfilter = brandModel;
        }
        delete filters.brand_model;
    }

    let name = route.name;
    const vehicleTypeRoute = getVehicleFromRoute(name);
    const vehicleType = filters.vehicle_type;

    if (vehicleType) {
        if (vehicleType !== vehicleTypeRoute) {
            name = name.replace(
                catalogRouteNameVehicle(vehicleTypeRoute),
                catalogRouteNameVehicle(vehicleType),
            );
        }
    } else {
        if (vehicleTypeRoute) {
            name = name.replace(
                catalogRouteNameVehicle(vehicleTypeRoute),
                catalogRouteDefaultName,
            );
        }
    }

    if (route.params && !route.params.hasOwnProperty('filter')) {
        FILTERS.WITHOUT_QUERY.forEach(key => {
            delete filters[key];
        });

        return {
            ...route,
            name,
            query: filters,
        }
    }

    const filterName = getMainFilter(filters);
    let filter;
    if (FILTERS.BOOLEAN.includes(filterName)) {
        filter = getAliasValue(filterName, _invert(FILTERS.ALIASES_VAL));
    } else {
        filter = filters[filterName];
    }

    if (filterIsArray(filterName)) {
        filters[filterName] = _castArray(filter);
        filter = filters[filterName][0];
        filters[filterName].splice(0, 1);
        if (!filters[filterName].length) delete filters[filterName];
    } else delete filters[filterName];

    FILTERS.WITHOUT_QUERY.forEach(key => {
        delete filters[key];
    });

    return {
        ...route,
        name,
        params: {
            ...route.params,
            filter,
            subfilter,
        },
        query: filters,
    }
}

export function getMainFilter(filters) {
    return getFirstParam(filters, ['landing', 'brand']) ?? 'extension'
}

export function getMainFiltersList(filters) {
    const params = [];
    const main = getMainFilter(filters);
    if (main) params.push(main);
    if (main === 'brand' && filters.brand_model?.length) params.push('brand_model');
    return params;
}

export function validParamsFilter(params, defaults) {//Удаляет all из параметров и заполняет стандартными значениями
    let paramsValid = _mapValues(params, (val) => val === 'all' ? undefined : val);
    paramsValid = _defaults(paramsValid, defaults);
    cleanParams(paramsValid, params);
    if (!_isEqual(params, paramsValid)) return paramsValid;
}

function cleanParams(newParams, oldParams) {
    _forEach(newParams, function (val, param) {
        if (!oldParams.hasOwnProperty(param)) {
            delete newParams[param];
        }
    });
}

export function cleanQuery(query) {//Удаляет поля в верхнем регистре
    const queryValid = _pickBy(query, (val, key) => key.toUpperCase() !== key);
    if (!_isEqual(query, queryValid)) return queryValid;
}

export function checkingFilterExceptions(filters, { params, name, query }) {//Проверяет что модель машины, находится в параметрах
    if (filters.brand === params.filter && filters.brand_model !== params.subfilter) {
        let validQuery = Object.assign({}, query);
        delete validQuery.brand_model;
        return {
            name,
            query: validQuery,
            params: {
                ...params,
                subfilter: filters.brand_model,
            },
        };
    }
}

function isCity(city, name) {
    if (!city?.length) return true;
    return findGlossary({ city }, name, (el) => el);
}

function normalizeFilters(filters) { //Привести фильтры в првильный формат (массивы)
    return Object.keys(filters).reduce((obj, key) => {
        if (filterIsArray(key) && filters[key]) obj[key] = _castArray(filters[key]);
        else obj[key] = filters[key];
        return obj;
    }, {})
}

function getValidCarCatalogCity(store, city) {
    const activeMetroList = store.state.cars.filters.metro;

    if (activeMetroList?.length) {
        const firstActiveMetro = activeMetroList[0];
        const metroList = store.state.glossary.glossariesAddress.metro;
        const activeMetroGlossary = metroList.find(metro => metro.alias === firstActiveMetro);
        const activeMetroCity = activeMetroGlossary?.line?.city?.alias;

        if (activeMetroCity) {
            if (activeMetroCity !== city) {
                return activeMetroCity;
            }
        }
    }

    return city;
}

function getValidCity(route, store) {
    const city = store.getters['glossary/currentCity'].alias;
    if (route.name === ROUTE_CARS) {
        return getValidCarCatalogCity(store, city);
    }
    return city;
}

export function redirectValidRoute(store, route, filters, $redirect) {
    const { name, params, query } = route;
    let queryAll = { ...query, ...filters };
    const curRoute = { name, params, query: normalizeFilters(query) };
    const city = getValidCity(route, store);
    if (!params.city || params.city !== city) {
        $redirect({ name, params: { city }, query: queryAll });
    } else if (params.city && params.city !== 'moskva' && !isCity(store.state.glossary.glossariesAddress.city, params.city)) { //Если параметр города не является городом, то это фильтр
        $redirect({ name, params: { city, filter: params.city }, query: queryAll });
    } else {
        let newRoute = getRouterParams(queryAll, curRoute);
        cleanParams(newRoute.params, params);
        if (!isEqualRoutes(newRoute, curRoute)) {
            $redirect({ name, ...newRoute });
        } else {
            return true;
        }
    }
}

export function filtersCleanByVal(filters, _values) {//Оставляет только те фильтры, которые есть в values
    const valuesRaw = _values.filter(v => v !== undefined);
    return Object.keys(filters).reduce((list, alias) => {
        const add = (value) => list.push({ alias, value });
        const value = filterIsNumber(alias) ? filters[alias]?.toString() : filters[alias];
        const values = valuesRaw.map((val) => filterUpper(alias, val));

        if (values.includes(value)) {
            add(filters[alias]);
        } else if (filterIsArray(alias) && Array.isArray(filters[alias])) {
            const val = filters[alias].find((filter) => values.includes(filter));
            if (val) {
                add(val);
            }
        } else if (FILTERS.BOOLEAN.includes(alias)) {
            add({ alias, value: alias });
        }
        return list;
    }, []);
}

export function isDefaultMarketplaceFilterAlias(alias) {
    return defaultMarketplaceFiltersAliases.includes(alias);
}

export function getDefaultMarketplaceFilter(alias) {
    if (isDefaultMarketplaceFilterAlias(alias)) {
        return defaultMarketplaceFilters[alias];
    }
    return null;
}

export function isDefaultMarketplaceFilter(alias, filter) {
    if (filter) {
        return defaultMarketplaceFilters[alias] === filter;
    }
    return false;
}

export function appendDefaultValuesToFiltersObj(filters = {}) {
    const defaultFiltersToAppend = mapObjectValues(defaultMarketplaceFilters, ([alias, defaultFilterValue]) => {
        const filterValue = filters[alias];
        return filterValue != null ? filterValue : defaultFilterValue;
    });
    return {
        ...filters,
        ...defaultFiltersToAppend,
    }
}

export function isMarketplaceCountIgnoreFilter(alias, filterValue) {
    if (marketplaceCountIgnoreFilters.includes(alias)) {
        return true;
    }
    const defaultFilterValue = defaultMarketplaceFilters[alias];
    return !!defaultFilterValue && defaultFilterValue === filterValue;

}

export function isMarketplaceCountPairedFilter(alias, filters) {
    const aliasFrom = () => alias.replace('_to', '_from');

    return alias.includes('_to') && filters[aliasFrom()];
}

export function errorsOnlyFilters(errors = {}) {
    const keys = Object.keys(errors);
    const result = keys.length && keys.every(key => key.startsWith('filter.'));
    return result ? keys.map(key => key.slice(7)) : false;
}
