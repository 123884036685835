import { createAnalyticsProduct } from "@/utils/analytics/products/createAnalyticsProduct";
import { getCarInfo } from "~/utils/meta";

export function carToAnalyticsProduct(car, analyticsObject) {
    if (!car) return null;
    const { brand, brandModel, title } = getCarInfo(car);

    return createAnalyticsProduct({
        id: car.id,
        name: title,
        ownerId: car.owner?.id,
        carClass: car.options?.car_class?.title,
        carBody: car.options?.cars_body?.title,
        brand,
        model: brandModel,
        city: car.city?.title,
        ...analyticsObject,
    });
}
