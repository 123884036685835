import Vue from "vue";
import _cloneDeep from "lodash/cloneDeep";

const Qs = require('qs');

export function getStore(apiStore, store = {}) {
    const api = apiStore.getStore({namespaced: true});
    return {
        ...api,
        state: () => _cloneDeep(Object.assign(store.state || {}, api.state)),
        getters: store.getters || {},
        mutations: Object.assign(store.mutations || {}, api.mutations),
        actions: Object.assign(store.actions || {}, api.actions),
    }
}

export const querySerializer = {
    paramsSerializer: function (params) {
        return Qs.stringify(params, {arrayFormat: 'brackets'})
    },
};

export function getStoreApiData(data = {}, params = {}, paramsData = {}) {
    return {payload: {data}, actionParams: {params: params, data: paramsData}};
}

export function pendingSome(pending) {
    return Object.values(pending).some(v => v);
}

export function cookiesSet($cookies, name, val, params = {}) {
    let date = new Date();
    date.setFullYear(date.getFullYear() + 10);
    $cookies.set(name, val, { path: '/', expires: date, ...params });
    return val;
}

export function onSuccessData(property) {
    return function (s, {data}) {
        Vue.set(s, property, Array.isArray(data?.data) ? data.data : data);
    }
}

export function onSuccessDataPages(property) {
    return function (s, {data: {data, meta}}, axios, params) {
        if (meta.current_page > s[property].currentPage) {
            s[property].list = s[property].list.concat(data);
        } else {
            s[property].list = data;
        }
        s[property].total = meta.total;
        s[property].pages = meta.last_page;
        s[property].currentPage = meta.current_page;
        s[property].paramsReq = params;
    }
}
