import {StoreApi} from "~/lib/storeRestApi";
import {getStore, onSuccessData} from "@/utils/store";

const api = new StoreApi({
    state: {
        page: null,
    }
}).get({
    action: "getPageData",
    onSuccess: onSuccessData('page'),
    path: ({alias}) => `/page/${alias}`
});

export default getStore(api);
