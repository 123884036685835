import { carToAnalyticsProduct } from "@/utils/analytics/products/carToAnalyticsProduct";
import { dealToAnalyticsProducts } from "@/utils/analytics/products/dealToAnalyticsProducts";
import { dealProlongationToAnalyticsProduct } from "~/utils/analytics/products/dealProlongationToAnalyticsProduct";
import { cleanObjects } from "@/utils/objects/cleanObjects";
import { GtmCarListsToProducts, GtmCarToProduct } from "@/utils/meta";

const defaultCurrency = 'RUB';
const defaultCity = 'moskva';

const aliasesMethodAuth = {
    phone: 'tel',
};

const GTMEventsSignalMap = {
    // Показы товаров
    impressions: ['catalog'],

    // Клики по товарам
    click: [],

    // Показы сведений о товарах
    detail: ['viewItem'],

    // Добавление товара в корзину
    add: ['addToCart'],

    // Оформление покупок
    checkout: ['booking1', 'booking2', 'booking3'],

    // Покупки
    purchase: ['purchase'],
};

function getGTMEvent(signalEvent) {
    const GTMEventEntry = Object.entries(GTMEventsSignalMap).find(([GTMEvent, signalEvents]) => {
        return signalEvents?.includes(signalEvent);
    });
    if (GTMEventEntry) {
        return GTMEventEntry[0];
    } else {
        return signalEvent;
    }
}


const createGTMCarData = (car, position) => ({
    products: [GtmCarToProduct(car, position)],
});

const createGTMCarsListData = (cars) => ({
    products: GtmCarListsToProducts(cars),
});

const createGTMDealData = (deal, otherActionField = {}) => ({
    ...createGTMCarData(deal.car),
    actionField: {
        id: deal.id,
        ...otherActionField,
    },
});

const createGTMCarCheckoutData = (car, step) => ({
    ...createGTMCarData(car),
    actionField: {
        step,
    },
});

function convertAnalyticsProducts(products) {
    if (!Array.isArray(products)) {
        return [products];
    } else {
        return products;
    }
}


export function createSignalsEmitters({app: {store}, $auth}, emitSignal) {
    const getUserId = () => $auth.user?.id;
    const getCity = () => store.state.city ?? defaultCity;

    const createDealSignalData = (deal) => ({
        items: convertAnalyticsProducts(dealToAnalyticsProducts(deal)),
        value: deal.payment_amount,
        currency: defaultCurrency,
        city: getCity(),
        userid: getUserId(),
    })

    const createDealProlongationSignalData = (deal, prolongation, transaction_id) => ({
        items: convertAnalyticsProducts([
            dealProlongationToAnalyticsProduct(prolongation),
            ...dealToAnalyticsProducts(deal),
        ]),
        value: prolongation?.price,
        currency: defaultCurrency,
        userid: getUserId(),
        transaction_id,
    });

    function emit({ event, category, signalData, GTMData: GTMRawData, label, GTMIgnore, logSettings }) {
        const GTMEvent = getGTMEvent(event);
        const GTMData = {
            [GTMEvent]: GTMRawData,
        };
        emitSignal(
            event,
            category,
            signalData,
            GTMData,
            label,
            GTMIgnore,
            logSettings,
        );
    }

    return {
        emit,
        visitPage() {
            emit({
                event: 'visit_page',
            });
        },
        signUp(method, userId, source) {
            emit({
                event: 'sign_up',
                category: 'auth',
                label: source,
                signalData: {
                    method: aliasesMethodAuth[method] ?? method,
                    userid: userId ?? getUserId(),
                },
            })
        },
        login(method) {
            emit({
                event: 'login',
                signalData: {
                    method: aliasesMethodAuth[method] ?? method,
                    userid: getUserId(),
                },
            })
        },
        viewItem(car) {
            emit({
                event: 'view_item',
                GTMData: createGTMCarData(car),
                signalData: {
                    items: convertAnalyticsProducts(carToAnalyticsProduct(car)),
                    currency: defaultCurrency,
                    city: getCity(),
                },
            });
        },
        booking1(car, form) {
            emit({
                event: 'booking1',
                category: 'car',
                label: car?.id,
                GTMData: createGTMCarCheckoutData(car, 1),
                signalData: {
                    items: convertAnalyticsProducts(carToAnalyticsProduct(car)),
                    ...cleanObjects(form),
                },
            });
        },
        booking2(car, form) {
            emit({
                event: 'booking2',
                category: 'car',
                label: car?.id,
                GTMData: createGTMCarCheckoutData(car, 2),
                signalData: {
                    items: convertAnalyticsProducts(carToAnalyticsProduct(car)),
                    ...cleanObjects(form),
                },
            });
        },
        booking3(car, form) {
            emit({
                event: 'booking3',
                category: 'car',
                label: car?.id,
                GTMData: createGTMCarCheckoutData(car, 3),
                signalData: {
                    items: convertAnalyticsProducts(carToAnalyticsProduct(car)),
                    ...cleanObjects(form),
                },
            });
        },
        clickFindWithoutLimits(car) {
            emit({
                event: 'click_find_without_limits',
                category: 'car',
                label: car?.id,
            });
        },
        addToCart(deal) {
            emit({
                event: 'add_to_cart',
                category: 'order',
                label: deal?.id,
                GTMData: createGTMDealData(deal),
                signalData: createDealSignalData(deal),
            });
        },
        orderConfirm(deal) {
            emit({
                event: 'order_confirm',
                category: 'request',
                label: deal?.id,
                GTMData: createGTMDealData(deal),
                signalData: createDealSignalData(deal),
            });
        },
        clickPrivacyPolicy(deal) {
            emit({
                event: 'click_privacy',
                category: 'request',
                label: deal?.id,
                GTMData: createGTMDealData(deal),
                signalData: createDealSignalData(deal),
            });
        },
        documentsAdd() {
            emit({
                event: 'documents_add',
                category: 'billing',
                signalData: {
                    userid: getUserId(),
                },
            });
        },
        documentsApprove() {
            emit({
                event: 'documents_approve',
            });
        },
        documentsReject() {
            emit({
                event: 'documents_reject',
            });
        },
        beginCheckout(deal) {
            emit({
                event: 'begin_checkout',
                category: 'order',
                signalData: {
                    items: convertAnalyticsProducts(dealToAnalyticsProducts(deal)),
                    value: deal.payment_amount,
                    currency: defaultCurrency,
                },
            });
        },
        purchase(deal, transaction_id) {
            emit({
                event: 'purchase',
                GTMData: createGTMDealData(deal, { transaction_id }),
                label: deal?.id,
                signalData: {
                    items: convertAnalyticsProducts(dealToAnalyticsProducts(deal)),
                    value: deal.payment_amount,
                    transaction_id,
                    shipping: 0,
                    currency: defaultCurrency,
                    userid: getUserId(),
                },
            });
        },
        addPaymentInfo(paymentType) {
            emit({
                event: 'add_payment_info',
                signalData: {
                    payment_type: paymentType,
                    currency: defaultCurrency,
                },
            });
        },
        sdatButton() {
            emit({
                event: 'sdat_button',
                category: 'cta_join_us',
            });
        },
        catalog({filters = {}, cars = []}) {
            emit({
                event: 'catalog',
                GTMData: createGTMCarsListData(cars),
                signalData: {
                    filters: cleanObjects(filters),
                },
            });
        },
        exploitationBegin(deal) {
            emit({
                event: 'exploitation_begin',
                GTMData: createGTMDealData(deal),
                signalData: createDealSignalData(deal),
            });
        },
        dealCancel(deal, isManager) {
            emit({
                event: 'deal_cancel',
                category: 'request',
                label: isManager ? 'owner' : 'client',
                GTMData: createGTMDealData(deal),
                signalData: createDealSignalData(deal),
            });
        },
        dealComplete(deal) {
            emit({
                event: 'deal_complete',
                GTMData: createGTMDealData(deal),
                signalData: createDealSignalData(deal),
            });
        },
        prolongationRequest(deal, prolongation) {
            emit({
                event: 'prolongation_request',
                GTMData: createGTMDealData(deal),
                signalData: createDealProlongationSignalData(deal, prolongation),
            });
        },
        prolongationConfirm(deal, prolongation) {
            emit({
                event: 'prolongation_confirm',
                GTMData: createGTMDealData(deal),
                signalData: createDealProlongationSignalData(deal, prolongation),
            });
        },
        prolongationCancel(deal, prolongation) {
            emit({
                event: 'prolongation_cancel',
                GTMData: createGTMDealData(deal),
                signalData: createDealProlongationSignalData(deal, prolongation),
            });
        },
        prolongationReject(deal, prolongation) {
            emit({
                event: 'prolongation_reject',
                GTMData: createGTMDealData(deal),
                signalData: createDealProlongationSignalData(deal, prolongation),
            });
        },
        prolongationPayed(deal, prolongation, transaction_id) {
            emit({
                event: 'prolongation_payed',
                GTMData: createGTMDealData(deal),
                signalData: createDealProlongationSignalData(deal, prolongation, transaction_id),
            });
        },
        createCar(car) {
            emit({
                event: 'create_car',
                label: car?.id,
                GTMData: createGTMCarData(car),
                signalData: {
                    items: convertAnalyticsProducts(carToAnalyticsProduct(car)),
                },
            });
        },
        moderateCar(car) {
            emit({
                event: 'moderate_car',
                GTMData: createGTMCarData(car),
                signalData: {
                    items: convertAnalyticsProducts(carToAnalyticsProduct(car)),
                },
            });
        },

        searchGoCar(car) {
            emit({
                event: 'go_car',
                category: 'search',
                label: car?.id,
                GTMData: createGTMCarData(car),
                signalData: {
                    items: convertAnalyticsProducts(carToAnalyticsProduct(car)),
                },
            });
        },
        searchClickShowMore() {
            emit({
                event: 'click_show_more',
                category: 'search',
            });
        },
        searchClickPage(page) {
            emit({
                event: 'click_page',
                category: 'search',
                label: page,
                GTMData: { page },
                signalData: { page },
            });
        },
        searchSeenCar(car) {
            emit({
                event: 'seen_car',
                category: 'search',
                label: car?.id,
                signalData: {
                    items: convertAnalyticsProducts(carToAnalyticsProduct(car)),
                },
                GTMIgnore: true,
            });
        },
        searchClickShowMap() {
            emit({
                event: 'click_show_map',
                category: 'search',
            });
        },
        searchClickShowList() {
            emit({
                event: 'click_show_list',
                category: 'search',
            });
        },
        filterSetFilter(name, value) {
            let val = value;
            if (Array.isArray(val)) {
                val = val.join('; ');
            } else if (typeof val === 'boolean') {
                val = val ? 'true' : 'false';
            } else if (val) {
                val = val.toString()
            }

            emit({
                event: 'set_filter_' + name,
                category: 'filter',
                label: val,
                GTMData: { val },
                signalData: { val },
            });
        },
        filterClickShowAll() {
            emit({
                event: 'click_show_all',
                category: 'filter',
            });
        },
        filterClickCalendar() {
            emit({
                event: 'click_calendar',
                category: 'filter',
            });
        },
        filterSetInterval(from, to) {
            emit({
                event: 'set_interval',
                category: 'filter',
                label: `${from ?? ''} ${to ?? ''}`,
                GTMData: { from, to },
                signalData: { from, to },
            });
        },
        filterSetFrom(from) {
            emit({
                event: 'set_from',
                category: 'filter',
                label: from,
                GTMData: { from },
                signalData: { from },
            });
        },
        filterSetTo(to) {
            emit({
                event: 'set_to',
                category: 'filter',
                label: to,
                GTMData: { to },
                signalData: { to },
            });
        },
        filterClickCityFromHeader(city) {
            emit({
                event: 'click_city_from_header',
                category: 'geo',
                label: city,
                GTMData: { city },
                signalData: { city },
            });
        },
        filterClickCityFromSearch(city) {
            emit({
                event: 'click_city_from_search',
                category: 'geo',
                label: city,
                GTMData: { city },
                signalData: { city },
            });
        },
        filterSetCity(city) {
            emit({
                event: 'set_city',
                category: 'geo',
                label: city,
                GTMData: { city },
                signalData: { city },
            });
        },
        carSwipePhoto(car, slide) {
            emit({
                event: 'swipe_photo',
                category: 'car',
                GTMData: {
                    slide,
                    ...createGTMCarData(car),
                },
                signalData: {
                    slide,
                    items: convertAnalyticsProducts(carToAnalyticsProduct(car)),
                },
            });
        },
        carClickReadMore(car) {
            emit({
                event: 'click_read_more',
                category: 'car',
                GTMData: createGTMCarData(car),
                signalData: {
                    items: convertAnalyticsProducts(carToAnalyticsProduct(car)),
                },
            });
        },
        carClickShowMap(car) {
            emit({
                event: 'click_show_map',
                category: 'car',
                GTMData: createGTMCarData(car),
                signalData: {
                    items: convertAnalyticsProducts(carToAnalyticsProduct(car)),
                },
            });
        },
        carSetRentIntervalFrom(car, from) {
            emit({
                event: 'set_rent_interval_from',
                category: 'car',
                label: from,
                GTMData: {
                    ...createGTMCarData(car),
                    from,
                },
                signalData: {
                    from,
                    items: convertAnalyticsProducts(carToAnalyticsProduct(car)),
                },
            })
        },
        carSetRentIntervalTo(car, to) {
            emit({
                event: 'set_rent_interval_to',
                category: 'car',
                label: to,
                GTMData: {
                    ...createGTMCarData(car),
                    to,
                },
                signalData: {
                    to,
                    items: convertAnalyticsProducts(carToAnalyticsProduct(car)),
                },
            });
        },
        //booking1(checkout)
        /*carClickBookUpCar(car,) {
            emit({
                event: 'click_book_up_car',
                category: 'car',
                GTMData: createGTMCarData(car),
                signalData: {
                    items: convertAnalyticsProducts(carToAnalyticsProduct(car)),
                },
            });
        },*/
        simularCarsGoCar(car) {
            emit({
                event: 'go_car',
                category: 'simular_cars',
                label: car?.id,
                GTMData: createGTMCarData(car),
                signalData: {
                    items: convertAnalyticsProducts(carToAnalyticsProduct(car)),
                },
            });
        },
        newCarsGoCar(car) {
            emit({
                event: 'go_car',
                category: 'new_cars',
                label: car?.id,
                GTMData: createGTMCarData(car),
                signalData: {
                    items: convertAnalyticsProducts(carToAnalyticsProduct(car)),
                },
            });
        },
        popularCarsGoCar(car) {
            emit({
                event: 'go_car',
                category: 'popular_cars',
                label: car?.id,
                GTMData: createGTMCarData(car),
                signalData: {
                    items: convertAnalyticsProducts(carToAnalyticsProduct(car)),
                },
            });
        },
        externalMediaClickArticle(article) {
            emit({
                event: 'click_article',
                category: 'external_media',
                label: article,
                GTMData: { article },
                signalData: {
                    article,
                },
            });
        },
        blogClickArticle(article) {
            emit({
                event: 'click_article',
                category: 'blog',
                label: article,
                GTMData: { article },
                signalData: {
                    article,
                },
            });
        },
        travelThemeClickOpen(title) {
            emit({
                event: 'click_open',
                category: 'travel_theme',
                label: title,
                GTMData: { title },
                signalData: {
                    title,
                },
            });
        },
        //sdat_button
        /*ctaJoinUsClickBeRentier() {
            emit({
                event: 'click_be_rentier',
                category: 'cta_join_us',
            });
        },*/
        ctaJoinUsClickRentCar() {
            emit({
                event: 'click_rent_car',
                category: 'cta_join_us',
            });
        },
        headerClickLogo() {
            emit({
                event: 'click_logo',
                category: 'header',
            });
        },
        headerClickNavItem(link) {
            emit({
                event: 'click_nav_item',
                category: 'header',
                label: link,
                GTMData: { link },
                signalData: {
                    link,
                },
            });
        },
        headerClickPhone() {
            emit({
                event: 'click_phone',
                category: 'header',
            });
        },
        headerClickSignIn() {
            emit({
                event: 'click_sign_in',
                category: 'header',
            });
        },
        headerClickOpenMenu() {
            emit({
                event: 'click_open_menu',
                category: 'header',
            });
        },
        headerClickMenuItem(link) {
            emit({
                event: 'click_menu_item',
                category: 'header',
                label: link,
                GTMData: { link },
                signalData: {
                    link,
                },
            });
        },
        footerClickNavItem(link) {
            emit({
                event: 'click_nav_item',
                category: 'footer',
                label: link,
                GTMData: { link },
                signalData: {
                    link,
                },
            });
        },
        footerClickPhone() {
            emit({
                event: 'click_phone',
                category: 'footer',
            });
        },
        clickApp(platform) {
            emit({
                event: 'click_app',
                category: 'footer',
                label: platform,
                GTMData: { platform },
                signalData: {
                    platform,
                },
            });
        },
        footerClickZen() {
            emit({
                event: 'click_zen',
                category: 'footer',
            });
        },
        segmentsClickSegment(segment) {
            emit({
                event: 'click_segment',
                category: 'segments',
                label: segment,
                GTMData: { segment },
                signalData: {
                    segment,
                },
            });
        },
        segmentsSearchClickSegment(segment) {
            emit({
                event: 'click_segment',
                category: 'search',
                label: segment,
                GTMData: { segment },
                signalData: {
                    segment,
                },
            });
        },
        //booking3
        /*bookingModalClickSubmit(deal) {
            emit({
                event: 'click_submit',
                category: 'booking_modal',
                GTMData: createGTMDealData(deal),
                signalData: createDealSignalData(deal),
            });
        },*/
        bookingModalSetOption(option, val) {
            emit({
                event: 'set_option_' + option,
                category: 'booking_modal',
                label: val,
                GTMData: {
                    option,
                    val,
                },
                signalData: {
                    option,
                    val,
                },
            });
        },
        bookingModalClose() {
            emit({
                event: 'close',
                category: 'booking_modal',
            });
        },
        //purchase
        /*billingSuccess(deal) {
            emit({
                event: 'success',
                category: 'success',
                GTMData: createGTMDealData(deal),
                signalData: createDealSignalData(deal),
            });
        },*/
        authClickGetSmsCode() {
            emit({
                event: 'click_get_sms_code',
                category: 'auth',
            });
        },
        authClickGetEmailCode() {
            emit({
                event: 'click_get_email_code',
                category: 'auth',
            });
        },
        authClickSignInByEmail() {
            emit({
                event: 'click_sign_in_by_email',
                category: 'auth',
            });
        },
        authClickSignInBySms() {
            emit({
                event: 'click_sign_in_by_sms',
                category: 'auth',
            });
        },
        /*authLoggedIn(method) {
            emit({
                event: 'logged_in',
                category: 'auth',
                label: method,
            });
        },*/
        authLoggedOut() {
            emit({
                event: 'logged_out',
                category: 'auth',
            });
        },
        /*authRegistered(method) {
            emit({
                event: 'registered',
                category: 'auth',
                label: method,
            });
        },*/
        authCodeSuccess() {
            emit({
                event: 'code_success',
                category: 'auth',
            });
        },
        authCodeFailed() {
            emit({
                event: 'code_failed',
                category: 'auth',
            });
        },
        myProfileClickAddPhoto() {
            emit({
                event: 'click_add_photo',
                category: 'my_profile',
            });
        },
        myProfileClickEditProfile() {
            emit({
                event: 'click_edit_profile',
                category: 'my_profile',
            });
        },
        myProfileClickSaveDocuments() {
            emit({
                event: 'click_save_documents',
                category: 'my_profile',
            });
        },
        myProfileClickNavigation(section) {
            emit({
                event: 'click_navigation',
                category: 'my_profile',
                label: section,
            });
        },
        tabNavigationClick(label) {
            emit({
                event: 'click_tab',
                category: 'tab_navigation',
                label,
            });
        },
        profileNavigationClick(label) {
            emit({
                event: 'click_navigation',
                category: 'profile_navigation',
                label,
            });
        },
        myCarClickNextStep(step) {
            emit({
                event: 'click_next_step',
                category: 'my_car',
                label: step,
            });
        },
        myCarClickSendToModeration() {
            emit({
                event: 'click_send_to_moderation',
                category: 'my_car',
            });
        },
        myCarClickSetDiscounts() {
            emit({
                event: 'click_set_discounts',
                category: 'my_car',
            });
        },
        myCarClickSetAccessInterval() {
            emit({
                event: 'click_set_access_interval',
                category: 'my_car',
            });
        },
        myCarSubmitDiscountInterval(ok) {
            emit({
                event: 'submit_discount_interval',
                category: 'my_car',
                label: ok ? 'success' : 'fail',
            });
        },
        myCarSubmitOverpriceInterval(ok) {
            emit({
                event: 'submit_overprice_interval',
                category: 'my_car',
                label: ok ? 'success' : 'fail',
            });
        },
        myCarClick(section) {
            emit({
                event: `click_${section}`,
                category: 'my_car',
            });
        },
        myCarSetShowInCatalog(val) {
            emit({
                event: 'set_show_in_catalog',
                category: 'my_car',
                label: val,
            });
        },
        myCarClickGetDataBy(carId, type) {
            emit({
                event: `click_get_data_by_${type}`,
                category: 'my_car',
                label: carId,
            });
        },
        myCarClickSendRequest(carId) {
            emit({
                event: 'click_send_request',
                category: 'my_car',
                label: carId,
            });
        },
        myCarClickCarDataInput(carId, input) {
            emit({
                event: 'click_car_data_input',
                category: 'my_car',
                label: carId,
                signalData: {
                    input,
                },
            });
        },
        myCarScanCarQRFromDocs(carId) {
            emit({
                event: 'scan_car_QR_from_docs',
                category: 'my_car',
                label: carId,
            });
        },
        myCarUploadPhoto(carId, type) {
            emit({
                event: `upload_photo_${type}`,
                category: 'my_car',
                label: carId,
            });
        },
        myCarScanCarQRFromPhotos(carId) {
            emit({
                event: 'scan_car_QR_from_photos',
                category: 'my_car',
                label: carId,
            });
        },
        myCarClickGoMyCars(from) {
            emit({
                event: 'click_go_my_cars',
                category: 'my_car',
                label: from,
            });
        },
        myCarClickCarPreview(carId) {
            emit({
                event: 'click_car_preview',
                category: 'my_car',
                label: carId,
            });
        },
        myCarClickNavigation(type) {
            emit({
                event: 'click_navigation',
                category: 'my_car',
                label: type,
            });
        },
        myCarClickDiscountAdd(carId, data) {
            emit({
                event: 'click_discount_add',
                category: 'my_car',
                label: carId,
                signalData: getCalendarData(data),
            });
        },
        myCarClickDiscountEdit(carId, id) {
            emit({
                event: 'click_discount_edit',
                category: 'my_car',
                label: carId,
                signalData: {
                    id,
                },
            });
        },
        myCarClickDiscountEditSave(carId, data, id) {
            emit({
                event: 'click_discount_edit_save',
                category: 'my_car',
                label: carId,
                signalData: {
                    id,
                    ...getCalendarData(data),
                },
            });
        },
        myCarClickDiscountDelete(carId, id) {
            emit({
                event: 'click_discount_delete',
                category: 'my_car',
                label: carId,
                signalData: {
                    id,
                },
            });
        },
        myCarClickCalendarAdd(carId, data) {
            emit({
                event: 'click_calendar_add',
                category: 'my_car',
                label: carId,
                signalData: getCalendarData(data),
            });
        },
        myCarClickCalendarEdit(carId, id) {
            emit({
                event: 'click_calendar_edit',
                category: 'my_car',
                label: carId,
                signalData: {
                    id,
                },
            });
        },
        myCarClickCalendarEditSave(carId, data, id) {
            emit({
                event: 'click_calendar_edit_save',
                category: 'my_car',
                label: carId,
                signalData: {
                    id,
                    ...getCalendarData(data),
                },
            });
        },
        myCarClickCalendarDelete(carId, id) {
            emit({
                event: 'click_calendar_delete',
                category: 'my_car',
                label: carId,
                signalData: {
                    id,
                },
            });
        },
        myСarClickInfo() {
            emit({
                event: 'click_photo_examples',
                category: 'my_car',
            });
        },
        myСarClickSuperOwnerBanner() {
            emit({
                event: 'click_info',
                category: 'super_owner',
            });
        },
        ratingClickPhotoExamples() {
            emit({
                event: 'click_photo_examples',
                category: 'rating',
            });
        },
        ratingClickInfo() {
            emit({
                event: 'click_info',
                category: 'rating',
            });
        },
        myCarClickCtaAddCar() {
            emit({
                event: 'click_cta_add_car',
                category: 'my_car',
            });
        },
        myCarClickCtaMoreInfo() {
            emit({
                event: 'click_cta_more_info',
                category: 'my_car',
            });
        },
        myCarClickAddCar() {
            emit({
                event: 'click_add_car',
                category: 'my_car',
            });
        },
        myCarClickLinkCard() {
            emit({
                event: 'click_link_card',
                category: 'my_car',
            });
        },
        myRequestsSetFilterStatus(status) {
            emit({
                event: 'set_filter_status',
                category: 'my_requests',
                label: status,
            });
        },
        myRequestsSetFilterDate() {
            emit({
                event: 'set_filter_date',
                category: 'my_requests',
            });
        },
        myRequestsClickResetFilters() {
            emit({
                event: 'click_reset_filters',
                category: 'my_requests',
            });
        },
        myPaymentsClickLinkCard() {
            emit({
                event: 'click_link_card',
                category: 'my_payments',
            });
        },
        //order_confirm
        /*requestClickApprove(dealId) {
            emit({
                event: 'click_approve',
                category: 'request',
                label: dealId,
            });
        },*/
        //deal_cancel
        /*requestClickReject(dealId) {
            emit({
                event: 'click_reject',
                category: 'request',
                label: dealId,
            });
        },*/
        requestClickNavItem(section) {
            emit({
                event: 'click_navigation',
                category: 'request',
                label: section,
            });
        },
        requestUserInfoClickItem(userRole, itemName, dealId) {
            emit({
                event: `click_${userRole}_${itemName}`,
                category: 'request',
                label: dealId,
            });
        },
        requestClickPayDeposit(dealId) {
            emit({
                event: 'click_pay_deposit',
                category: 'request',
                label: dealId,
            });
        },
        requestClickProlongation(dealId) {
            emit({
                event: 'click_prolongation',
                category: 'request',
                label: dealId,
            });
        },
        requestClickCarInspection(dealId) {
            emit({
                event: 'click_car_inspection',
                category: 'request',
                label: dealId,
            });
        },
        requestSubmitCarInspection(dealId) {
            emit({
                event: 'submit_car_inspection',
                category: 'request',
                label: dealId,
            });
        },
        requestClickCarMakeReview(dealId) {
            emit({
                event: 'click_make_review',
                category: 'request',
                label: dealId,
            });
        },
        requestSubmitCarReview(dealId) {
            emit({
                event: 'submit_review',
                category: 'request',
                label: dealId,
            });
        },
        requestSubmitDealChanges(dealId) {
            emit({
                event: 'submit_deal_changes',
                category: 'request',
                label: dealId,
            });
        },
        //deal_cancel
        /*requestClickCancel(dealId) {
            emit({
                event: 'click_cancel',
                category: 'request',
                label: dealId,
            });
        },*/
        requestSendMessage(dealId) {
            emit({
                event: 'send_message',
                category: 'request',
                label: dealId,
            });
        },
        requestClickRequest(dealId) {
            emit({
                event: 'click_request',
                category: 'request',
                label: dealId,
            });
        },
        reviewSent(car) {
            emit({
                event: 'review_sent',
                category: 'review',
                label: car?.id,
                GTMData: car ? createGTMCarData(car) : undefined,
                signalData: {
                    items: car ? convertAnalyticsProducts(carToAnalyticsProduct(car)) : undefined,
                },
            });
        },
        setSorting(type) {
            emit({
                event: 'set_sorting',
                category: 'search',
                label: type ?? 'default',
            });
        },
        swipeCatalogCarSpider(car, slideIndex) {
            emit({
                event: 'swipe_photo',
                category: 'search',
                label: car?.id,
            });
        },
        clickStartEarningSdatLanding(type) {
            emit({
                event: 'click_start_earning',
                category: 'sdat_landing',
                label: type,
            });
        },
        clickCallBackSdatLanding() {
            emit({
                event: 'click_call_back',
                category: 'sdat_landing',
                label: 'from_first_screen',
            });
        },
        leadSdatLanding(type) {
            emit({
                event: 'lead',
                category: 'sdat_landing',
                label: type,
            });
        },
        dealClickFaqItem(isCar, type) {
            emit({
                event: 'click_faq_item',
                category: isCar ? 'car' : 'request',
                label: type,
            });
        },
        catalogAbSorting(action) {
            emit({
                event: action,
                category: 'ab_sorting',
            });
        },
        mapClickВot(carId) {
            emit({
                event: 'click_dot',
                category: 'map',
                label: carId,
            });
        },
        mapClickCloseCarCard(carId) {
            emit({
                event: 'click_close_car_card',
                category: 'map',
                label: carId,
            });
        },
        mapSwipeCloseCardCard(carId) {
            emit({
                event: 'swipe_close_card_card',
                category: 'map',
                label: carId,
            });
        },
        mapGoCar(car) {
            emit({
                event: 'go_car',
                category: 'map',
                label: car?.id,
            });
        },
        appCtaOpen(label) {
            emit({
                event: 'click_open',
                category: 'app_cta',
                label,
            });
        },
        appCtaClose(label) {
            emit({
                event: 'click_close',
                category: 'app_cta',
                label,
            });
        },
        mapClickZoom(type) {
            emit({
                event: `click_zoom_${type}`,
                category: 'map',
            });
        },
        carDeliveryInputClick(carId) {
            emit({
                event: `click_delivery`,
                category: 'car',
                label: carId,
            });
        },
        carDeliveryInputChange(deliveryType) {
            emit({
                event: `set_delivery`,
                category: 'car',
                label: deliveryType,
            });
        },
        dealDeliveryRemove(deliveryType) {
            emit({
                event: `remove_delivery`,
                category: 'request',
                label: deliveryType,
            });
        },
        dealDeliveryAdd(deliveryType) {
            emit({
                event: `add_delivery`,
                category: 'request',
                label: deliveryType,
            });
        },
        dealDeliverEdit() {
            emit({
                event: `edit_delivery`,
                category: 'request',
            });
        },
        bonusPointsPromotionActivateInModal(id) {
            emit({
                event: 'click_activate',
                category: 'promo',
                label: id,
            });
        },
        bonusPointsPromotionCloseModal(id) {
            emit({
                event: 'click_close_modal',
                category: 'promo',
                label: id,
            });
        },
        newbiesBonusPromotionClickOpen() {
            emit({
                event: 'click_open',
                category: 'newbies_bonus',
            });
        },
        newbiesBonusPromotionClickClose() {
            emit({
                event: 'click_close',
                category: 'newbies_bonus',
            });
        },
        newbiesBonusPromotionClickRegistration() {
            emit({
                event: 'click_registration',
                category: 'newbies_bonus',
            });
        },
        newbiesBonusPromotionClickCloseModal() {
            emit({
                event: 'click_close_modal',
                category: 'newbies_bonus',
            });
        },
        autoAgreedClickSettings() {
            emit({
                event: 'click_settings',
                category: 'autoconfirmation',
            });
        },
        autoAgreedClickTurnOn() {
            emit({
                event: 'click_turn_on',
                category: 'autoconfirmation',
            });
        },
        autoAgreedClickTurnOff() {
            emit({
                event: 'click_turn_off',
                category: 'autoconfirmation',
            });
        },
        blogWidgetSeenWidget(signalData) {
            if (signalData) {
                emit({
                    event: 'seen_widget',
                    category: 'blog_widget',
                    signalData,
                    GTMIgnore: true,
                });
            }
        },
        blogWidgetClickSearch(signalData) {
            if (signalData) {
                emit({
                    event: 'click_search',
                    category: 'blog_widget',
                    signalData,
                });
            }
        },
        blogWidgetSetFilter(signalData, name, value) {
            if (signalData) {
                let val = value;
                if (Array.isArray(val)) {
                    val = val.join('; ');
                } else if (typeof val === 'boolean') {
                    val = val ? 'true' : 'false';
                } else if (val) {
                    val = val.toString()
                }

                emit({
                    event: 'set_filter_' + name,
                    category: 'blog_widget',
                    label: val,
                    signalData,
                });
            }
        },
        blogWidgetGoCar(signalData, idCar) {
            if (signalData) {
                emit({
                    event: 'go_car',
                    category: 'blog_widget',
                    label: idCar,
                    signalData,
                });
            }
        },
    }
}


function getCalendarData({period_start, period_end, percent}) {
    return {
        from: period_start,
        to: period_end,
        discount: percent,
    }
}
