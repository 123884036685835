import _mapValues from 'lodash/mapValues';
import _isString from 'lodash/isString';

export default function objectDeepLower(obj) {
    return _mapValues(obj, (val) => {
        if (Array.isArray(val)) {
            return val.map((v) => v?.toLowerCase());
        } else {
            return _isString(val) ? val.toLowerCase() : val;
        }
    })
}
