import { formatPrice } from "@/utils/format/formatPrice";
import { formatUserFullName } from "@/utils/format/formatUserFullName";
import {formatUserName} from "~/utils/format/formatUserName";
import { formatNumber } from "@/utils/format/formatNumber";
import { formatDate, formatDuration, formatFullDate, shortDate } from "@/utils/format/formatDate"
import { roundNumber } from "@/utils/number/roundNumber";
import { VueMaskFilter } from 'v-mask'

const filters = {
    price: formatPrice,
    fullName: formatUserFullName,
    userName: formatUserName,
    date: formatDate,
    fullDate: formatFullDate,
    shortDate,
    duration: formatDuration,
    round: roundNumber,
    number: formatNumber,
    VMask: VueMaskFilter,
}

export default (context, inject) => {
    inject('filters', filters);
}
