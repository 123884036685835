import {StoreApi} from "~/lib/storeRestApi";
import {getStore} from "@/utils/store";

const store = new StoreApi({
    state: {
        selections: {
            list: [],
            total: 0,
            pages: 0,
            currentPage: 0
        },
        selection: {}
    }
}).get({
    action: "getSelections",
    path: '/selections',
    onSuccess(s, {data}, axios, { params, infinite }){
        let selections = s.selections;

        if(infinite) selections.list = selections.list.concat(data.data);
        else selections.list = data.data;

        selections.total = data.total;
        selections.pages = data.last_page;
        selections.currentPage = data.current_page;
    }
}).get({
    action: "getSelection",
    property: "selection",
    path: ({alias}) => `/selections/${alias}`,
});

export default getStore(store, {
    mutations: {
        resetSelection(s) {
            s.selection = {};
        }
    }
});
