import { isBot } from "../utils/router";
import packageJson from "../package.json";
import { metricaClientIds } from '../utils/meta';
import md5 from 'md5';
import _defaults from "lodash/defaults"

export class ServerError extends Error {
    constructor(message, data, statusCode, cfg = {}, other = {}) {
        super(message);
        Object.assign(this, other, other?.__proto__);
        if (data) {
            try {
                Object.keys(data).forEach(key => {
                    this[key] = data[key];
                });
            } catch (e) {
            }
        }

        this.cfg = {};
        if (cfg) {
            try {
                let {url, data, method} = cfg;
                if(data instanceof FormData) {
                    data = [...data.keys()].reduce((tmp, key)=>{
                        tmp[key] = data.get(key);
                        return tmp;
                    }, {});
                }
                this.cfg = {url, method, data: JSON.stringify(data)};
            } catch (e) {
            }
        }
        this.statusCode = statusCode;
    }

    getName(){
        return `${this.cfg?.method} ${this.cfg?.url} (${this.statusCode})`
    }

    toJSON() {
        return {
            message: this.message,
            ...this
        };
    }
}

const times = {};

function debugName(cfg) {
    return cfg?.url + JSON.stringify(cfg?.params);
}

function debugStart(cfg) {
    times[debugName(cfg)] = Date.now();
}

function debug($moment, isDev, cfg, status) {
    if (process.server) {
        const time = Date.now() - times[debugName(cfg)];
        console.log(`[${$moment().format('HH:mm:ss')}]`, `${time.toLocaleString()}ms`, cfg?.method?.toUpperCase?.(), cfg?.url, status, JSON.stringify(cfg?.params), JSON.stringify(cfg?.data));
    }
}

export default (context, inject) => {
    const { $sentry, $moment, $axios, isDev, error } = context;
    $axios.onResponse((res) => {
        debug($moment, isDev, res.config, res.status);
        if (!res.data && res.data !== 0) return Promise.reject('Ошибка сервера!');
        return res;
    });
    $axios.onResponseError((e) => {
        debug($moment, isDev, e.config, e?.response?.status);

        const ErrorSrv = (message) => {
            const srvE = new ServerError(message, e?.response?.data, e?.response?.status, e?.config, e);
            $sentry.configureScope(scope => scope.setTransactionName(`API ${srvE.getName()}`));
            $sentry.setContext("server_error", srvE.toJSON());
            $sentry.setTag("server_error_code", srvE.statusCode);
            if(isError(srvE)) {
                setTimeout(()=>{
                    $sentry.captureException(srvE ?? new Error(message));
                    console.warn(srvE);
                }, 100);
            }
            return Promise.reject(srvE);
        };
        if (e.response?.data || e.response?.data === 0) {
            if (e.response?.data?.errors) return ErrorSrv(e.response.data.message);
            else if (e.response?.status !== 200) return ErrorSrv(`HTTP API error #${e.response.status}`);
            else return Promise.reject(e.response?.data);
        } else return ErrorSrv(e.toString());
    });

    $axios.onRequest(config => {
        debugStart(config);
        setHeaders(context, config.headers);

        return config;
    });

    inject('axiosError', (msgData) => {
        if (!msgData?.isCanceled) {
            return error(msgData);
        }
    })
}

export function setHeaders({ $cookies, app, req, route, store, $device }, initHeaders) {
    const metrica = metricaClientIds($cookies);
    const timestamp = Date.now();

    const headers = {};
    headers['X-RR-WEBP'] = store.state.supportWebp ? 1 : 0;
    headers['X-Device-Id'] = rr_store_id(app) ?? '';
    headers['X-RR-Url'] = process.client ? location.pathname : req.url;
    headers['X-RR-Bot'] = isBot(req);
    headers['X-RR-Is-Srv'] = process.server ? 1 : 0;
    headers['X-RR-OS'] = getOS($device);
    headers['X-RR-CHANNEL'] = 'web';
    headers['X-RR-PROJECT'] = 'rentride';
    headers['X-RR-VERSION'] = packageJson.version;
    headers['X-RR-TIMESTAMP'] = timestamp;
    headers['X-RR-TOKEN'] = get_token_rr(timestamp, headers['X-Device-Id']);
    if (metrica.yandex_client_id) headers['X-RR-YM-UID'] = metrica.yandex_client_id;
    if (metrica.google_client_id) headers['X-RR-GA'] = metrica.google_client_id;

    return _defaults(initHeaders, headers);
}

export function get_token_rr(timestamp, deviceId) {
    const time = timestamp.toString().slice(-5)
    const device = deviceId.split('-').slice(-1)[0];
    return btoaFun(md5(`web_${time}_${device}`));
}

function btoaFun(str) {
    if (process.server && !globalThis.btoa) {
        return require('btoa')(str);
    } else {
        return btoa(str);
    }
}

export function rr_store_id(app) {
    let uuid = app.$cookies.get('rr_store_id');
    return uuid || app.rr_store_id;
}

export function rr_session_id(app) {
    let uuid = app.$cookies.get('rr_session_id');
    return uuid || app.rr_store_id;
}

export function getOS({ isDesktopOrTablet, isAndroid, isIos }) {
    if (isAndroid) return 'android';
    else if (isIos) return 'ios';
    else if (isDesktopOrTablet) return 'pc';
    else return 'unknown';
}

function isError(err) {
    const ignoreStatus = {
        srv: [401, 404],
        client: [401, 422, 404],
    };

    const code = +err?.statusCode;
    if(code === 422 && err?.cfg?.url === '/token') return false; //Auth
    if(process.server) {
        return !code || !ignoreStatus.srv.includes(code);
    }
    else {
        return code && !ignoreStatus.client.includes(code);
    }
}
