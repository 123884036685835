export default function ({ $echo, route, $auth, $axios, store: { commit, dispatch, state } }) {
    let userId = $auth.user?.id;

    if ($auth.loggedIn) {
        connect();
    }
    $auth.$storage.watchState('loggedIn', (loggedIn) => {
        disconnect();
        if (loggedIn) {
            userId = $auth.user?.id;
            connect();
        }
    })

    function connect() {
        $axios.get('/profile/messages/count').then(({data: profileUnreadMessagesCount}) => {
            commit('profile/setProfileUnreadMessagesCount', profileUnreadMessagesCount);
        });
        $echo.join(`user.${userId}`).listen('.user.messages.all', ({ count: profileUnreadMessagesCount, deal }) => {
            commit('profile/setProfileUnreadMessagesCount', profileUnreadMessagesCount);
            dispatch('profile/setMessageUnread', { deal, isChat: route?.name?.includes('profile-chat') });
        }).error((e) => console.warn(e));
    }

    function disconnect() {
        $echo.leave(`user.${userId}`);
    }
}
