export const splitTestNames = {
    newbiesDiscount: 'ab_newbies_discount',
    documents: 'ab_documents',
}

export const splitTestGroups = {
    [splitTestNames.newbiesDiscount]: {
        default: 'default_version_showed',
        bonus: '500_version_showed',
    },
}

export const profilePersistSplitTestGroups = {
    [splitTestNames.documents]: {
        default: 'default',
        manual: 'manual_documents',
    },
}
