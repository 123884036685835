import { capitalizeString } from "@/utils/string/capitalizeString";

const HTTPMethod = ["get", "delete", "head", "options", "post", "put", "patch"];
const successSuffix = "Succeeded";
const errorSuffix = "Failed";
const cancelSuffix = "Cancel";

export class StoreApi {
    actionTemplates = {};
    defaultState;

    constructor({state = {}} = {}) {
        this.defaultState = state;
    }

    getStore({namespaced = false, createStateFn}) {
        return this._createStore(namespaced, createStateFn);
    }

    _createStore(namespaced, createStateFn) {
        return {
            namespaced,
            state: this._createState(createStateFn),
            mutations: this._createMutations(),
            actions: this._createActions(),
        }
    }

    add(options = {}) {
        this._addActionTemplate(options);
        return this;
    }

    _createState(createStateFn) {
        const state = {
            pending: {},
            error: {},
            cancelSources: {},
        }
        const initState = createStateFn ? createStateFn() : this.defaultState;

        Object.assign(state, initState);
        Object.keys(this.actionTemplates).forEach((property) => {
            state[property] = state[property] ?? null;
            state.pending[property] = false;
            state.error[property] = null;
            state.cancelSources[property] = null;
        })
        return state;
    }

    _addActionTemplate({
                           method,
                           action,
                           property,
                           path,
                           headers,
                           queryParams,
                           beforeRequest,
                           onSuccess,
                           onError,
                           allowDuplicate,
                           requestConfig = {},
                       }) {
        const isGet = method.toUpperCase() === 'GET';
        this.actionTemplates[property] = {
            actionName: action,
            beforeRequest,
            onSuccess,
            onError,
            isGet,
            commitsStrings: this._createCommitsStrings(action),
            allowDuplicate: allowDuplicate ?? !isGet,
            request(actionParams, cancelToken, axios) {
                const {params, data} = actionParams;
                const hasParamsSerializer = (requestConfig?.paramsSerializer) || axios.defaults?.paramsSerializer;
                if (queryParams || hasParamsSerializer) {
                    requestConfig.params = params;
                }
                return axios({
                    url: getPath(path, params),
                    headers: getHeaders(headers, actionParams, axios),
                    method,
                    cancelToken,
                    data,
                    ...requestConfig,
                });
            },
        }
    }

    _createMutations() {
        return Object.entries(this.actionTemplates).reduce((mutations, [property, actionTmp]) => {
            Object.assign(mutations, this._createMutationsByActionTmp(property, actionTmp));
            return mutations;
        }, {});
    }

    _createMutationsByActionTmp(property, actionTmp) {
        const commits = actionTmp.commitsStrings;
        const storeApi = this;
        return {
            [commits.fetch]: function (state, {payload: cancelSource, actionParams}) {
                if (property !== null) {
                    state.pending[property] = true;
                    state.error[property] = null;
                }
                if (process.client) {
                    state.cancelSources[property] = Object.freeze(cancelSource);
                }
                if (actionTmp.beforeRequest) {
                    actionTmp.beforeRequest(state, actionParams);
                }
            },
            [commits.success]: function (state, {payload, actionsParams}) {
                if (property !== null) {
                    state.pending[property] = false;
                    state.error[property] = null;
                }
                state.cancelSources[property] = null;
                if (actionTmp.onSuccess) {
                    actionTmp.onSuccess(state, payload, this.$axios, actionsParams);
                } else {
                    state[property] = payload.data;
                }
            },
            [commits.failed]: function (state, {payload: error, actionParams}) {
                if (property !== null) {
                    state.pending[property] = false;
                    state.error[property] = error;
                }
                state.cancelSources[property] = null;
                if (actionTmp.onError) {
                    actionTmp.onError(state, error, this.$axios, actionParams);
                } else if (property !== null) {
                    state[property] = storeApi.defaultState[property];
                }
            },
            [commits.cancel]: function (state) {
                const cancelSource = state.cancelSources[property];
                if (cancelSource) {
                    cancelSource.cancel();
                }
                state.cancelSources[property] = null;
                state.pending[property] = false;
            },
        }
    }

    _createActions() {
        return Object.fromEntries(Object.entries(this.actionTemplates).map(([property, actionTmp]) => {
            function actionFn({commit, state}, actionsParams = {}) {
                const allowDuplicate = actionsParams?.allowDuplicate || actionTmp.allowDuplicate;
                const cancelSource = !allowDuplicate ? this.$axios.CancelToken.source() : null;
                if (!actionTmp.allowDuplicate && state.pending[property]) {
                    commit(actionTmp.commitsStrings.cancel)
                }
                commit(
                    actionTmp.commitsStrings.fetch,
                    { payload: cancelSource, actionsParams },
                );
                return actionTmp.request(actionsParams, cancelSource?.token, this.$axios)
                    .then((response) => {
                        commit(
                            actionTmp.commitsStrings.success,
                            {payload: response, actionsParams},
                        );
                        return Promise.resolve(response);
                    })
                    .catch((error) => {
                        if (!this.$axios.isCancel(error)) {
                            commit(
                                actionTmp.commitsStrings.failed,
                                { payload: error, actionsParams },
                            );
                        }
                        return Promise.reject(error);
                    });
            }

            return [actionTmp.actionName, actionFn];
        }));
    }

    _createCommitsStrings(actionName) {
        const c = capitalizeString;
        return {
            fetch: c(actionName),
            success: c(actionName + successSuffix),
            failed: c(actionName + errorSuffix),
            cancel: c(actionName + cancelSuffix),
        };
    }
}


HTTPMethod.forEach((method) => {
    StoreApi.prototype[method] = function (options) {
        return StoreApi.prototype.add.call(this, {...options, method});
    }
})

/* Utils */
function getPath(path, params) {
    if (path instanceof Function) {
        return path(params);
    }
    return path;
}

function getHeaders(headers, ...args) {
    if (typeof headers === 'function') {
        return headers(...args);
    }
    return headers;
}
