const middleware = {}

middleware['cleanErrors'] = require('../../middleware/cleanErrors.js')
middleware['cleanErrors'] = middleware['cleanErrors'].default || middleware['cleanErrors']

middleware['leavePageCancelRequest'] = require('../../middleware/leavePageCancelRequest.js')
middleware['leavePageCancelRequest'] = middleware['leavePageCancelRequest'].default || middleware['leavePageCancelRequest']

middleware['modal'] = require('../../middleware/modal.js')
middleware['modal'] = middleware['modal'].default || middleware['modal']

middleware['notificationKuid'] = require('../../middleware/notificationKuid.js')
middleware['notificationKuid'] = middleware['notificationKuid'].default || middleware['notificationKuid']

middleware['redirects'] = require('../../middleware/redirects.js')
middleware['redirects'] = middleware['redirects'].default || middleware['redirects']

middleware['seo'] = require('../../middleware/seo.js')
middleware['seo'] = middleware['seo'].default || middleware['seo']

middleware['trailingSlash'] = require('../../middleware/trailingSlash.js')
middleware['trailingSlash'] = middleware['trailingSlash'].default || middleware['trailingSlash']

export default middleware
