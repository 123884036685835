import compact from "lodash/compact";
import {carToAnalyticsProduct} from "@/utils/analytics/products/carToAnalyticsProduct";
import {createAnalyticsProduct} from "@/utils/analytics/products/createAnalyticsProduct";
import {roundDaysDiff} from "~/utils/date";

export function dealToAnalyticsProducts(deal) {
    const dealRentDuration = roundDaysDiff(deal.period_start, deal.period_end);

    const carAnalyticsProduct = carToAnalyticsProduct(deal.car, {
        id: deal.id,
        periodStart: deal.period_start,
        periodEnd: deal.period_end,
        quantity: dealRentDuration,
        price: deal.rental_price,
    });
    const dealPriceOptionsAnalyticProducts = dealPricesOptionsToAnalyticsProducts(deal.prices?.options,
        {duration: dealRentDuration},
    );
    const dealDeliveryFromAnalyticProduct = dealDeliveryToAnalyticsProduct(deal.delivery_from, 'deliveryFrom');
    const dealDeliveryToAnalyticProduct = dealDeliveryToAnalyticsProduct(deal.delivery_to, 'deliveryTo');

    return compact([
        carAnalyticsProduct,
        dealDeliveryFromAnalyticProduct,
        dealDeliveryToAnalyticProduct,
        ...dealPriceOptionsAnalyticProducts,
    ]);
}

export function dealPricesOptionsToAnalyticsProducts(dealPricesOptions, analyticsObject = {}) {
    return Object.entries(dealPricesOptions || {}).map((key, priceOption) => {
        return createAnalyticsProduct({
            id: key,
            name: priceOption.title ?? key,
            price: priceOption.price ?? 0,
            ...analyticsObject,
        });
    })
}

export function dealDeliveryToAnalyticsProduct(delivery, id) {
    if (delivery != null) {
        return createAnalyticsProduct({
            id,
            name: delivery.delivery_tariff?.title,
            price: delivery.price,
        });
    }
}
