import { cookieNames } from '@/utils/_consts';
import { ACTIONS } from '@/mixins/booking/getUrlPay';

export default ({ app, $sentry, route }) => {
    const routePay = route.query?.action === ACTIONS.SUCCESS;

    function send() {
        app.$cookies.remove(cookieNames.payRedirect);
        $sentry.captureMessage(`payInfo ${routePay ? '(routePay)' : ''}: ${route.fullPath}`);
    }

    if (app.$cookies.get(cookieNames.payRedirect) || routePay) {
        if (process.client) {
            setTimeout(() => send(), 2000);
        } else {
            send();
        }
    }
}
