export function sortByDistanceCities(cities, cords) {
    return cities.map(city => {
        return {
            ...city,
            distance: getDistance(city, cords)
        }
    }).sort((a,b)=>a.distance-b.distance);
}

export function nearestCity(cities, cords){//Находит ближайший город
    return cities.reduce((nearest, city)=>{
        const distance = getDistance(city, cords);
        if(!nearest.distance || distance < nearest.distance) {
            nearest.distance = distance;
            nearest.alias = city.alias;
        }
        return nearest;
    }, {distance: null, alias: null});
}

export function getDistance(cords1, cords2){//Вычесляет растояние между кординатами
    const latitude =  Math.pow(cords1.latitude - cords2.latitude, 2);
    const longitude =  Math.pow(cords1.longitude - cords2.longitude, 2);
    return Math.sqrt(latitude + longitude);
}

export function getCities(store){//Получает список городов
    return new Promise((resolve, reject) => {
        if(store.state.glossary._readyGlossaries) {
            return resolve(store.state.glossary.glossariesAddress.city);
        }
        store.dispatch('glossary/getGlossaries').then(({data})=>resolve(data.address.city), reject);
    });
}

export function getCords(){//Получает координаты устройства
    if(!process.client) return Promise.reject();
    return new Promise((resolve, reject) =>{
        if(!process.client) reject('Client only');
        navigator.geolocation.getCurrentPosition(({coords})=>resolve(coords), reject, {enableHighAccuracy: false});
    });
}
