import {StoreApi} from "~/lib/storeRestApi";
import {getStore} from "@/utils/store";

const store = new StoreApi({
    state: {

    }
})
    /* Calendar */
.post({
    action: "addCalendar",
    property: "addCalendarResult",
    path: ({carId}) => `/cars/${carId}/calendar`,
}).put({
    action: "editCalendar",
    property: "editCalendarResult",
    path: ({id}) => `/cars/calendar/${id}`,
}).delete({
    action: "removeCalendar",
    property: "removeCalendarResult",
    path: ({id}) => `/cars/calendar/${id}`,
}).put({
    action: "cleanSystemBlockedCalendar",
    property: "cleanSystemBlockedCalendarResult",
    path: ({id}) => `/cars/calendar/${id}/clear-interval`,
})
    /* Discount */
.post({
    action: "addDiscount",
    property: "addDiscountResult",
    path: ({carId}) => `/cars/${carId}/price/discount`,
}).put({
    action: "editDiscount",
    property: "editDiscountResult",
    path: ({carId, id}) => `/cars/${carId}/price/discount/${id}`,
}).delete({
    action: "removeDiscount",
    property: "removeDiscountResult",
    path: ({carId, id}) => `/cars/${carId}/price/discount/${id}`,
});

export default getStore(store);
