import {objectClean} from "@/utils/data";

export function createAnalyticsProduct(analyticsProductObject = {}) {
    return {
        ...analyticsProductObject,
        toJSON() {
            const {
                ownerId,
                carClass,
                carBody,
                brand,
                model,
                equipment,
                id,
                name,
                price,
                city,
                quantity,
                periodStart,
                periodEnd,
                toJSON,
                ...other
            } = this;
            return objectClean({
                'item_brand': ownerId,
                'item_category': carClass,
                'item_category2': carBody,
                'item_category3': brand,
                'item_category4': model,
                'item_category5': equipment,
                'item_id': id,
                'item_name': name,
                'price': price,
                'location_id': city,
                'quantity': quantity,
                'period_start': periodStart?.getTime?.(),
                'period_end': periodEnd?.getTime?.(),
                ...other,
            });
        },
    };
}
