import Vue from "vue";
import { StoreApi } from "~/lib/storeRestApi";
import { getStore, onSuccessData, querySerializer } from "@/utils/store";
import { getArrayStep, getPercent, getPriceCarH, minMax } from "@/utils/data";
import { allowedActions, allowedEdit } from "@/utils/deal";
import _cloneDeep from "lodash/cloneDeep";
import moment from "moment";
import { getDiscount, processDiscount } from "@/utils/cars";
import { PROLONGATION_STATUS } from "@/utils/prolongation";
import { DELIVERY_TYPES } from "@/utils/_consts";

function createDealDeliveryOwnerSuccessHandler(property) {
    return (s, {data: {data: delivery}}) => {
        s[property] = delivery;
        if (s.deal) {
            if (delivery.type === DELIVERY_TYPES.delivery) {
                s.deal.delivery_to = delivery;
            }
            else if (delivery.type === DELIVERY_TYPES.return) {
                s.deal.delivery_from = delivery;
            }
        }
    }
}

const formDefault = {
    id: null,
    first_name: null,
    last_name: null,
    email: null,
    phone: null,

    period_start: null,
    period_end: null,
    regions: [],
    comment: null,
    driving_experience_from: null,

    template: null,

    //Владелец
    price_per_day: null,
    deposit: null,
    deal_options: []
};


const store = new StoreApi({
    state: {
        form: _cloneDeep(formDefault),
        chat: [],
        calculatedProlongation: null,
    }
}).post({
    action: "addRent",
    property: "addRentResult",
    path: '/deals'
}).get({
    action: "getDeal",
    property: "deal",
    path: ({id}) => `/deals/${id}`
}).get({
    action: "getShortDeal",
    property: "shortDeal",
    path: ({id}) => `/short-deal/${id}`,
}).post({
    action: "dealPayment",
    property: "dealPaymentResult",
    path: ({id}) => `/deals/${id}/payment`
}).put({
    action: "updateDeal",
    property: "updateDeal",
    path: ({id}) => `/deals/${id}`,
    onSuccess: onSuccessData('deal')
}).post({
    action: "updateDealOwnerDelivery",
    property: "dealOwnerDeliveryResult",
    path: ({id}) => `/deals/${id}/delivery-owner`,
    onSuccess: createDealDeliveryOwnerSuccessHandler('dealOwnerDeliveryResult'),
}).post({
    action: "updateDealOwnerDeliveryPrice",
    property: "updateDealOwnerDeliveryPriceResult",
    path: ({id}) => `/deals/delivery-owner/${id}/set-price`,
    onSuccess: createDealDeliveryOwnerSuccessHandler('updateDealOwnerDeliveryPriceResult'),
}).post({
    action: "addDealCarOwnerReview",
    property: "resultAddDealCarOwnerReview",
    path: ({id}) => `/deals/${id}/review/user`,
}).post({
    action: "prolongationDeal",
    property: "prolongationDeal",
    path: ({id}) => `/deals/${id}/prolongation`,
    onSuccess(s, {data}) {
        s.prolongationDeal = data;
        if(data?.id && s.deal?.prolongations) {
            if(s.deal.prolongations.every(({id}) => id !== data.id)) {
                s.deal.prolongations.push(data);
            }
        }
    }
}).put({
    action: "prolongationDealUpdate",
    property: "prolongationDealUpdate",
    path: ({dealId, id}) => `/deals/${dealId}/prolongation/${id}`,
    onSuccess(s, {data}) {
        s.prolongationDealUpdate = data;
        if(data?.id && s.deal?.prolongations?.length) {
            const index = s.deal.prolongations.findIndex(({id}) => id === data.id);
            if(~index) {
                Vue.set(s.deal.prolongations, index, data);
            }
        }
    }
}).post({
    action: "prolongationDealCalculate",
    property: "calculatedProlongation",
    path: ({dealId}) => `/deals/${dealId}/prolongation/calculator/calculate`,
    allowDuplicate: false,
}).post({
    action: "prolongationDealPayment",
    property: "prolongationDealPayment",
    path: ({id}) => `/deals/prolongation/${id}/payment`
}).get({
    action: "getDocDeal",
    property: "docDeal",
    path: ({id, type}) => `/deals/${id}/${type}`,
}).post({
    action: "getCalculator",
    property: "calculateDeal",
    path: 'deals/calculator/calculate',
    requestConfig: querySerializer,
})
    /*CHAT*/
.get({
    action: "getDealChat",
    property: "chat",
    path: ({ id, page }) => `/deals/${id}/messages?page=${page}`,
    onSuccess(s, { data: { data } }) {
        s.chat = [...data, ...s.chat];
    },
}).post({
        action: "addMessageDealChat",
        property: "addMessageChatResult",
        path: ({ dealId }) => `/deals/${dealId}/messages`,
    }).post({
        action: "readMessageDeal",
        property: "readMessageDealResult",
        path: ({ dealId }) => `/deals/${dealId}/messages/read_all`,
    }).post({
        action: "notificationsRentalRequest",
        property: "notificationsRentalRequestResult",
        path: '/notifications/rental-request',
    });

export default getStore(store, {
    mutations: {
        formFill(s, data){
            if(data) {
                for (const key in s.form) {
                    if (data.hasOwnProperty(key) && data[key]) {
                        let val = data[key];
                        if (['period_start', 'period_end'].includes(key)) {
                            val = parseInt(val);
                        }
                        if (key === 'regions' && !Array.isArray(val)) {
                            val = [val];
                        }
                        s.form[key] = val;
                    }
                }
            }
        },
        setRentTemplate(s, brand) {
            s.form.template = brand;
        },
        setForm(s, {name, val}) {
            s.form[name] = val;
        },
        toggleFormOptions(s, option) {
            if (s.form.deal_options.includes(option)) {
                s.form.deal_options.splice(s.form.deal_options.indexOf(option), 1);
                return false;
            } else {
                s.form.deal_options.push(option);
                return true;
            }
        },
        resetForm(s) {
            s.form = _cloneDeep(formDefault);
        },
        cleanChat(s){
            s.chat = [];
        },
        cleanDeal(s){
            s.deal = null;
        },
        setDeal(s, deal){
            s.deal = deal;
        },
        cleanErrors(s) {
            Object.keys(s.error).forEach(key => {
                s.error[key] = null;
            })
        },
        addMessage(s, message){
            const i = s.chat.findIndex(({id})=>id===message.id);
            if(~i) Vue.set(s.chat, i, message);
            else s.chat.push(message);
        },
        updateMessage(s, {message, messageId}){
            const i = s.chat.findIndex(({id})=>id===messageId);
            if(~i) Vue.set(s.chat, i, message);
        },
        removeMessage(s, idMessage){
            const i = s.chat.findIndex(({id})=>id===idMessage);
            if(~i) s.chat.splice(i);
        },
    },
    getters: {
        getPeriodDays: () => ({period_start, period_end}) => {
            return (period_end - period_start)/1000/60/60/24;
        },
        periodDays(s, g){
            return g.getPeriodDays(s.form);
        },
        getTermsCar(s, g, rootS)  {
            const {prices} = rootS.cars.car;
            let price;
            if(g.periodDays > 0) {
                price = g.getPrice;
            }
            if(!price) {
                price = g.getMinPrice([...(prices || [])]);
            }
            return price;
        },
        getMinPrice: () => (prices) => {
            if(prices?.length === 1) return prices[0];
            return Array.from(prices)
                .sort((a, b) => getPriceCarH(a) - getPriceCarH(b))[0];//Сортировка по цене
        },
        typePriceToDays: (s, g) => (val, type) => {
            /* const CAR_PRICE_PER_HOUR = 1;
               const CAR_PRICE_PER_DAY = 2; */
            if(type === 1) return Math.ceil(val/24);
            else {
                if(type !== 2) console.warn('Неизвестный период аренды:', type);
                return val;
            }
        },
        withoutDeposit(s){
            return s.form.deal_options.includes('without-deposit');
        },
        priceWithoutDeposit: (s, g, rootS) => (total) => {
            const percents = [100, 100, 90, 75, 55, 30];
            const percentsDeal = {
                10: [null, 10000],
                8: [10000, 20000],
                7: [20000, 30000],
                6: [30000, null]
            };
            const deals = rootS.auth?.user?.deals?.success || 0;
            const percentUser = deals >= 6 ? 0 : percents[deals];
            const percentDeal = +Object.keys(percentsDeal).find(p => minMax(total, ...percentsDeal[p]));

            return Math.floor(getPercent(getPercent(total, percentDeal), percentUser) || 0);
        },
        getDiscountRent: (s, g) => ({period_start, period_end}, days, discountsRaw) => {
            const discounts = processDiscount(discountsRaw);
            const start = moment(period_start);
            const fullDays = Math.floor(days);
            return getArrayStep(0, fullDays).reduce((tmp, el, i)=>{
                const date = +start.clone().add(i, 'day');
                return getDiscount(date, discounts) + tmp;
            }, 0) / fullDays;
        },
        getCalcDaysRent: (s, g) => (periodDays) => {
            let days = Math.floor(periodDays);
            const remainsHours = Math.floor((periodDays % (days || 1))*24);

            /*  +1 час бесплатно
                от 2 до 9 часов = 60% суточного тарифа
                от 10 часов = 100% сут тарифа */
            if(remainsHours > 1) {
                if(remainsHours < 10) {
                    days += 0.6;
                }
                else {
                    days += 1;
                }
            }
           return days;
        },
        getPriceRent: (s, g) => (price, days) => {
            const pricePerDay = g.typePriceToDays(price.price, price.type);
            return Math.floor(days * pricePerDay);
        },
        getPrice(s, g){
            return g.getPricePeriod(s.form)
        },
        getPricePeriod: (s, g, rootS) => (period, calckExtend = false, car = false) => {
            const periodDays = g.getPeriodDays(period);
            const {prices, price_day, discounts} = car || rootS.cars.car;
            if(!prices) return price_day;
            let priceList = prices.filter(({type, min_time}) => periodDays >= g.typePriceToDays(min_time, type));//Проверка что достигнут минимальный период
            let price = g.getMinPrice(priceList);
            if(!priceList.length && calckExtend) {
                price = [...prices].sort((a,b)=>a.min_time-b.min_time)[0];
            }
            if(price) {
                let days = g.getCalcDaysRent(periodDays);
                const discount = g.getDiscountRent(period, days, discounts);
                let rent = g.getPriceRent(price, days) * discount;
                let total = rent;
                let optionsPrice = 0;
                if(g.withoutDeposit) {
                    optionsPrice = g.priceWithoutDeposit(rent);
                    total += optionsPrice;
                }
                else if(!calckExtend) {
                    total += price?.deposit || 0;
                }
                return {
                    rent,
                    total,
                    discount: discount !== 1,
                    options: optionsPrice,
                    periodDays,
                    ...price
                }
            }
            else return {};
        },
        allowedActions(s, g, rootS){
            const userId = rootS.auth.user?.id;
            const ownerId = s.deal?.car?.owner?.id;
            const status = s.deal?.status?.id;
            const isOwner = userId === ownerId;

            return {
                edit: allowedEdit(status, isOwner),
                actions: allowedActions(status, isOwner),
            }
        },
        prolongationStatus(s) {
            let list = s.deal?.prolongations || [];
            list = [...list].reverse();
            const checkStatus = (statusId) => list.find(({status}) => status?.id === statusId);
            const {NEW, PAYED, CONFIRMED, CANCELED} = PROLONGATION_STATUS;

            return {
                new: checkStatus(NEW),
                payed: checkStatus(PAYED),
                confirmed: checkStatus(CONFIRMED),
                active: checkStatus(NEW) || checkStatus(CONFIRMED),
                canceled: checkStatus(CANCELED),
            }
        },
    }
});
