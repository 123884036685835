import { getUtm } from "@/utils/router";
import { v4 as uuidv4 } from "uuid";
import { cookiesSet } from "@/utils/store";
import { LOCALSTORAGE_NAMES } from '~/utils/_consts';

export default ({ route, app }) => {
    setIdRR('rr_store_id', (name, uuid) => {
        cookiesSet(app.$cookies, name, uuid);
    });
    setIdRR('rr_session_id', (name, uuid) => {
        cookiesSet(app.$cookies, name, uuid, { expires: undefined });
    });

    if (process.client) {
        setLocalStorage(LOCALSTORAGE_NAMES.utm, {
            ...getUtm(),
            ...queryUtm(route.query),
        });
        if (route.query.referral_id) {
            const id = parseInt(route.query.referral_id);
            if (!isNaN(id)) {
                setLocalStorage(LOCALSTORAGE_NAMES.referralId, id);
            }
        }
    }

    function setLocalStorage(key, data) {
        try {
            localStorage.setItem(key, JSON.stringify(data));
        } catch (e) {
            console.warn(e);
        }
    }

    function setIdRR(name, setFn) {
        let uuid = app.$cookies.get(name) ?? app[name];
        if (!uuid) {
            uuid = uuidv4();
            setFn(name, uuid);
        }
        app[name] = uuid;
    }
}


export function queryUtm(query) {
    const allowed = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content'];
    return Object.keys(query).reduce((obj, key) => {//Sort only utm_
        if (allowed.includes(key)) obj[key] = query[key];
        return obj;
    }, {});
}
