import Vue from 'vue'
import vSelect from 'vue-select'
import OpenIndicator from "@/assets/img/icons/select-arrow.svg?inline";

vSelect.props.components.default = () => ({
    OpenIndicator,
    Deselect: undefined
});

export default function () {
    Vue.component('v-select', vSelect)
}
