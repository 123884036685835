import Vue from 'vue'
import Vuex from 'vuex'
import Meta from 'vue-meta'
import ClientOnly from 'vue-client-only'
import NoSsr from 'vue-no-ssr'
import { createRouter } from './router.js'
import NuxtChild from './components/nuxt-child.js'
import NuxtError from '../../layouts/error.vue'
import Nuxt from './components/nuxt.js'
import App from './App.js'
import { setContext, getLocation, getRouteData, normalizeError } from './utils'
import { createStore } from './store.js'

/* Plugins */

import nuxt_plugin_sentryserver_50e73e8c from 'nuxt_plugin_sentryserver_50e73e8c' // Source: ./sentry.server.js (mode: 'server')
import nuxt_plugin_sentryclient_71d2ef32 from 'nuxt_plugin_sentryclient_71d2ef32' // Source: ./sentry.client.js (mode: 'client')
import nuxt_plugin_image_de96181e from 'nuxt_plugin_image_de96181e' // Source: ./image.js (mode: 'all')
import nuxt_plugin_vlazyload_49868364 from 'nuxt_plugin_vlazyload_49868364' // Source: ./v-lazy-load.js (mode: 'all')
import nuxt_plugin_vuescrollto_2ad5235a from 'nuxt_plugin_vuescrollto_2ad5235a' // Source: ./vue-scrollto.js (mode: 'client')
import nuxt_plugin_vuesocialsharingplugin_24cfca22 from 'nuxt_plugin_vuesocialsharingplugin_24cfca22' // Source: ./vue-social-sharing-plugin.js (mode: 'all')
import nuxt_plugin_cookieuniversalnuxt_41922ac4 from 'nuxt_plugin_cookieuniversalnuxt_41922ac4' // Source: ./cookie-universal-nuxt.js (mode: 'all')
import nuxt_plugin_axios_b4a41214 from 'nuxt_plugin_axios_b4a41214' // Source: ./axios.js (mode: 'all')
import nuxt_plugin_deviceplugin_0c9b8efe from 'nuxt_plugin_deviceplugin_0c9b8efe' // Source: ./device.plugin.js (mode: 'all')
import nuxt_plugin_mediaQueryPlugin_79f316ab from 'nuxt_plugin_mediaQueryPlugin_79f316ab' // Source: ./mediaQueryPlugin.js (mode: 'all')
import nuxt_plugin_workbox_73dc8692 from 'nuxt_plugin_workbox_73dc8692' // Source: ./workbox.js (mode: 'client')
import nuxt_plugin_metaplugin_0b51c8b6 from 'nuxt_plugin_metaplugin_0b51c8b6' // Source: ./pwa/meta.plugin.js (mode: 'all')
import nuxt_plugin_iconplugin_02027dce from 'nuxt_plugin_iconplugin_02027dce' // Source: ./pwa/icon.plugin.js (mode: 'all')
import nuxt_plugin_moment_1ebc7962 from 'nuxt_plugin_moment_1ebc7962' // Source: ./moment.js (mode: 'all')
import nuxt_plugin_index_c34dee86 from 'nuxt_plugin_index_c34dee86' // Source: ../../plugins/global/index (mode: 'all')
import nuxt_plugin_headerframeoptions_2f4915fc from 'nuxt_plugin_headerframeoptions_2f4915fc' // Source: ../../plugins/header-frame-options.server (mode: 'server')
import nuxt_plugin_axios_fb9c9a02 from 'nuxt_plugin_axios_fb9c9a02' // Source: ../../plugins/axios (mode: 'all')
import nuxt_plugin_redirectPermanently_10f06008 from 'nuxt_plugin_redirectPermanently_10f06008' // Source: ../../plugins/redirectPermanently (mode: 'all')
import nuxt_plugin_ssrcookieproxy_62933f1b from 'nuxt_plugin_ssrcookieproxy_62933f1b' // Source: ../../plugins/ssr-cookie-proxy.server (mode: 'server')
import nuxt_plugin_seo_6e031db4 from 'nuxt_plugin_seo_6e031db4' // Source: ../../plugins/seo (mode: 'all')
import nuxt_plugin_notify_4c8c7c40 from 'nuxt_plugin_notify_4c8c7c40' // Source: ../../plugins/notify (mode: 'all')
import nuxt_plugin_mask_56cceb43 from 'nuxt_plugin_mask_56cceb43' // Source: ../../plugins/mask (mode: 'all')
import nuxt_plugin_vueselect_93678b8c from 'nuxt_plugin_vueselect_93678b8c' // Source: ../../plugins/vue-select (mode: 'all')
import nuxt_plugin_GTM_1cdbeb90 from 'nuxt_plugin_GTM_1cdbeb90' // Source: ../../plugins/GTM.client (mode: 'client')
import nuxt_plugin_schemaorg_6f8d60ae from 'nuxt_plugin_schemaorg_6f8d60ae' // Source: ../../plugins/schema-org (mode: 'all')
import nuxt_plugin_filters_98405076 from 'nuxt_plugin_filters_98405076' // Source: ../../plugins/filters (mode: 'all')
import nuxt_plugin_payInfo_9049ad80 from 'nuxt_plugin_payInfo_9049ad80' // Source: ../../plugins/payInfo (mode: 'all')
import nuxt_plugin_plugin_833d189c from 'nuxt_plugin_plugin_833d189c' // Source: ./auth/plugin.js (mode: 'all')
import nuxt_plugin_token_ff5af42e from 'nuxt_plugin_token_ff5af42e' // Source: ../../plugins/auth/token (mode: 'all')
import nuxt_plugin_index_e62f47fc from 'nuxt_plugin_index_e62f47fc' // Source: ../../plugins/signal/index (mode: 'all')
import nuxt_plugin_seo_491fc44d from 'nuxt_plugin_seo_491fc44d' // Source: ../../plugins/auth/seo (mode: 'all')
import nuxt_plugin_resetStoreAtLogout_11d94c9f from 'nuxt_plugin_resetStoreAtLogout_11d94c9f' // Source: ../../plugins/auth/resetStoreAtLogout (mode: 'all')
import nuxt_plugin_index_550b2599 from 'nuxt_plugin_index_550b2599' // Source: ../../plugins/splitTest/index (mode: 'all')
import nuxt_plugin_echo_72fbc1dd from 'nuxt_plugin_echo_72fbc1dd' // Source: ./echo.js (mode: 'client')
import nuxt_plugin_index_896daf44 from 'nuxt_plugin_index_896daf44' // Source: ../../plugins/echo/index (mode: 'client')
import nuxt_plugin_payments_7a5bad60 from 'nuxt_plugin_payments_7a5bad60' // Source: ../../plugins/echo/payments (mode: 'client')
import nuxt_plugin_userUpdate_5413a9e7 from 'nuxt_plugin_userUpdate_5413a9e7' // Source: ../../plugins/echo/userUpdate (mode: 'client')
import nuxt_plugin_authWatcher_676337e1 from 'nuxt_plugin_authWatcher_676337e1' // Source: ../../plugins/echo/authWatcher (mode: 'client')

// Component: <ClientOnly>
Vue.component(ClientOnly.name, ClientOnly)

// TODO: Remove in Nuxt 3: <NoSsr>
Vue.component(NoSsr.name, {
  ...NoSsr,
  render (h, ctx) {
    if (process.client && !NoSsr._warned) {
      NoSsr._warned = true

      console.warn('<no-ssr> has been deprecated and will be removed in Nuxt 3, please use <client-only> instead')
    }
    return NoSsr.render(h, ctx)
  }
})

// Component: <NuxtChild>
Vue.component(NuxtChild.name, NuxtChild)
Vue.component('NChild', NuxtChild)

// Component NuxtLink is imported in server.js or client.js

// Component: <Nuxt>
Vue.component(Nuxt.name, Nuxt)

Object.defineProperty(Vue.prototype, '$nuxt', {
  get() {
    const globalNuxt = this.$root.$options.$nuxt
    if (process.client && !globalNuxt && typeof window !== 'undefined') {
      return window.$nuxt
    }
    return globalNuxt
  },
  configurable: true
})

Vue.use(Meta, {"keyName":"head","attribute":"data-n-head","ssrAttribute":"data-n-head-ssr","tagIDKeyName":"hid"})

const defaultTransition = {"name":"page","mode":"out-in","appear":false,"appearClass":"appear","appearActiveClass":"appear-active","appearToClass":"appear-to"}

const originalRegisterModule = Vuex.Store.prototype.registerModule

function registerModule (path, rawModule, options = {}) {
  const preserveState = process.client && (
    Array.isArray(path)
      ? !!path.reduce((namespacedState, path) => namespacedState && namespacedState[path], this.state)
      : path in this.state
  )
  return originalRegisterModule.call(this, path, rawModule, { preserveState, ...options })
}

async function createApp(ssrContext, config = {}) {
  const router = await createRouter(ssrContext, config)

  const store = createStore(ssrContext)
  // Add this.$router into store actions/mutations
  store.$router = router

  // Fix SSR caveat https://github.com/nuxt/nuxt.js/issues/3757#issuecomment-414689141
  store.registerModule = registerModule

  // Create Root instance

  // here we inject the router and store to all child components,
  // making them available everywhere as `this.$router` and `this.$store`.
  const app = {
    head: {"title":"RentRide","meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1"},{"hid":"description","name":"description","content":"RentRide"}],"link":[{"rel":"icon","type":"image\u002Fsvg+xml","href":"\u002Ffavicon\u002Ficon.svg"},{"rel":"apple-touch-icon","sizes":"180x180","href":"\u002Ffavicon\u002Fapple-touch-icon.png"},{"rel":"stylesheet","href":"https:\u002F\u002Ffonts.googleapis.com\u002Fcss2?family=Inter:wght@400&display=swap"},{"rel":"preload","as":"style","href":"https:\u002F\u002Ffonts.googleapis.com\u002Fcss2?family=Inter:wght@100;300;400;500;600;700;800&display=swap","onload":"this.rel='stylesheet'"}],"__dangerouslyDisableSanitizers":["link"],"style":[],"script":[]},

    store,
    router,
    nuxt: {
      defaultTransition,
      transitions: [defaultTransition],
      setTransitions (transitions) {
        if (!Array.isArray(transitions)) {
          transitions = [transitions]
        }
        transitions = transitions.map((transition) => {
          if (!transition) {
            transition = defaultTransition
          } else if (typeof transition === 'string') {
            transition = Object.assign({}, defaultTransition, { name: transition })
          } else {
            transition = Object.assign({}, defaultTransition, transition)
          }
          return transition
        })
        this.$options.nuxt.transitions = transitions
        return transitions
      },

      err: null,
      dateErr: null,
      error (err) {
        err = err || null
        app.context._errored = Boolean(err)
        err = err ? normalizeError(err) : null
        let nuxt = app.nuxt // to work with @vue/composition-api, see https://github.com/nuxt/nuxt.js/issues/6517#issuecomment-573280207
        if (this) {
          nuxt = this.nuxt || this.$options.nuxt
        }
        nuxt.dateErr = Date.now()
        nuxt.err = err
        // Used in src/server.js
        if (ssrContext) {
          ssrContext.nuxt.error = err
        }
        return err
      }
    },
    ...App
  }

  // Make app available into store via this.app
  store.app = app

  const next = ssrContext ? ssrContext.next : location => app.router.push(location)
  // Resolve route
  let route
  if (ssrContext) {
    route = router.resolve(ssrContext.url).route
  } else {
    const path = getLocation(router.options.base, router.options.mode)
    route = router.resolve(path).route
  }

  // Set context to app.context
  await setContext(app, {
    store,
    route,
    next,
    error: app.nuxt.error.bind(app),
    payload: ssrContext ? ssrContext.payload : undefined,
    req: ssrContext ? ssrContext.req : undefined,
    res: ssrContext ? ssrContext.res : undefined,
    beforeRenderFns: ssrContext ? ssrContext.beforeRenderFns : undefined,
    ssrContext
  })

  function inject(key, value) {
    if (!key) {
      throw new Error('inject(key, value) has no key provided')
    }
    if (value === undefined) {
      throw new Error(`inject('${key}', value) has no value provided`)
    }

    key = '$' + key
    // Add into app
    app[key] = value
    // Add into context
    if (!app.context[key]) {
      app.context[key] = value
    }

    // Add into store
    store[key] = app[key]

    // Check if plugin not already installed
    const installKey = '__nuxt_' + key + '_installed__'
    if (Vue[installKey]) {
      return
    }
    Vue[installKey] = true
    // Call Vue.use() to install the plugin into vm
    Vue.use(() => {
      if (!Object.prototype.hasOwnProperty.call(Vue.prototype, key)) {
        Object.defineProperty(Vue.prototype, key, {
          get () {
            return this.$root.$options[key]
          }
        })
      }
    })
  }

  // Inject runtime config as $config
  inject('config', config)

  if (process.client) {
    // Replace store state before plugins execution
    if (window.__NUXT__ && window.__NUXT__.state) {
      store.replaceState(window.__NUXT__.state)
    }
  }

  // Add enablePreview(previewData = {}) in context for plugins
  if (process.static && process.client) {
    app.context.enablePreview = function (previewData = {}) {
      app.previewData = Object.assign({}, previewData)
      inject('preview', previewData)
    }
  }
  // Plugin execution

  if (process.server && typeof nuxt_plugin_sentryserver_50e73e8c === 'function') {
    await nuxt_plugin_sentryserver_50e73e8c(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_sentryclient_71d2ef32 === 'function') {
    await nuxt_plugin_sentryclient_71d2ef32(app.context, inject)
  }

  if (typeof nuxt_plugin_image_de96181e === 'function') {
    await nuxt_plugin_image_de96181e(app.context, inject)
  }

  if (typeof nuxt_plugin_vlazyload_49868364 === 'function') {
    await nuxt_plugin_vlazyload_49868364(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vuescrollto_2ad5235a === 'function') {
    await nuxt_plugin_vuescrollto_2ad5235a(app.context, inject)
  }

  if (typeof nuxt_plugin_vuesocialsharingplugin_24cfca22 === 'function') {
    await nuxt_plugin_vuesocialsharingplugin_24cfca22(app.context, inject)
  }

  if (typeof nuxt_plugin_cookieuniversalnuxt_41922ac4 === 'function') {
    await nuxt_plugin_cookieuniversalnuxt_41922ac4(app.context, inject)
  }

  if (typeof nuxt_plugin_axios_b4a41214 === 'function') {
    await nuxt_plugin_axios_b4a41214(app.context, inject)
  }

  if (typeof nuxt_plugin_deviceplugin_0c9b8efe === 'function') {
    await nuxt_plugin_deviceplugin_0c9b8efe(app.context, inject)
  }

  if (typeof nuxt_plugin_mediaQueryPlugin_79f316ab === 'function') {
    await nuxt_plugin_mediaQueryPlugin_79f316ab(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_workbox_73dc8692 === 'function') {
    await nuxt_plugin_workbox_73dc8692(app.context, inject)
  }

  if (typeof nuxt_plugin_metaplugin_0b51c8b6 === 'function') {
    await nuxt_plugin_metaplugin_0b51c8b6(app.context, inject)
  }

  if (typeof nuxt_plugin_iconplugin_02027dce === 'function') {
    await nuxt_plugin_iconplugin_02027dce(app.context, inject)
  }

  if (typeof nuxt_plugin_moment_1ebc7962 === 'function') {
    await nuxt_plugin_moment_1ebc7962(app.context, inject)
  }

  if (typeof nuxt_plugin_index_c34dee86 === 'function') {
    await nuxt_plugin_index_c34dee86(app.context, inject)
  }

  if (process.server && typeof nuxt_plugin_headerframeoptions_2f4915fc === 'function') {
    await nuxt_plugin_headerframeoptions_2f4915fc(app.context, inject)
  }

  if (typeof nuxt_plugin_axios_fb9c9a02 === 'function') {
    await nuxt_plugin_axios_fb9c9a02(app.context, inject)
  }

  if (typeof nuxt_plugin_redirectPermanently_10f06008 === 'function') {
    await nuxt_plugin_redirectPermanently_10f06008(app.context, inject)
  }

  if (process.server && typeof nuxt_plugin_ssrcookieproxy_62933f1b === 'function') {
    await nuxt_plugin_ssrcookieproxy_62933f1b(app.context, inject)
  }

  if (typeof nuxt_plugin_seo_6e031db4 === 'function') {
    await nuxt_plugin_seo_6e031db4(app.context, inject)
  }

  if (typeof nuxt_plugin_notify_4c8c7c40 === 'function') {
    await nuxt_plugin_notify_4c8c7c40(app.context, inject)
  }

  if (typeof nuxt_plugin_mask_56cceb43 === 'function') {
    await nuxt_plugin_mask_56cceb43(app.context, inject)
  }

  if (typeof nuxt_plugin_vueselect_93678b8c === 'function') {
    await nuxt_plugin_vueselect_93678b8c(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_GTM_1cdbeb90 === 'function') {
    await nuxt_plugin_GTM_1cdbeb90(app.context, inject)
  }

  if (typeof nuxt_plugin_schemaorg_6f8d60ae === 'function') {
    await nuxt_plugin_schemaorg_6f8d60ae(app.context, inject)
  }

  if (typeof nuxt_plugin_filters_98405076 === 'function') {
    await nuxt_plugin_filters_98405076(app.context, inject)
  }

  if (typeof nuxt_plugin_payInfo_9049ad80 === 'function') {
    await nuxt_plugin_payInfo_9049ad80(app.context, inject)
  }

  if (typeof nuxt_plugin_plugin_833d189c === 'function') {
    await nuxt_plugin_plugin_833d189c(app.context, inject)
  }

  if (typeof nuxt_plugin_token_ff5af42e === 'function') {
    await nuxt_plugin_token_ff5af42e(app.context, inject)
  }

  if (typeof nuxt_plugin_index_e62f47fc === 'function') {
    await nuxt_plugin_index_e62f47fc(app.context, inject)
  }

  if (typeof nuxt_plugin_seo_491fc44d === 'function') {
    await nuxt_plugin_seo_491fc44d(app.context, inject)
  }

  if (typeof nuxt_plugin_resetStoreAtLogout_11d94c9f === 'function') {
    await nuxt_plugin_resetStoreAtLogout_11d94c9f(app.context, inject)
  }

  if (typeof nuxt_plugin_index_550b2599 === 'function') {
    await nuxt_plugin_index_550b2599(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_echo_72fbc1dd === 'function') {
    await nuxt_plugin_echo_72fbc1dd(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_index_896daf44 === 'function') {
    await nuxt_plugin_index_896daf44(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_payments_7a5bad60 === 'function') {
    await nuxt_plugin_payments_7a5bad60(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_userUpdate_5413a9e7 === 'function') {
    await nuxt_plugin_userUpdate_5413a9e7(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_authWatcher_676337e1 === 'function') {
    await nuxt_plugin_authWatcher_676337e1(app.context, inject)
  }

  // Lock enablePreview in context
  if (process.static && process.client) {
    app.context.enablePreview = function () {
      console.warn('You cannot call enablePreview() outside a plugin.')
    }
  }

  // Wait for async component to be resolved first
  await new Promise((resolve, reject) => {
    // Ignore 404s rather than blindly replacing URL in browser
    if (process.client) {
      const { route } = router.resolve(app.context.route.fullPath)
      if (!route.matched.length) {
        return resolve()
      }
    }
    router.replace(app.context.route.fullPath, resolve, (err) => {
      // https://github.com/vuejs/vue-router/blob/v3.4.3/src/util/errors.js
      if (!err._isRouter) return reject(err)
      if (err.type !== 2 /* NavigationFailureType.redirected */) return resolve()

      // navigated to a different route in router guard
      const unregister = router.afterEach(async (to, from) => {
        if (process.server && ssrContext && ssrContext.url) {
          ssrContext.url = to.fullPath
        }
        app.context.route = await getRouteData(to)
        app.context.params = to.params || {}
        app.context.query = to.query || {}
        unregister()
        resolve()
      })
    })
  })

  return {
    store,
    app,
    router
  }
}

export { createApp, NuxtError }
