import sortBy from "lodash/sortBy";
import moment from "moment";
import {includedRange} from "@/utils/data";

export function sortCars(cars, sort) {
    if(!sort) return cars;
    const {filed, invert} = getParamsSort(sort);
    let list = sortBy(cars, filed);
    if(invert) list.reverse();
    return list;
}

function getParamsSort(sort){
    const filed = sort.replace(/^-/, '');
    const alias = {
        price: 'max_price_per_day'
    };
    return {
        filed: alias[filed] || filed,
        invert: filed !== sort
    };
}

export function processDiscount(discounts = []){
    return discounts.map(({period_start, period_end, percent})=>({
        period_start: +moment(period_start).startOf('day'),
        period_end: +moment(period_end).endOf('day'),
        discount: (percent+100)/100
    }));
}

export function getDiscount(date, discounts = []){
    return discounts.find(({period_start, period_end})=>{
        return includedRange(period_start, period_end, date);
    })?.discount ?? 1;
}
