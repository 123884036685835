import { formatNumber } from "@/utils/format/formatNumber";
import { NBSP } from '@/utils/_consts';

export function formatPrice(price) {
    if (price != null) {
        const formattedNumber = formatNumber(price);
        return `${formattedNumber}${NBSP}₽`;
    }
    return ''
}
