import Vue from "vue";
import { StoreApi } from "~/lib/storeRestApi";
import { getStore, onSuccessData, onSuccessDataPages, querySerializer } from "@/utils/store";
import { FILTERS } from "@/utils/_consts";

const _wizardEmpty = () => ({
    option: {},
    uploads: [],
    uploadsSts: [],
    imagesSts: [],//Для сохранения старых фото
    vehicle_type: 'car',
    rent_options: [],
});

const _dealsEmpty = () => ({
    list: [],
    total: 0,
    pages: 0,
    currentPage: 0,
});

const api = new StoreApi({
    state: {
        posts: {},
        postsTyped: {},
        car: {},
        wizard: _wizardEmpty(),
        wizardCar: {},
        wizardHideImages: [],
        deals: _dealsEmpty(),
        messages: _dealsEmpty(),
        filtersDeals: {
            status: 0,
        },
        actualDeal: null,
        actualDealLastFetchTimestamp: null,
        profileUnreadMessagesCount: 0,
    },
}).post({
    action: "authCode",
    property: "authCodeResult",
    path: ({ method }) => `/auth/${method}`,
    queryParams: true,
    requestConfig: querySerializer,
}).put({
    action: "authCodeValidate",
    property: "authCodeValidateResult",
    path: ({ method }) => `/auth/${method}`,
}).post({
    action: "register",
    property: "registerResult",
    path: '/register',
}).post({
    action: "resetPassword",
    property: "resetPasswordResult",
    path: '/reset',
}).post({
    action: "resetPasswordConfirm",
    property: "resetPasswordConfirmResult",
    path: '/reset/confirm',
}).post({
    action: "registerFast",
    property: "registerResult",
    path: '/register/fast',
}).post({
    action: "profileUpdate",
    property: "profileUpdateResult",
    path: '/profile',
}).post({
    action: "profileRequisitesDefaultUpdate",
    property: "profileRequisitesDefault",
    path: '/profile/requisites/edit/default',
}).get({
    action: "getMyCars",
    property: "myCars",
    path: '/profile/cars',
    requestConfig: querySerializer,
    onSuccess: onSuccessData('myCars'),
}).get({
    action: "getMyCar",
    property: "wizardCar",
    path: ({ id }) => `/profile/cars/${id}`,
}).post({
    action: "addCar",
    property: "addCarResult",
    path: '/cars',
}).delete({
    action: "removePhotoCar",
    property: "removePhotoCarResult",
    path: ({ car, id }) => `/cars/${car}/images/${id}`,
}).post({
    action: "updateCar",
    property: "updateCarResult",
    path: ({ id }) => `/cars/${id}`,
}).post({
    action: "updateCarSts",
    property: "updateCarStsResult",
    path: ({ id }) => `/cars/${id}/sts`,
}).delete({
    action: "removeCar",
    property: "removeCarResult",
    path: ({ id }) => `/cars/${id}`,
}).post({
    action: "updateDocs",
    property: "updateDocsResult",
    path: '/profile/documents',
}).get({
    action: "getDocs",
    property: "profileDocs",
    path: '/profile/documents',
}).get({
    action: "getRequisites",
    property: "profileRequisites",
    path: '/profile/requisites',
}).post({
    action: "addRequisites",
    property: "addRequisitesResult",
    path: '/profile/requisites',
}).put({
    action: "updateRequisites",
    property: "updateRequisitesResult",
    path: ({ id }) => `/profile/requisites/${id}`,
}).delete({
    action: "deleteRequisites",
    property: "deleteRequisitesResult",
    path: ({ id }) => `/profile/requisites/${id}`,
}).delete({
    action: "deletePans",
    property: "deletePansResult",
    path: ({ id }) => `/profile/pans/${id}`,
}).get({
    action: "getDeals",
    property: "deals",
    path: '/deals',
    requestConfig: querySerializer,
    onSuccess: onSuccessDataPages('deals'),
}).get({
    action: "getMessages",
    property: "messages",
    path: '/profile/messages',
    requestConfig: querySerializer,
    onSuccess: onSuccessDataPages('messages'),
}).get({
    action: "getSystemMessages",
    property: "systemMessages",
    path: ({page}) => `/profile/messages/rentride?page=${page}`,
    onSuccess(s, {data: {data: {data: messages}, unread_count, last}}) {
        if (!s.systemMessages) {
            s.systemMessages = messages;
        } else {
            s.systemMessages = [...messages, ...s.systemMessages];
        }
        s.unreadSystemMessagesCount = unread_count;
        s.systemLastMessage = last;
    },
}).post({
    action: "sendSystemMessages",
    property: "sendSystemMessagesResult",
    path: '/profile/messages/rentride',
}).get({
    action: "readSystemMessages",
    property: "readSystemMessagesResult",
    path: '/profile/messages/rentride/readAll',
}).get({
    action: "_getActualDeal",
    property: "actualDeal",
    path: '/actual/deal',
}).get({
    action: "getBrandAndModelInfo",
    property: "brandAndModelInfo",
    path: '/cars/brand/model/info',
    requestConfig: querySerializer,
}).post({
    action: "changeContacts",
    property: "changeContactsResult",
    path: ({ type }) => `/profile/contacts/${type}`,
});

export default getStore(api, {
    actions: {
        getMyCarFill({ dispatch, commit }, id) {
            return dispatch('getMyCar', { params: { id } }).then(({ data }) => {
                return commit('fillWizard', data);
            });
        },
        getActualDeal({ dispatch, commit }, params) {
            return dispatch('_getActualDeal', params)
                .catch((e) => {
                    if (e.statusCode !== 404) {
                        throw e;
                    }
                }).finally(() => {
                    commit('updateActualDealLastFetchTimestamp');
                });
        },
        setMessageUnread({ state: s, dispatch, commit }, { deal: { deal_id, unread_msg }, isChat }) {
            if (isChat) {
                if (!s.messages.list.find(({ id }) => id === deal_id)) {
                    dispatch('rent/getDeal', { params: { id: deal_id } }, { root: true }).then(({ data }) => {
                        commit('addDealMessage', data);
                    });
                } else if (unread_msg.last) {
                    commit('upDealMessage', deal_id);
                }
            }

            commit('setMessage', { deal_id, unread_msg });
        },
    },
    mutations: {
        addDealMessage(s, deal) {
            s.messages.list.unshift(deal);
        },
        upDealMessage(s, deal_id) {
            const messagesIndex = s.messages.list.findIndex(({ id }) => id === deal_id);
            if (~messagesIndex) {
                const deal = s.messages.list[messagesIndex];
                s.messages.list.splice(messagesIndex, 1);
                s.messages.list.unshift(deal);
            }
        },
        setMessage(s, { deal_id, unread_msg }) {
            const messagesIndex = s.messages.list.findIndex(({ id }) => id === deal_id);
            if (~messagesIndex) {
                const messages = s.messages.list[messagesIndex].messages ?? {};
                Vue.set(s.messages.list[messagesIndex], 'messages', {
                    ...messages,
                    ...unread_msg,
                    last: unread_msg.last ?? messages.last,
                });
            }

            const dealsIndex = s.deals.list.findIndex(({ id }) => id === deal_id);
            if (~dealsIndex) {
                const messages = s.deals.list[dealsIndex].messages ?? {};
                Vue.set(s.deals.list[dealsIndex], 'messages', {
                    ...messages,
                    ...unread_msg,
                    last: unread_msg.last ?? messages.last,
                });
            }
        },
        setErrorWizard(s, { state, errors, message = 'not valid' }) {
            Vue.set(s.error, state, {
                errors,
                message,
            });
        },
        setFiltersDeals(s, { name, val }) {
            Vue.set(s.filtersDeals, name, val);
        },
        setFiltersDealsObj(s, obj) {
            Object.keys(obj).forEach(key => {
                let val = obj[key] ?? undefined;
                if (typeof val === 'number' && isNaN(val)) {
                    val = undefined;
                }
                Vue.set(s.filtersDeals, key, val);
            });
        },
        resetFiltersDeals(s) {
            s.filtersDeals = {};
        },
        setError(s, { name, data = null }) {
            Vue.set(s.error, name, data);
        },
        setWizardVehicleType(s, type) {
            Vue.set(s.wizard, 'vehicle_type', type);
        },
        setWizardData(s, { name, val }) {
            if (FILTERS.ROOT.includes(name)) {
                Vue.set(s.wizard, name, val);
            } else {
                if (!s.wizard.options || Array.isArray(s.wizard.options)) {
                    Vue.set(s.wizard, 'options', {});
                }
                Vue.set(s.wizard.options, name, val);
            }
        },
        setWizardDeliveryTariffData(s, {name, val}) {
            if (!s.wizard.delivery_tariff) {
                Vue.set(s.wizard, 'delivery_tariff', {});
            }
            Vue.set(s.wizard.delivery_tariff, name, val);
        },
        removeWizardData(s, name) {
            if (FILTERS.ROOT.includes(name)) {
                Vue.delete(s.wizard, name);
            } else if (s.wizard.options) {
                Vue.delete(s.wizard.options, name);
            }
        },
        setWizardDataArray(s, { name, val, index }) {
            if (!s.wizard[name]) {
                Vue.set(s.wizard, name, []);
            }
            Vue.set(s.wizard[name], index, val);
        },
        removeWizardDataArray(s, { name, index }) {
            s.wizard[name].splice(index, 1);
        },
        resetAddCarResult(s) {
            s.addCarResult = null;
        },
        resetWizard(s) {
            s.wizardCar = {};
            s.wizard = _wizardEmpty();
        },
        changeWizardCarStatus(s, status) {
            Vue.set(s.wizardCar, 'status', status);
        },
        updateWizardCar(s, data) {
            Object.entries(data).forEach(([key, value]) => {
                Vue.set(s.wizardCar, key, value);
            });
        },
        fillWizard(s, car) {
            const exceptions = ['prices', 'calendar', 'discounts', 'owner', 'options'];
            const fill = (toList, list, defaultVal = {}) => {
                Object.keys({ ...toList, ...list }).forEach((key) => {
                    const val = list[key] ?? defaultVal[key];
                    if (val !== undefined && !exceptions.includes(key)) {
                        Vue.set(toList, key, val?.alias || val);
                    } else {
                        Vue.delete(toList, key);
                    }
                });
            }
            Vue.set(s, 'wizardCar', car);
            fill(s.wizard, car, _wizardEmpty());
            Vue.set(s.wizard, 'options', {});
            fill(s.wizard.options, car.options || {});

            Vue.set(s.wizard, 'vehicle_type', car.vehicle_type?.alias ?? car.vehicle_type ?? 'car');

            return car;
        },
        updateActualDealLastFetchTimestamp(s, timestamp) {
            s.actualDealLastFetchTimestamp = timestamp || Date.now();
        },
        resetActualDeal(s) {
            s.actualDeal = null;
            s.actualDealLastFetchTimestamp = null;
        },
        setWizardHideImages(s, list) {
            s.wizardHideImages = list;
        },
        setProfileUnreadMessagesCount(s, count) {
            s.profileUnreadMessagesCount = count;
        },
        resetMessages(s) {
            s.messages = _dealsEmpty();
            s.systemMessages = null;
            s.profileUnreadMessagesCount = 0;
            s.unreadSystemMessagesCount = 0;
        },
        setSystemMessagesUnreadCount(s, count) {
          s.unreadSystemMessagesCount = count;
        },
        readDealMessages(s, dealId) {
            const messagesList = s.messages.list;
            const dealIndex = messagesList.findIndex((deal) => deal.id === dealId);
            const deal = messagesList[dealIndex];
            if (deal) {
                const updatedDeal = {
                    ...deal,
                    messages: {
                        ...deal.messages,
                        unread_count_owner: 0,
                        unread_count_renter: 0,
                    },
                };
                Vue.set(messagesList, dealIndex, updatedDeal);
            }
        },
        addSystemMessages(s, message) {
            s.systemMessages.push(message);
            s.systemLastMessage = message;
        },
    },
    getters: {
        filtersDealClean(s) {
            const storeFilter = s.filtersDeals;
            const filters = Object.keys(storeFilter).reduce((tmp, key) => {
                if (storeFilter[key]) tmp[key] = storeFilter[key];
                return tmp;
            }, {});

            return filters;
        },
        wizardVehicleType(s) {
            return s.wizard.vehicle_type;
        },
        isWizardMoto(s, g) {
            return g.wizardVehicleType === 'moto';
        },
        wizardCarStatusId(s) {
            return s.wizardCar.status?.id ?? s.wizardCar.status;
        },
        getWizardParam: (s) => (name) => {
            if (FILTERS.ROOT.includes(name)) {
                return s.wizard[name];
            } else {
                if (s.wizard.options) {
                    return s.wizard.options[name];
                }
            }
        },
    },
});
