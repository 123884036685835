export default {
    namespaced: true,
    state: () => ({
        topics: {},
    }),
    mutations: {
        setTopics(s, data) {
            s.topics = data;
        }
    },
    actions: {
        init() {
        },
    }
}
