import path from 'path'

export const mediaQueryBreakpoints = {
    sm: 576, // Mobile small
    md: 768, // Mobile
    lg: 992, // Tablet
    xl: 1200, // Laptop
    xxl: 1324, // Laptop Large
    xxxl: 1436, // PC
}

export default function (moduleOptions) {
    const options = Object.assign(mediaQueryBreakpoints, moduleOptions);
    this.addPlugin({
        src: path.resolve(__dirname, 'plugin.js'),
        fileName: 'mediaQueryPlugin.js',
        options: options,
    });
}
