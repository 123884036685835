export default function ({app: {store}, route}) {
    const queryModal = route.query?.modal;
    const storeModal = store.state.modal.activeModal;
    if(queryModal) {
        if(storeModal !== queryModal) {
            store.commit('modal/openModal', route.query.modal);
        }
    }
    else if (storeModal) {
        store.commit('modal/closeModal');
    }
}
