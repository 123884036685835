import Vue from 'vue'
import _isString from 'lodash/isString';

function handleSSRError(err) {
    if (_isString(err.message)) {
        if ((/appendChild(.*)Node/g).test(err.message) || err.message.includes('incorrect node tree')) {
            const url = new URL(window.location);
            if (!url.searchParams.get('srr-error')) {
                url.searchParams.set('srr-error', '1');
                window.location.search = url.searchParams.toString();
            }
        }
    }
}

if (process.client) {
    const UbiNotify = require("vue-ubi-notify").default;
    Vue.use(UbiNotify, {cssFramework: "bulma"});

    Vue.config.errorHandler = function (err, vm, info) {
        handleSSRError(err);
        vm?.$sentry?.addBreadcrumb({
            category: "notify",
            message: `[${vm?.$options?.name}] ${info}: ${err?.message}`,
            level: vm?.$sentry?.Severity?.Info,
        });
        Vue.prototype.$notify(`[${vm?.$options?.name}] ${info}: ${err?.message}`, 'Внутреняя ошибка', 'danger', null, 30000);
        console.error(info, vm, err);
    };
    /*Vue.config.warnHandler = function (msg, vm, trace) {
      Vue.prototype.$notify(`[${vm && vm.$options.name}] ${msg}`, 'Внутренняя ошибка', 'warning', null, 30000);
      console.warn(vm, msg, trace);
    };*/
}
