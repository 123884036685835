import { mergeDocsUser } from '@/utils/mergeDocsUser/mergeDocsUser';

export default function ({ $echo, $auth }) {
    let userId = $auth.user?.id;

    if ($auth.loggedIn) {
        connect();
    }
    $auth.$storage.watchState('loggedIn', (loggedIn) => {
        disconnect();
        if (loggedIn) {
            userId = $auth.user?.id;
            connect();
        }
    })

    function connect() {
        $echo.join(`user.${userId}`).listen('.user.updated', (user) => {
            if (user?.id === $auth.user?.id) {
                $auth.setUser(mergeDocsUser($auth.user, user));
            }
        }).error((e) => console.warn(e));
    }

    function disconnect() {
        $echo.leave(`user.${userId}`);
    }
}
