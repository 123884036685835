import Vue from 'vue';
import { StoreApi } from "~/lib/storeRestApi";
import { getStore, onSuccessData } from "@/utils/store";

const api = new StoreApi({
    state: {
        categories: [],
        categoriesFeatured: [],
        newsList: {},
        news: null,
        newsTyped: {},
    },
}).get({
    action: "getNewsList",
    property: "newsList",
    queryParams: true,
    path: '/news',
}).get({
    action: "getNewsType",
    queryParams: true,
    path: '/news',
    onSuccess(s, { data }, axios, { params }) {
        Vue.set(s.newsTyped, params.type, data);
    },
}).get({
    action: "getNews",
    property: "news",
    path: ({ alias }) => `/news/${alias}`,
}).get({
    action: "getCategories",
    property: "categories",
    path: '/newscategories',
    onSuccess: onSuccessData('categories'),
}).get({
    action: "getCategoriesFeatured",
    property: "categoriesFeatured",
    path: '/newscategories?filter[featured]=1',
    onSuccess: onSuccessData('categoriesFeatured'),
});

export default getStore(api, {
    getters: {
        newsType: (s) => (type) => {
            return s.newsTyped[type] || [];
        },
    },
});
