import { consonantLast } from "./word";
import _mergeWith from "lodash/mergeWith";
import _isArray from "lodash/isArray";
import { joinStrings } from "./joinStrings";
import { GET_BASE_URL } from './_consts';

export function noIndex(noIndex = true) {
    if (noIndex) return {hid: 'robots', name: 'robots', content: 'noindex, nofollow'};
    else return {hid: 'robots'};
}

export function metaMerge(...meta) {
    function customizer(objValue, srcValue) {
        if (Array.isArray(objValue)) {
            return objValue.concat(srcValue);
        }
    }

    return meta.reduce((tmp, meta) => _mergeWith(tmp, meta, customizer), {});
}

export function mergerHead(head1, head2) {
    return _mergeWith(
        head1,
        head2,
        (objValue, srcValue) => {
            if (_isArray(objValue)) {
                return objValue.concat(srcValue);
            }
        },
    )
}

export function titleAndDescription(title, descriptionRaw, path) {
    const domain = `https://${GET_BASE_URL(true, true)}`;
    const getUrl = (path) => domain + path;
    const description = descriptionRaw?.replace(/  /g, ' ');

    return {
        title,
        meta: [
            { hid: 'description', name: 'description', content: description },
            { hid: 'og:title', property: 'og:title', content: title },
            { hid: 'og:description', property: 'og:description', content: description },
            { hid: 'og:url ', property: 'og:url ', content: getUrl(path) ?? '' },
        ],
    }
}

export function getImageOg(list = [], limit = 3) {
    return {
        meta: list.slice(0, limit).map((img, i) => {
            const content = img?.lg || img;
            const id = img?.id || i;
            return { hid: `og:image:${id}`, property: 'og:image', content };
        }),
    }
}

export function getImagePreload(list = []) {
    return {
        link: list.map((href, i) => {
            return { hid: `og:image:${href}`, rel: 'preload', as: 'image', href };
        }),
    }
}

export function getCanonical($store, $route) {
    let path = $route.path;
    if (!path.endsWith('/')) path += '/';
    const url = `https://${$store.state.domain}${path}`;
    return {
        link: [
            {
                rel: 'canonical',
                href: url,
            }
        ],
        meta: [
            {hid: 'og:url', property: 'og:url', content: url}
        ]
    }
}

export function getCarInfo(car) {
    let year = car?.year;
    if (year?.min) {
        if (year.min === year.max) year = year.min;
        else year = `${year.min}-${year.max}`;
    }

    const body = car?.options?.cars_body?.title;
    const info = {
        brand: car.brand?.title,
        brandModel: car.brand_model?.title,
        body: body && body.toLowerCase(),
        year
    };
    info.title = joinStrings(info.brand, info.brandModel, info.year);
    return info;
}

export function getCarCategory({city, car_class, cars_body, brand, brand_model}) {
    const list = [city?.title, car_class?.title, cars_body?.title, brand?.title, brand_model?.title];
    return list.join('/');
}

export function GtmCarToProduct({dealId, id, max_price_per_day: price, ...car}, position = undefined) {
    if(!id) return {id};
    let data = {
        id: id.toString(),
        name: getCarInfo(car).title,
        category: getCarCategory(car)
    };
    const priceVal = price?.min ?? price;
    if (priceVal) data.price = parseFloat(priceVal).toFixed(2);
    if (position) data.position = position.toString();
    if (dealId) data.dealId = dealId.toString();
    return data;
}

export function GtmCarListsToProducts(listCar) {
    return listCar.map((car, index) => GtmCarToProduct(car, index + 1)).map(({category, ...product}) => product);
}

export function findGlossaryTitle(values, alias, getValue = false) {
    if (!values?.length) return '';
    const glossaryItem = values.find((item) => item?.alias === alias);
    if (getValue) {
        return glossaryItem?.value || '';
    }
    return glossaryItem?.seo_title || glossaryItem?.title || '';
}

export function getSeoDescription({ alias, value }, { glossariesCar }) {
    const glossaryItem = glossariesCar[alias]?.find((item) => item?.alias === value);
    return glossaryItem?.seo_description || '';
}


export function getFilterString(filter, { glossariesOptions, glossariesCar, glossariesAddress }, vehicleTypeWords) {
    const exceptions_prefix = ['cars_body', 'brand', 'brand_model', 'tags'];
    const exceptions_suffix = ['cars_body', 'car_class'];
    const exceptions_revers = ['engine_type'];
    const venMulti = `${vehicleTypeWords.full.multi} -`;

    if (filter.alias === 'metro') {
        return `${venMulti} у метро ${findGlossaryTitle(glossariesAddress.metro, filter.value)}`;
    }
    if (filter.alias === 'delivery_airport') {
        return `${venMulti} в аэропорту ${findGlossaryTitle(glossariesAddress.airport, filter.value)}`;
    } else if (filter.alias === 'mileage_limit') {
        return `${venMulti} ${findGlossaryTitle(glossariesCar.mileage_limit, filter.value).toLowerCase()} пробега`;
    } else if (filter.alias === 'rent_options' && filter.value === 'without-deposit') {
        return `${venMulti} без депозита и залога`;
    } else if (filter.alias === 'year') {
        return `автомобилей ${filter.value} года`;
    }

    const { name_with, type, values } = glossariesOptions[filter.alias] || {};
    let val = filter.value;
    const valuesCar = glossariesCar[filter.alias];
    if (valuesCar) val = findGlossaryTitle(valuesCar, val);
    else if (type) {
        if (type?.alias === 'array') val = findGlossaryTitle(values, val);
    }

    let prefix = venMulti;
    let value = (val || '').toString().toLowerCase();
    let suffix = (name_with || '').toString().toLowerCase();

    if (filter.alias === 'cars_body' && consonantLast(value)) value = `${value}ов`;

    if (exceptions_suffix.includes(filter.alias)) suffix = '';
    if (exceptions_prefix.includes(filter.alias)) prefix = '';
    if (exceptions_revers.includes(filter.alias)) return [prefix, suffix, value].join(' ');
    else return [prefix, value, suffix].join(' ').trim();
}

export function getTitleInBrand(filter, {glossariesCar}) {
    const list = glossariesCar[filter.alias] || [];
    return list.find(({alias}) => alias === filter.value)?.title;
}
export function getAltCar(car) {
    const {brand, brandModel, body, year} = getCarInfo(car);
    return [brand, brandModel, body, year].filter(el => el).join(' ');
}

export function GTMPay({payment_id, payment_amount, car}){
    return {
        currencyCode: "RUB",
        purchase: {
            actionField: {
                id: payment_id,
                revenue: payment_amount
            },
            products: [
                {
                    ...GtmCarToProduct(car ?? {}),
                    quantity: 1
                }
            ]
        }
    };
}

export function metricaClientIds($cookies) {
    return {
        yandex_client_id: $cookies.get('_ym_uid'),
        google_client_id: $cookies.get('_ga')?.split('.')?.splice(-2)?.join('.'),
    }
}
