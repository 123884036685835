import {profilePersistSplitTestGroups, splitTestGroups} from "~/utils/splitTestGroups";

const SPLIT_TEST_COOKIES_KEY_PREFIX = 'rr-split-test';

const logSettings = {
    color: 'orange',
}

const receivedTest = {};

export default ({app, store, $signals, $isDev, $auth}, inject) => {
    function splitTest(name, optionKey) {
        const result = getSplitTestResult(name);

        if (!receivedTest[name] && result != null) {
            receivedTest[name] = result;
            $signals.emit({
                category: name,
                event: result,
                logSettings,
            })
        }

        if (optionKey) {
            // Compare option with test result
            const testGroup = splitTestGroups[name] ?? profilePersistSplitTestGroups[name];
            const option = testGroup[optionKey];
            if (!option) {
                throw new Error(`Split testing option with key: "${optionKey}" is not defined`);
            }
            return result === option;
        } else {
            // Return test result
            return result;
        }
    }

    function getSplitTestResult(name) {
        if (profilePersistSplitTestGroups[name]) {
            if ($auth.loggedIn) {
                return $auth.user.settings?.[name] || null;
            }
            return null;
        } else {
            const testOptions = splitTestGroups[name];

            if (!testOptions) {
                throw new Error(`Split testing with name: "${name}" is not defined`);
            }

            const testCookieKey = `${SPLIT_TEST_COOKIES_KEY_PREFIX}:${name}`;
            const result = app.$cookies.get(testCookieKey);

            if (result) {
                return result;
            } else {
                const randomTestResult = getRandomSplitTestResult(Object.values(testOptions));
                app.$cookies.set(testCookieKey, randomTestResult);
                return randomTestResult;
            }
        }
    }

    inject('splitTest', splitTest);
}

export function getRandomSplitTestResult(options) {
    return options[Math.floor(Math.random() * options.length)];
}
