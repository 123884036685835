import { isBot } from "@/utils/router";
import { rr_session_id, rr_store_id } from '@/plugins/axios';
import { cleanObjects } from '@/utils/objects/cleanObjects';

let gtmAdded = false;

export default ({ isDev, req, app, route }, inject) => {
    try {
        if (process.client && !gtmAdded && (!isDev || route.query.gtm_debug) && !isBot(req)) {
            gtmAdded = true;

            (function (w, d, s, l, i) {
                w[l] = w[l] || [];
                w[l].push({
                    'gtm.start':
                        new Date().getTime(), event: 'gtm.js',
                });
                var f = d.getElementsByTagName(s)[0],
                    j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : '';
                j.async = true;
                j.src =
                    'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
                f.parentNode.insertBefore(j, f);
            })(window, document, 'script', 'dataLayer', 'GTM-PNDVBKX');
        }
    } catch (e) {
        console.warn(e);
    }

    inject('dataLayerPush', dataLayerPush);
    inject('dataLayerConfig', dataLayerConfig);

    function dataLayerPush(action, category, label, ecommerce = {}) {
        try {
            if (process.client) {
                const user_id = app?.$auth?.user?.id;
                return addToDataLayer(
                    'event',
                    action,
                    cleanObjects({
                        timestamp: Date.now(),
                        client_id: rr_store_id(app),
                        session_id: rr_session_id(app),
                        user_id,
                        event_category: category,
                        event_label: label,
                        ...ecommerce,
                    }),
                );
            } else console.warn('dataLayerPush srv', arguments);
        } catch (e) {
            console.warn('dataLayerPush', e);
        }
    }

    function dataLayerConfig(name, value) {
        const gaId = process.env.VUE_APP_GA_MEASUREMENT_ID ?? '';
        try {
            if (process.client) {
                gaId.split(',').forEach((GA_MEASUREMENT_ID) => {
                    addToDataLayer(
                        'config',
                        GA_MEASUREMENT_ID,
                        name ? {
                            [name]: value,
                        } : undefined,
                    );
                });
            } else console.warn('dataLayerConfig srv', dataLayer);
        } catch (e) {
            console.warn('dataLayerPush', e);
        }
    }

    function addToDataLayer() {
        return window.dataLayer.push(arguments);
    }

    if (process.client) {
        window.dataLayer = window.dataLayer || [];
        dataLayerConfig('custom_map', {
            'dimension1': 'client_id',
            'dimension2': 'session_id',
            'dimension3': 'user_id',
            'dimension4': 'timestamp',
        });
    }
}
