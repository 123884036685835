import Vue from 'vue'
import {mediaQueryBreakpoints} from "@/modules/mediaQueryPlugin";

function addMediaListener(width, listener) {
    if (!window.matchMedia) {
        return window.outerWidth < width;
    }
    const mediaQueryList = window.matchMedia(`(max-width: ${width}px)`);
    if (mediaQueryList.addEventListener) {
        mediaQueryList.addEventListener('change', listener);
    } else if (mediaQueryList.addListener) {
        mediaQueryList.addListener(listener);
    }
    return mediaQueryList.matches
}

function getDefaultMatches(device, width) {
    if (width <= mediaQueryBreakpoints.md) return device.isMobile;
    if (width <= mediaQueryBreakpoints.lg) return device.isMobileOrTablet;
    return device.isMobileOrTablet || device.isDesktop;
}

export default function ({$device}, inject) {
    const options = {
  "sm": 576,
  "md": 768,
  "lg": 992,
  "xl": 1200,
  "xxl": 1324,
  "xxxl": 1436
};
    const vm = new Vue({data: options});

    Object.entries(options).forEach(([key, width]) => {
        let matches;
        if (process.client) {
            matches = addMediaListener(width, (event) => {
                vm.$data[key] = event.matches;
            });
        } else {
            matches = getDefaultMatches($device, width)
        }
        vm.$data[key] = matches;
    });

    inject('media', vm.$data)
}
