import { StoreApi } from "~/lib/storeRestApi";
import { getStore } from "@/utils/store";
import { getFormData, isFile } from "@/utils/files";
import { DAY } from "@/utils/date";
import { checkIsExploitation } from "@/utils/deal";
import { INSPECTION_TYPES } from '~/utils/_consts';

const store = new StoreApi({
    state: {
        form: {},
        photos: [],
        errorInspection: null,
    },
}).post({
    action: "inspectionDealStart",
    property: "inspectionDealStartResult",
    path: ({ id }) => `/deals/${id}/inspection/start`,
}).post({
    action: "inspectionDealProcess",
    property: "inspectionDealProcessResult",
    path: ({ inspection_id }) => `/deals/inspection/${inspection_id}/process`,
}).put({
    action: "inspectionDealFinish",
    property: "inspectionDealFinishResult",
    path: ({ inspection_id }) => `/deals/inspection/${inspection_id}/finish`,
});


export default getStore(store, {
    getters: {
        inspectionPending(s) {
            return Object.values(s.pending).some((v) => v);
        },
        inspectionFormValid({ form: { fuel, mileage, dashboard }, photos }) {
            return fuel && mileage && dashboard && photos?.length >= 4;
        },
        inspections(s, g, rootS) {
            return rootS.rent.deal?.inspections ?? [];
        },
        isStartInspectionsEnded(s, g, rootS) {
            return checkIsExploitation(rootS.rent.deal?.status?.id);
        },
        inspectionAvailable(s, g, rootS, rootG) {
            const deal = rootS.rent.deal
            const allowedActions = rootG['rent/allowedActions'];
            if (!allowedActions.actions.inspection) return false;
            const timeLeft = deal.period_start - Date.now();
            return timeLeft <= DAY;
        },
    },
    mutations: {
        resetInspection(s) {
            s.form = {};
            s.photos = [];
            s.errorInspection = null;
        },
        setFormInspection(s, form) {
            s.form = { ...form };
            s.errorInspection = null;
        },
        setPhotosInspection(s, photos) {
            s.photos = [...photos];
            s.errorInspection = null;
        },
        setErrorInspection(s, errors) {
            s.errorInspection = errors;
        },
    },
    actions: {
        uploadInspection({ state, getters, dispatch, commit, rootGetters }, { dealId, type }) {
            const { dashboard, ...form } = state.form;
            commit('setErrorInspection', null);
            if (!getters.inspectionFormValid) {
                const { fuel, mileage, dashboard } = state.form;
                if (!fuel || !mileage) {
                    commit('setErrorInspection', {
                        fuel: fuel ? null : ['Поле обязательно для заполнения'],
                        mileage: mileage ? null : ['Поле обязательно для заполнения'],
                        dashboard: dashboard ? null : ['Вы не добавили фотографию'],
                    });
                }
                return Promise.reject(new Error('Заполните все поля'));
            }

            let files = state.photos.filter(file => isFile(file));
            return dispatch('inspectionDealStart', {//Начинаем осмотр
                params: { id: dealId },
                data: getFormData({
                    type: INSPECTION_TYPES.draft,
                    upload: dashboard,
                    ...form,
                }),
            }).catch(function (e) {
                commit('setErrorInspection', e?.errors);
                throw e;
            }).then(({ data: { inspection_id } }) => {
                return Promise.all(//Отправляем все фотографии осмотра отдельно
                    files.map((upload, i) => dispatch('inspectionDealProcess', {
                        params: { inspection_id },
                        data: getFormData({ upload }),
                    }).catch(function (e) {
                        const uploads = [];
                        uploads[i] = e?.errors.upload;
                        commit('setErrorInspection', { uploads });
                        throw e;
                    })),
                ).then(() => dispatch('inspectionDealFinish', {//Завершаем осмотр
                    params: { inspection_id },
                    data: { type },
                }));
            }).then(() => {
                commit('resetInspection');
                return dispatch('rent/getDeal', { params: { id: dealId } }, { root: true }).then(({ data }) => {
                    return commit('rent/formFill', data, { root: true });
                });
            });
        },
    },
});
