import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _ba61bdb8 = () => interopDefault(import('../../pages/back.vue' /* webpackChunkName: "pages/back" */))
const _f47d0cbc = () => interopDefault(import('../../pages/blog/index.vue' /* webpackChunkName: "pages/blog/index" */))
const _5cf42fd3 = () => interopDefault(import('../../pages/company/index.vue' /* webpackChunkName: "pages/company/index" */))
const _1c01cc10 = () => interopDefault(import('../../pages/contacts.vue' /* webpackChunkName: "pages/contacts" */))
const _616748ac = () => interopDefault(import('../../pages/faq/index.vue' /* webpackChunkName: "pages/faq/index" */))
const _625168da = () => interopDefault(import('../../pages/news/index.vue' /* webpackChunkName: "pages/news/index" */))
const _83a41402 = () => interopDefault(import('../../pages/profile/index.vue' /* webpackChunkName: "pages/profile/index" */))
const _5d878b96 = () => interopDefault(import('../../pages/registration_bonus.vue' /* webpackChunkName: "pages/registration_bonus" */))
const _f69f8f32 = () => interopDefault(import('../../pages/selections/index.vue' /* webpackChunkName: "pages/selections/index" */))
const _405d908b = () => interopDefault(import('../../pages/sign-in/index.vue' /* webpackChunkName: "pages/sign-in/index" */))
const _6ad7ce68 = () => interopDefault(import('../../pages/styleguide.vue' /* webpackChunkName: "pages/styleguide" */))
const _728f378c = () => interopDefault(import('../../pages/token.vue' /* webpackChunkName: "pages/token" */))
const _94c338f2 = () => interopDefault(import('../../pages/generation/referral.vue' /* webpackChunkName: "pages/generation/referral" */))
const _241f08a6 = () => interopDefault(import('../../pages/profile/confirm.vue' /* webpackChunkName: "pages/profile/confirm" */))
const _744640e6 = () => interopDefault(import('../../pages/profile/my-cars.vue' /* webpackChunkName: "pages/profile/my-cars" */))
const _248eb437 = () => interopDefault(import('../../pages/profile/navigation.vue' /* webpackChunkName: "pages/profile/navigation" */))
const _0bc9ba08 = () => interopDefault(import('../../pages/profile/orders.vue' /* webpackChunkName: "pages/profile/orders" */))
const _7d98b3f3 = () => interopDefault(import('../../pages/profile/payment.vue' /* webpackChunkName: "pages/profile/payment" */))
const _5dd3912a = () => interopDefault(import('../../pages/profile/reservations-schedule.vue' /* webpackChunkName: "pages/profile/reservations-schedule" */))
const _75a12fec = () => interopDefault(import('../../pages/profile/reservations.vue' /* webpackChunkName: "pages/profile/reservations" */))
const _223b8356 = () => interopDefault(import('../../pages/sign-in/email.vue' /* webpackChunkName: "pages/sign-in/email" */))
const _535016db = () => interopDefault(import('../../pages/sign-in/emailConfirm.vue' /* webpackChunkName: "pages/sign-in/emailConfirm" */))
const _593b79f2 = () => interopDefault(import('../../pages/sign-in/password.vue' /* webpackChunkName: "pages/sign-in/password" */))
const _4781ad8e = () => interopDefault(import('../../pages/sign-in/passwordCreate.vue' /* webpackChunkName: "pages/sign-in/passwordCreate" */))
const _10202dc9 = () => interopDefault(import('../../pages/sign-in/phoneConfirm.vue' /* webpackChunkName: "pages/sign-in/phoneConfirm" */))
const _689d7aac = () => interopDefault(import('../../pages/system/clear.vue' /* webpackChunkName: "pages/system/clear" */))
const _0de0ac18 = () => interopDefault(import('../../pages/system/filters.vue' /* webpackChunkName: "pages/system/filters" */))
const _7314e992 = () => interopDefault(import('../../pages/system/widget.vue' /* webpackChunkName: "pages/system/widget" */))
const _53807aec = () => interopDefault(import('../../pages/blog/category/_category.vue' /* webpackChunkName: "pages/blog/category/_category" */))
const _301dceca = () => interopDefault(import('../../pages/news/category/_category.vue' /* webpackChunkName: "pages/news/category/_category" */))
const _5e678df2 = () => interopDefault(import('../../pages/profile/chat/_dealId.vue' /* webpackChunkName: "pages/profile/chat/_dealId" */))
const _18524280 = () => interopDefault(import('../../pages/profile/wizard/_carId/basic.vue' /* webpackChunkName: "pages/profile/wizard/_carId/basic" */))
const _5f26ac48 = () => interopDefault(import('../../pages/profile/wizard/_carId/calendar.vue' /* webpackChunkName: "pages/profile/wizard/_carId/calendar" */))
const _06336a5f = () => interopDefault(import('../../pages/profile/wizard/_carId/discount.vue' /* webpackChunkName: "pages/profile/wizard/_carId/discount" */))
const _4b0e0a84 = () => interopDefault(import('../../pages/profile/wizard/_carId/photo.vue' /* webpackChunkName: "pages/profile/wizard/_carId/photo" */))
const _0235cbca = () => interopDefault(import('../../pages/profile/wizard/_carId/price.vue' /* webpackChunkName: "pages/profile/wizard/_carId/price" */))
const _1115394e = () => interopDefault(import('../../pages/profile/wizard/_carId/terms.vue' /* webpackChunkName: "pages/profile/wizard/_carId/terms" */))
const _9764954c = () => interopDefault(import('../../pages/profile/wizard/_carId/mobile/docs.vue' /* webpackChunkName: "pages/profile/wizard/_carId/mobile/docs" */))
const _7168d2a3 = () => interopDefault(import('../../pages/profile/wizard/_carId/mobile/photo.vue' /* webpackChunkName: "pages/profile/wizard/_carId/mobile/photo" */))
const _b3f27d4c = () => interopDefault(import('../../pages/profile/wizard/_carId/moto/basic.vue' /* webpackChunkName: "pages/profile/wizard/_carId/moto/basic" */))
const _2fe284c2 = () => interopDefault(import('../../pages/profile/wizard/_carId/moto/calendar.vue' /* webpackChunkName: "pages/profile/wizard/_carId/moto/calendar" */))
const _65a94545 = () => interopDefault(import('../../pages/profile/wizard/_carId/moto/discount.vue' /* webpackChunkName: "pages/profile/wizard/_carId/moto/discount" */))
const _4e7aed44 = () => interopDefault(import('../../pages/profile/wizard/_carId/moto/photo.vue' /* webpackChunkName: "pages/profile/wizard/_carId/moto/photo" */))
const _e6ccce16 = () => interopDefault(import('../../pages/profile/wizard/_carId/moto/price.vue' /* webpackChunkName: "pages/profile/wizard/_carId/moto/price" */))
const _f5ac3b9a = () => interopDefault(import('../../pages/profile/wizard/_carId/moto/terms.vue' /* webpackChunkName: "pages/profile/wizard/_carId/moto/terms" */))
const _792f8484 = () => interopDefault(import('../../pages/booking/chat/_dealId/_module.vue' /* webpackChunkName: "pages/booking/chat/_dealId/_module" */))
const _0f242b31 = () => interopDefault(import('../../pages/blog/_alias.vue' /* webpackChunkName: "pages/blog/_alias" */))
const _0e64e04a = () => interopDefault(import('../../pages/cars/_id/index.vue' /* webpackChunkName: "pages/cars/_id/index" */))
const _3d2225cb = () => interopDefault(import('../../pages/company/_vacancy.vue' /* webpackChunkName: "pages/company/_vacancy" */))
const _0daee63a = () => interopDefault(import('../../pages/deal/_dealId/index.vue' /* webpackChunkName: "pages/deal/_dealId/index" */))
const _6000ecc6 = () => interopDefault(import('../../pages/faq/_topic.vue' /* webpackChunkName: "pages/faq/_topic" */))
const _2e6ed140 = () => interopDefault(import('../../pages/news/_alias.vue' /* webpackChunkName: "pages/news/_alias" */))
const _00e40a8e = () => interopDefault(import('../../pages/pages/_alias.vue' /* webpackChunkName: "pages/pages/_alias" */))
const _f42f9d06 = () => interopDefault(import('../../pages/promo/_promotionSegmentId.vue' /* webpackChunkName: "pages/promo/_promotionSegmentId" */))
const _71479f77 = () => interopDefault(import('../../pages/reset/_token.vue' /* webpackChunkName: "pages/reset/_token" */))
const _16165f3c = () => interopDefault(import('../../pages/sdat/_city.vue' /* webpackChunkName: "pages/sdat/_city" */))
const _6e66a0cb = () => interopDefault(import('../../pages/selections/_selection/index.vue' /* webpackChunkName: "pages/selections/_selection/index" */))
const _045cbc57 = () => interopDefault(import('../../pages/deal/_dealId/chat.vue' /* webpackChunkName: "pages/deal/_dealId/chat" */))
const _3fbb6f49 = () => interopDefault(import('../../pages/deal/_dealId/documents.vue' /* webpackChunkName: "pages/deal/_dealId/documents" */))
const _0dde3376 = () => interopDefault(import('../../pages/deal/_dealId/inspection/index.vue' /* webpackChunkName: "pages/deal/_dealId/inspection/index" */))
const _ccd3cdc4 = () => interopDefault(import('../../pages/deal/_dealId/renew.vue' /* webpackChunkName: "pages/deal/_dealId/renew" */))
const _361807bc = () => interopDefault(import('../../pages/deal/_dealId/settings.vue' /* webpackChunkName: "pages/deal/_dealId/settings" */))
const _5c7d39c0 = () => interopDefault(import('../../pages/deal/_dealId/support.vue' /* webpackChunkName: "pages/deal/_dealId/support" */))
const _6b2c4b11 = () => interopDefault(import('../../pages/deal/_dealId/inspection/_inspectionType.vue' /* webpackChunkName: "pages/deal/_dealId/inspection/_inspectionType" */))
const _535dab7f = () => interopDefault(import('../../pages/booking/_carId/_module.vue' /* webpackChunkName: "pages/booking/_carId/_module" */))
const _20be1286 = () => interopDefault(import('../../pages/group/_groupId/_city/index.vue' /* webpackChunkName: "pages/group/_groupId/_city/index" */))
const _b32a5292 = () => interopDefault(import('../../pages/arendovat-car/_city/_filter/_subfilter.vue' /* webpackChunkName: "pages/arendovat-car/_city/_filter/_subfilter" */))
const _5908a61c = () => interopDefault(import('../../pages/arendovat-moto/_city/_filter/_subfilter.vue' /* webpackChunkName: "pages/arendovat-moto/_city/_filter/_subfilter" */))
const _2d76f25e = () => interopDefault(import('../../pages/arendovat/_city/_filter/_subfilter.vue' /* webpackChunkName: "pages/arendovat/_city/_filter/_subfilter" */))
const _39fe91e5 = () => interopDefault(import('../../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/back/",
    component: _ba61bdb8,
    pathToRegexpOptions: {"strict":true},
    name: "back"
  }, {
    path: "/blog/",
    component: _f47d0cbc,
    pathToRegexpOptions: {"strict":true},
    name: "blog"
  }, {
    path: "/company/",
    component: _5cf42fd3,
    pathToRegexpOptions: {"strict":true},
    name: "company"
  }, {
    path: "/contacts/",
    component: _1c01cc10,
    pathToRegexpOptions: {"strict":true},
    name: "contacts"
  }, {
    path: "/faq/",
    component: _616748ac,
    pathToRegexpOptions: {"strict":true},
    name: "faq"
  }, {
    path: "/news/",
    component: _625168da,
    pathToRegexpOptions: {"strict":true},
    name: "news"
  }, {
    path: "/profile/",
    component: _83a41402,
    pathToRegexpOptions: {"strict":true},
    name: "profile"
  }, {
    path: "/registration_bonus/",
    component: _5d878b96,
    pathToRegexpOptions: {"strict":true},
    name: "registration_bonus"
  }, {
    path: "/selections/",
    component: _f69f8f32,
    pathToRegexpOptions: {"strict":true},
    name: "selections"
  }, {
    path: "/sign-in/",
    component: _405d908b,
    pathToRegexpOptions: {"strict":true},
    name: "sign-in"
  }, {
    path: "/styleguide/",
    component: _6ad7ce68,
    pathToRegexpOptions: {"strict":true},
    name: "styleguide"
  }, {
    path: "/token/",
    component: _728f378c,
    pathToRegexpOptions: {"strict":true},
    name: "token"
  }, {
    path: "/generation/referral/",
    component: _94c338f2,
    pathToRegexpOptions: {"strict":true},
    name: "generation-referral"
  }, {
    path: "/profile/confirm/",
    component: _241f08a6,
    pathToRegexpOptions: {"strict":true},
    name: "profile-confirm"
  }, {
    path: "/profile/my-cars/",
    component: _744640e6,
    pathToRegexpOptions: {"strict":true},
    name: "profile-my-cars"
  }, {
    path: "/profile/navigation/",
    component: _248eb437,
    pathToRegexpOptions: {"strict":true},
    name: "profile-navigation"
  }, {
    path: "/profile/orders/",
    component: _0bc9ba08,
    pathToRegexpOptions: {"strict":true},
    name: "profile-orders"
  }, {
    path: "/profile/payment/",
    component: _7d98b3f3,
    pathToRegexpOptions: {"strict":true},
    name: "profile-payment"
  }, {
    path: "/profile/reservations-schedule/",
    component: _5dd3912a,
    pathToRegexpOptions: {"strict":true},
    name: "profile-reservations-schedule"
  }, {
    path: "/profile/reservations/",
    component: _75a12fec,
    pathToRegexpOptions: {"strict":true},
    name: "profile-reservations"
  }, {
    path: "/sign-in/email/",
    component: _223b8356,
    pathToRegexpOptions: {"strict":true},
    name: "sign-in-email"
  }, {
    path: "/sign-in/emailConfirm/",
    component: _535016db,
    pathToRegexpOptions: {"strict":true},
    name: "sign-in-emailConfirm"
  }, {
    path: "/sign-in/password/",
    component: _593b79f2,
    pathToRegexpOptions: {"strict":true},
    name: "sign-in-password"
  }, {
    path: "/sign-in/passwordCreate/",
    component: _4781ad8e,
    pathToRegexpOptions: {"strict":true},
    name: "sign-in-passwordCreate"
  }, {
    path: "/sign-in/phoneConfirm/",
    component: _10202dc9,
    pathToRegexpOptions: {"strict":true},
    name: "sign-in-phoneConfirm"
  }, {
    path: "/system/clear/",
    component: _689d7aac,
    pathToRegexpOptions: {"strict":true},
    name: "system-clear"
  }, {
    path: "/system/filters/",
    component: _0de0ac18,
    pathToRegexpOptions: {"strict":true},
    name: "system-filters"
  }, {
    path: "/system/widget/",
    component: _7314e992,
    pathToRegexpOptions: {"strict":true},
    name: "system-widget"
  }, {
    path: "/blog/category/:category?/",
    component: _53807aec,
    pathToRegexpOptions: {"strict":true},
    name: "blog-category-category"
  }, {
    path: "/news/category/:category?/",
    component: _301dceca,
    pathToRegexpOptions: {"strict":true},
    name: "news-category-category"
  }, {
    path: "/profile/chat/:dealId?/",
    component: _5e678df2,
    pathToRegexpOptions: {"strict":true},
    name: "profile-chat-dealId"
  }, {
    path: "/profile/wizard/:carId(\\d+)?/basic/",
    component: _18524280,
    pathToRegexpOptions: {"strict":true},
    name: "profile-wizard-carId-basic"
  }, {
    path: "/profile/wizard/:carId(\\d+)?/calendar/",
    component: _5f26ac48,
    pathToRegexpOptions: {"strict":true},
    name: "profile-wizard-carId-calendar"
  }, {
    path: "/profile/wizard/:carId(\\d+)?/discount/",
    component: _06336a5f,
    pathToRegexpOptions: {"strict":true},
    name: "profile-wizard-carId-discount"
  }, {
    path: "/profile/wizard/:carId(\\d+)?/photo/",
    component: _4b0e0a84,
    pathToRegexpOptions: {"strict":true},
    name: "profile-wizard-carId-photo"
  }, {
    path: "/profile/wizard/:carId(\\d+)?/price/",
    component: _0235cbca,
    pathToRegexpOptions: {"strict":true},
    name: "profile-wizard-carId-price"
  }, {
    path: "/profile/wizard/:carId(\\d+)?/terms/",
    component: _1115394e,
    pathToRegexpOptions: {"strict":true},
    name: "profile-wizard-carId-terms"
  }, {
    path: "/profile/wizard/:carId(\\d+)?/mobile/docs/",
    component: _9764954c,
    pathToRegexpOptions: {"strict":true},
    name: "profile-wizard-carId-mobile-docs"
  }, {
    path: "/profile/wizard/:carId(\\d+)?/mobile/photo/",
    component: _7168d2a3,
    pathToRegexpOptions: {"strict":true},
    name: "profile-wizard-carId-mobile-photo"
  }, {
    path: "/profile/wizard/:carId(\\d+)?/moto/basic/",
    component: _b3f27d4c,
    pathToRegexpOptions: {"strict":true},
    name: "profile-wizard-carId-moto-basic"
  }, {
    path: "/profile/wizard/:carId(\\d+)?/moto/calendar/",
    component: _2fe284c2,
    pathToRegexpOptions: {"strict":true},
    name: "profile-wizard-carId-moto-calendar"
  }, {
    path: "/profile/wizard/:carId(\\d+)?/moto/discount/",
    component: _65a94545,
    pathToRegexpOptions: {"strict":true},
    name: "profile-wizard-carId-moto-discount"
  }, {
    path: "/profile/wizard/:carId(\\d+)?/moto/photo/",
    component: _4e7aed44,
    pathToRegexpOptions: {"strict":true},
    name: "profile-wizard-carId-moto-photo"
  }, {
    path: "/profile/wizard/:carId(\\d+)?/moto/price/",
    component: _e6ccce16,
    pathToRegexpOptions: {"strict":true},
    name: "profile-wizard-carId-moto-price"
  }, {
    path: "/profile/wizard/:carId(\\d+)?/moto/terms/",
    component: _f5ac3b9a,
    pathToRegexpOptions: {"strict":true},
    name: "profile-wizard-carId-moto-terms"
  }, {
    path: "/booking/chat/:dealId?/:module?/",
    component: _792f8484,
    pathToRegexpOptions: {"strict":true},
    name: "booking-chat-dealId-module"
  }, {
    path: "/blog/:alias/",
    component: _0f242b31,
    pathToRegexpOptions: {"strict":true},
    name: "blog-alias"
  }, {
    path: "/cars/:id?/",
    component: _0e64e04a,
    pathToRegexpOptions: {"strict":true},
    name: "cars-id"
  }, {
    path: "/company/:vacancy/",
    component: _3d2225cb,
    pathToRegexpOptions: {"strict":true},
    name: "company-vacancy"
  }, {
    path: "/deal/:dealId?/",
    component: _0daee63a,
    pathToRegexpOptions: {"strict":true},
    name: "deal-dealId"
  }, {
    path: "/faq/:topic/",
    component: _6000ecc6,
    pathToRegexpOptions: {"strict":true},
    name: "faq-topic"
  }, {
    path: "/news/:alias/",
    component: _2e6ed140,
    pathToRegexpOptions: {"strict":true},
    name: "news-alias"
  }, {
    path: "/pages/:alias?/",
    component: _00e40a8e,
    pathToRegexpOptions: {"strict":true},
    name: "pages-alias"
  }, {
    path: "/promo/:promotionSegmentId?/",
    component: _f42f9d06,
    pathToRegexpOptions: {"strict":true},
    name: "promo-promotionSegmentId"
  }, {
    path: "/reset/:token?/",
    component: _71479f77,
    pathToRegexpOptions: {"strict":true},
    name: "reset-token"
  }, {
    path: "/sdat/:city?/",
    component: _16165f3c,
    pathToRegexpOptions: {"strict":true},
    name: "sdat-city"
  }, {
    path: "/selections/:selection/",
    component: _6e66a0cb,
    pathToRegexpOptions: {"strict":true},
    name: "selections-selection"
  }, {
    path: "/deal/:dealId?/chat/",
    component: _045cbc57,
    pathToRegexpOptions: {"strict":true},
    name: "deal-dealId-chat"
  }, {
    path: "/deal/:dealId?/documents/",
    component: _3fbb6f49,
    pathToRegexpOptions: {"strict":true},
    name: "deal-dealId-documents"
  }, {
    path: "/deal/:dealId?/inspection/",
    component: _0dde3376,
    pathToRegexpOptions: {"strict":true},
    name: "deal-dealId-inspection"
  }, {
    path: "/deal/:dealId?/renew/",
    component: _ccd3cdc4,
    pathToRegexpOptions: {"strict":true},
    name: "deal-dealId-renew"
  }, {
    path: "/deal/:dealId?/settings/",
    component: _361807bc,
    pathToRegexpOptions: {"strict":true},
    name: "deal-dealId-settings"
  }, {
    path: "/deal/:dealId?/support/",
    component: _5c7d39c0,
    pathToRegexpOptions: {"strict":true},
    name: "deal-dealId-support"
  }, {
    path: "/deal/:dealId?/inspection/:inspectionType/",
    component: _6b2c4b11,
    pathToRegexpOptions: {"strict":true},
    name: "deal-dealId-inspection-inspectionType"
  }, {
    path: "/booking/:carId(\\d+)?/:module?/",
    component: _535dab7f,
    pathToRegexpOptions: {"strict":true},
    name: "booking-carId-module"
  }, {
    path: "/group/:groupId?/:city?/",
    component: _20be1286,
    pathToRegexpOptions: {"strict":true},
    name: "group-groupId-city"
  }, {
    path: "/arendovat-car/:city?/:filter?/:subfilter?/",
    component: _b32a5292,
    pathToRegexpOptions: {"strict":true},
    name: "arendovat-car-city-filter-subfilter"
  }, {
    path: "/arendovat-moto/:city?/:filter?/:subfilter?/",
    component: _5908a61c,
    pathToRegexpOptions: {"strict":true},
    name: "arendovat-moto-city-filter-subfilter"
  }, {
    path: "/arendovat/:city?/:filter?/:subfilter?/",
    component: _2d76f25e,
    pathToRegexpOptions: {"strict":true},
    name: "arendovat-city-filter-subfilter"
  }, {
    path: "/",
    component: _39fe91e5,
    pathToRegexpOptions: {"strict":true},
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
