function getLogStyle(settings) {
    return `
    color: ${settings?.color ?? 'royalblue'};
    background: black;
    padding: 2px 4px;
    border-radius: 4px;
    font-size: 12px;
    `
}


export function logSignal({signal, GTM, log, settings}) {
    if (process.client && log) {
        console.groupCollapsed(`%c${signal.signal_type}`, getLogStyle(settings))
        logDataGroup(signal, 'Signal');
        logDataGroup(GTM, 'GTM');
        console.groupEnd();
    }
}

function logDataGroup(data, name) {
    console.groupCollapsed(name)
    console.table(data);
    console.groupEnd();
}
