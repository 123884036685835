import Vue from 'vue'
import { cookieNames, IS_DEV, MODAL_IDS } from "~/utils/_consts";
import Vue2TouchEvents from 'vue2-touch-events'
import { checkWebpSupport } from '~/utils/browser/checkWebpSupport';
import { cookiesSet } from '~/utils/store';

const momentLocaleConfig = {
    monthsShort: {
        format: 'янв_февр_мар_апр_мая_июн_июл_авг_сент_окт_нояб_дек'.split('_'),
        standalone: 'янв_февр_март_апр_май_июн_июл_авг_сент_окт_нояб_дек'.split('_'),
    },
    relativeTime: {
        //s: 'несколько секунд',
        h: '1 час',
        d: '1 день',
        w: '1 неделя',
        M: '1 месяц',
        y: '1 год',
    },
};

export default (context, inject) => {
    const { $moment, app, store } = context;

    if (process.client) {
        Vue.use(Vue2TouchEvents);
    }

    $moment.updateLocale('ru', momentLocaleConfig);

    Vue.prototype.$modals = MODAL_IDS;

    Vue.prototype.$notifySrvErr = app.$notifySrvErr = notifySrvErr.bind(context);

    inject('isDev', IS_DEV());

    if (process.client && !app.$cookies.get(cookieNames.webpSupport)) {
        checkWebpSupport().then((result) => {
            store.commit('setSupportWebp', result);
            cookiesSet(app.$cookies, cookieNames.webpSupport, result);
        });
    }
}

function notifySrvErr(message, error, ignore404 = true) {
    const { $sentry, $axios } = this;
    const isCanceled = $axios.isCancel(error);
    let errors = error?.errors;
    try {
        if (errors) {
            errors = Object.values(errors).map(list => {
                if (Array.isArray(list)) return list.join(', ');
                else return list;
            }).join(', ');
        } else {
            errors = error?.message ?? error;
        }
    } catch (e) {
        console.warn('notifySrvErr err', e);
    }
    const title = error?.statusCode === 422 ? 'Ошибка данных' : 'Ошибка сервера';
    const is404 = !ignore404 && error?.statusCode === 404

    if (!isCanceled && !is404) {
        $sentry.addBreadcrumb({
            category: "notify",
            message: `${title}: ${message}. ${errors}`,
            level: $sentry.Severity.Info,
        });

        const list = [message, errors].filter(v => v).join('. ');
        if (process.client) $nuxt.$notify(list, title, 'warning', null, 20000);
        else console.log('[AXIOS]', message, error);

        console.warn(message, error);
    }

    return {
        message: `${message}: ${error?.message || error}. ${errors || ''}`,
        statusCode: error?.statusCode,
        isCanceled,
    };
}
