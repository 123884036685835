import { metricaClientIds } from "~/utils/meta";
import { logSignal } from "~/utils/log/logSignal";
import { createSignalsEmitters } from "~/utils/analytics/signals";
import { getOS } from "~/plugins/axios";
import packageJson from "~/package.json"
import { getUtm } from '~/utils/router';

export default (context, inject) => {
    const globalSignalWithContext = globalSignal.bind(context);

    inject('signal', signal.bind(null, context));
    inject('signals', createSignalsEmitters(context, globalSignalWithContext));
    inject('globalSignal', globalSignalWithContext);

    context.$signals.visitPage();
};

function getSignalType(event, category) {
    return [category, event].filter(v => v).join('.');
}

function signal({
                    app,
                    store,
                    route,
                    req,
                    $auth,
                    $cookies,
                    $device,
                    $axios,
                }, signal_type, category, data = {}, label) {
    const user_agent = process.server
        ? req.headers['user-agent']
        : navigator?.userAgent;

    const signal = {
        signal_type: getSignalType(signal_type, category),
        label,
        rr_store_id: app.rr_store_id,
        user_id: $auth?.user?.id ?? data?.userid,
        referrer: process.client && (store.state.prevRoute ?? document?.referrer),
        platform: !app.$media.md ? 'desktop' : 'mobile', //navigator?.platform,
        user_agent,
        ip: store.state.ip,
        url: route.fullPath,
        project: 'rentride',
        os: getOS($device),
        version: packageJson.version,
        ...metricaClientIds($cookies),
        data: JSON.stringify(data),
        source_info: JSON.stringify(getUtm()),
    };
    if (process.client) {
        try {
            $axios.post('/events', signal);
        } catch (e) {
            console.error(`Ошибка отправки события: ${signal.signal_type}`, e);
            return signal;
        }
    } else {
        console.warn('Ignore signal', signal.category ?? '', signal.signal_type ?? signal, signal.label ?? '');
    }
    return signal;
}

function globalSignal(signal_type, category, data, GTMData, label, GTMIgnore = false, logSettings) {
    const signalData = this.$signal(signal_type, category, data, label);
    if (!GTMIgnore) {
        this.$dataLayerPush?.(signal_type, category, label, GTMData);
    }
    logSignal({
        signal: signalData,
        GTM: GTMData,
        log: this.$isDev,
        settings: logSettings,
    });
}
