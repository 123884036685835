import { getRouterParams } from "./filters";
import _isEqual from "lodash/isEqual";
import _pickBy from "lodash/pickBy";
import _map from "lodash/mapValues";
import _cloneDeep from "lodash/cloneDeep";
import isBotCheck from "isbot";
import { APP_LINKS, GET_BASE_URL, LOCALSTORAGE_NAMES } from "./_consts";
import { querySerializer } from './store';
import _isString from 'lodash/isString';

export const ROUTE_CARS = 'arendovat-city-filter-subfilter';

export function filter(params, allowed = false) {
    const alias = {
        category: 'category.alias',
        tag: 'tags.name',
    };
    return Object.keys(params).reduce((filters, key) => {
        if (params[key] && params[key] !== 'all') {
            if (Array.isArray(allowed) ? allowed.includes(key) : true) {
                filters[`filter[${alias[key] || key}]`] = params[key];
            }
        }
        return filters;
    }, {});
}

export function paramsPosts(params) {
    if (params.tag && !params.category) {
        return {
            ...params,
            category: 'all',
        };
    }
    return params;
}

export function replaceQuery({ $router, $route }, key, val) {
    let route = Object.assign({}, $route);
    route.query = Object.assign({}, route.query);
    if (val) route.query[key] = val;
    else delete route.query[key];
    return $router.replace(route).catch(() => {
    });
}

export function replaceQueryObj({ $router, $route }, obj) {
    let route = Object.assign({}, $route);
    route.query = { ...route.query, ...obj };
    for (const key in route.query) {
        if (route.query[key] === undefined || route.query[key] === null) {
            delete route.query[key];
        }
    }
    return $router.replace(route).catch(() => {
    });
}


export function getUtm() {
    let utm;
    try {
        utm = JSON.parse(localStorage.getItem(LOCALSTORAGE_NAMES.utm));
    } catch (e) {
    }
    return utm || {};
}

export function getReferralId() {
    try {
        return JSON.parse(localStorage.getItem(LOCALSTORAGE_NAMES.referralId))
    } catch (e) {
    }
}

export function playMarketUtmLink() {
    return `${APP_LINKS.android}?referrer=${querySerializer.paramsSerializer(getUtm()).replace(/&/g, '%26')}`;
}

export function getRouteToCars(filters = {}, city) {
    if (!city) {
        city = process.client ? window.$nuxt.$store.getters['glossary/currentCity'].alias : 'moskva';
    }
    return getRouterParams(filters, { name: ROUTE_CARS, params: { filter: null, city } });
}

export function goToCars(filters = {}) {
    if (process.client) {
        return window.$nuxt.$router.push(
            getRouteToCars(filters),
        )?.catch(() => {
        });
    } else console.error('goToCars only client');
}

export function getHost(req) {
    const host = process.client ? location.hostname : req?.headers?.host;
    return host ?? GET_BASE_URL(true, true);
}

export function isEqualRoutes(newRoute, curRoute) {
    const filter = (v) => _map(_cloneDeep(v), (obj) => {
        obj = _map(obj, (val) => {
            if (Array.isArray(val)) {
                if (val.length === 1) {
                    return toLoweCase(val[0]);
                } else {
                    return val.sort().map(toLoweCase);
                }
            }
            return toLoweCase(val);
        });
        return _pickBy(obj, (val) => Array.isArray(val) ? val.length : val)
    });

    return _isEqual(filter(newRoute), filter(curRoute));
}

function toLoweCase(str) {
    return _isString(str) ? str.toLowerCase() : str;
}

export function isBot(req) {
    const agent = process.client ? navigator.userAgent : req.headers["user-agent"];
    return isBotCheck(agent) ? 1 : 0;
}

export function getRouteWizard(section, vehicleType, carId) {
    const path = {
        moto: '-moto-',
    }

    return {
        name: `profile-wizard-carId${path[vehicleType] ?? '-'}${section}`,
        params: { carId },
    };
}


export function getSectionWizard(roueName) {
    const sections = roueName.split('-');
    const section = sections[sections.length - 1];
    if (sections[sections.length - 2] === 'mobile') {
        return `mobile-${section}`;
    } else {
        return section;
    }
}

export function replaceCityToCatalogRoute(path, city) {
    const list = path.split('/');
    list.splice(2, 0, city);
    return list.join('/');
}
